import {
  OriginalShipmentDetails,
  ShipmentCreateType,
  ShipmentLocation,
  ShipmentModel,
  TrackingStatus
} from '../models/base-shipment-models';
import * as moment from 'moment';
import { ShippingDateType } from '../enums/shipping-date-type';
import { PackagingType } from '../enums/packaging-type';
import { Commodity, InternationalContact } from '../models/international-shipment.model';
import { CarrierType } from '../models/shipment';
import { defaultGridColumnSettings, gridColumnSettings, savedShipmentsDefaultColumns, shipmentConstants } from './shipment-constants';
import { ShippingColumnName } from '../enums/shipping-column-type';
import { FilterType } from '../../../../shared/models/odata-query-model';
import { SavedShipmentState } from '../enums/saved-shipment-state';

export function populateShipmentLocationModel(values: any): ShipmentLocation {

  const location = new ShipmentLocation({
    valid: values.valid || false,
    complete: values.valid || false,
    firstName: values.firstName,
    lastName: values.lastName,
    emailAddress: values.email,
    phone: {
      callingCode: values.callingCode,
      number: values.phone
    },
    company: values.companyName,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    city: values.city,
    state: values.state,
    countryCode: values.country,
    postalCode: values.zipCode,
    isResidential: values.isResidential,
    referenceName: values.referenceName,
    createNewContact: values.createNewContact,
    updateExistingContact: values.updateExistingContact,
    makeDefaultContact: values.makeDefaultContact,
    sendShipmentNotification: values.sendNotification,
    skipStandardizedCheck: values.skipStandardizedCheck
  });

  return location;
}

export function computeShippingTimeType(shippingDate: string, dateFormat: string): string {
  const today = moment(new Date());
  const tomorrow = moment(new Date()).add(1, 'days');
  if (shippingDate === today.format(dateFormat)) {
    return ShippingDateType.Today;
  } else if (shippingDate === tomorrow.format(dateFormat)) {
    return ShippingDateType.Tomorrow;
  } else {
    return ShippingDateType.Custom;
  }
}

export function olderThanOneDay(date: Date): boolean {
  const now = moment(new Date());
  const end = moment(date);
  const duration = moment.duration(now.diff(end));
  const hours = duration.asHours();
  return hours > 24;
}

/**
 * Verifies if there are missing required data in a shipment location
 *
 * @param location shipment location
 * @param locationType origin/destination
 */
export function isShipmentLocationIncomplete(location: ShipmentLocation, locationType: string): boolean {
  if (!location) { return true; }

  let locationIncomplete = !(location.firstName || location.lastName || location.company)
    || !location.phoneNumber || !location.addressLine1
    || !location.city || !location.countryCode;

  if (locationType === 'origin') {
    locationIncomplete = locationIncomplete || !location.emailAddress;
  }

  const statesAndZipRequired = checkIfStatesAndZipAreRequired(location.countryCode);

  if (locationType === 'origin' || locationType === 'destination' && statesAndZipRequired) {
    locationIncomplete = locationIncomplete || !location.state || !location.postalCode;
  }

  return locationIncomplete;
}

/**
 * Verifies if there are missing required data in a shipment without addresses
 *
 * @param shipment shipment to be verified
 */
export function areShipmentDetailsComplete(shipment: ShipmentModel): boolean {
  const shipmentPackage = shipment.package;

  if (shipmentPackage === null) {
    return false;
  }

  const sealRequired = shipment.insuranceValue && +shipment.insuranceValue > shipmentConstants.securitySealDisplayLimit;

  let shipmentDetailsComplete = shipment.insuranceValue !== null
    && shipmentPackage.packagingTypes !== null && shipmentPackage.packagingTypes.length > 0
    && shipmentPackage.packageDetails !== null && shipmentPackage.packageDetails.length > 0
    && shipmentPackage.weights !== null
    && (shipmentPackage.weights[0].value !== null || shipmentPackage.weights[1].value !== null)
    && shipment.cargoId !== null && shipment.cargoId !== '00000000-0000-0000-0000-000000000000'
    && shipment.carriers !== null && shipment.carriers.length > 0
    && shipment.services !== null && shipment.services.length > 0
    && (!sealRequired || !!shipment.sealNumber)
    && (!shipment.codSelected || parseFloat(shipment.codService?.collectionAmount) <= 50000)
    && (!shipment.thirdPartySelected || shipment.thirdPartyService?.accountZipCode.length === 5);

  // Handle custom package selection
  if (shipmentPackage.packagingTypes[0] === PackagingType.Custom) {
    const dimensions = shipmentPackage.dimensions;
    shipmentDetailsComplete = shipmentDetailsComplete && dimensions.height !== null && dimensions.length !== null
      && dimensions.width !== null;
  }

  if (shipment.insuranceOnly) {
    shipmentDetailsComplete = shipmentDetailsComplete && !!shipment.insuranceOnlyTrackingNumber;
  }

  if (shipment.isInternational && !shipment.insuranceOnly) {
    shipmentDetailsComplete = shipmentDetailsComplete && shipment.internationalDetail.tariffCode !== null && shipment.invoiceValue !== null;
  } else {
    shipmentDetailsComplete = shipmentDetailsComplete && shipment.signatureOptions !== null && shipment.signatureOptions.length > 0;
  }

  return shipmentDetailsComplete;
}

/**
 * Verifies if there are missing required data for international shipment
 *
 * @param contact address to be verified
 */
function isInternationalContactComplete(contact: InternationalContact): boolean {
  if (!contact) { return false; }

  const statesAndZipRequired = checkIfStatesAndZipAreRequired(contact.countryCode);

  let isComplete = contact !== null && (!!contact.firstName || !!contact.company)
    && !!contact.phoneNumber && !!contact.addressLine1
    && !!contact.city && !!contact.countryCode;

  if (statesAndZipRequired) {
    isComplete = isComplete && !!contact.state && !!contact.postalCode;
  }
  return isComplete;
}

/**
 * Verifies if address requires states
 *
 * @param country
 */
export function checkIfStatesAndZipAreRequired(country: string): boolean {
  return isUSA(country) || isCAN(country);
}

export function isUSA(country: string): boolean {
  return ['US', 'USA'].includes(country);
}

export function isCAN(country: string): boolean {
  return ['CA', 'CAN'].includes(country);
}

/**
 * Verifies if there are missing mandatory details for international shipment and returns route to be displayed
 *
 * @param internationalDetail
 */
export function verifyExportDetails(shipment: ShipmentModel): string {

  const internationalDetail = shipment.internationalDetail;

  if (!internationalDetail) { return; }

  // verify commodities
  const commoditiesInvalid = !verifyCommoditiesValid(internationalDetail.commodities, internationalDetail.isSEDIncluded);

  if (internationalDetail.reasonForExport === 0 || internationalDetail.commodities.length === 0 || commoditiesInvalid) {
    return 'documents';
  }

  // don't verify rest of the data if export pages are skipped
  if (internationalDetail.exportDetailsSkipped) {
    return;
  }

  if (internationalDetail.isCommercialInvoiceIncluded) {
    const taxIdRequired = shipment.carriers && shipment.carriers.includes(CarrierType.UPS) &&
      internationalDetail.isSEDIncluded;

    const taxIdMissing = taxIdRequired ? !internationalDetail.senderTins?.tinNumber : false;

    const importerOfRecordIncomplete = internationalDetail.isImporterOfRecordSameAsRecipient === false &&
      !isInternationalContactComplete(internationalDetail.importerOfRecord);

    if (taxIdMissing || importerOfRecordIncomplete) {
      return 'importer-of-record';
    }
  }

  if (internationalDetail.isSEDIncluded) {
    let ultimateConsigneeValid = true;
    let intermediateConsigneeValid = true;
    let forwardingAgentValid = true;

    if (internationalDetail.isUltimateConsigneeSameAsRecipient === false) {
      ultimateConsigneeValid = isInternationalContactComplete(internationalDetail.ultimateConsignee);
    }

    if (internationalDetail.useIntermediateConsignee) {
      intermediateConsigneeValid = isInternationalContactComplete(internationalDetail.intermediateConsignee);
    }

    if (internationalDetail.useForwardingAgent) {
      forwardingAgentValid = isInternationalContactComplete(internationalDetail.forwardingAgent);
    }

    if (!ultimateConsigneeValid || !intermediateConsigneeValid || !forwardingAgentValid) {
      return 'consignee';
    }
  }
}

/**
 *
 * @param commodities list of commodites
 * @param isSEDIncluded flag indicating if SED is included
 * @returns true if all commodities are valid
 */
export function verifyCommoditiesValid(commodities: Commodity[], isSEDIncluded = false): boolean {
  const invalidCommodity = commodities.find(c => {
    let commodityComplete = true;
    if (isSEDIncluded) {
      commodityComplete = c.scheduleBUnit !== 0 && c.exportType !== 0;
    }
    commodityComplete = commodityComplete && !!c.detailedDescription && c.unitValue !== null;
    return !commodityComplete;
  });
  return invalidCommodity === undefined;
}

/**
 * Verifies if shipment approval/seal number is mandatory and if it's missing
 *
 * @param shipment
 */
export function needsApprovalOrSealNumber(shipment: ShipmentModel): boolean {
  const selectedQuote = shipment.selectedQuoteDetails;
  return selectedQuote && (selectedQuote.requiresApproval || selectedQuote.requiresSeal)
    && !(shipment.approvalCode || shipment.sealNumber);
}

/**
 * Map field names to translation label (used for forbidden words functionality)
 */
export function getInputFieldName(fieldName: string): string {
  switch (fieldName) {
    case 'firstName_origin':
    case 'firstName_destination':
      return 'Labels.FirstName';
    case 'lastName_origin':
    case 'lastName_destination':
      return 'Labels.LastName';
    case 'companyName_origin':
    case 'companyName_destination':
      return 'Labels.CompanyName';
    case 'addressLine1_origin':
    case 'addressLine1_destination':
    case 'addressLine1':
      return 'Labels.AddressLineOne';
    case 'addressLine2_origin':
    case 'addressLine2_destination':
    case 'addressLine2':
      return 'Labels.AddressLineTwo';
    case 'contactName':
      return 'Labels.Contact';
    case 'companyName':
      return 'Labels.CompanyName';
    case 'senderRef':
      return 'Labels.Reference1';
    case 'receiverRef':
      return 'Labels.Reference2';
  }
}

export function getColumnNameByFileName(columnFiled: string): ShippingColumnName {
  const columns = [...defaultGridColumnSettings, ...gridColumnSettings, ...savedShipmentsDefaultColumns];
  const column = columns.find(c => c.columnData === columnFiled);
  return column?.columnName;
}

export function getColumnFilterType(columnFiled: string): FilterType {
  const columnName = getColumnNameByFileName(columnFiled);
  switch (columnName) {
    case ShippingColumnName.Carrier:
    case ShippingColumnName.ShipToContact:
    case ShippingColumnName.ShipFromAddress:
    case ShippingColumnName.InsuredValue:
    case ShippingColumnName.ShipmentCost:
    case ShippingColumnName.DeclaredValue:
    case ShippingColumnName.SendersReference:
    case ShippingColumnName.ClaimReportingPeriod:
      return FilterType.CustomQuery;
    default:
      return FilterType.OdataQuery;
  }
}

export function isPriceColumn(columnName: ShippingColumnName): boolean {
  return columnName === ShippingColumnName.DeclaredValue ||
    columnName === ShippingColumnName.InsuredValue ||
    columnName === ShippingColumnName.ShipmentCost;
}

export function getOriginalShipmentDetails(
  shipment: ShipmentModel,
  childCreateType: ShipmentCreateType
): OriginalShipmentDetails {

  const trackingStatus: string = shipment.tracking &&
    shipment.tracking.activities &&
    shipment.tracking.activities.length > 0 ?
    TrackingStatus[shipment.tracking.activities[shipment.tracking.activities.length - 1].status] : '';

  return {
    id: shipment.id,
    childShipmentCreateType: childCreateType,
    name: shipment.name,
    zingTrackingId: shipment.zingTrackingNumber,
    carrierTrackingId: shipment.purchase ? shipment.purchase.trackingNumber : '',
    trackingInfo: trackingStatus,
    senderReference: shipment.senderReference,
    receiverReference: shipment.receiverReference
  };
}

export function prepareShippingDateForSave(shippingDate, dateFormat: string) {
  shippingDate = moment(new Date(shippingDate)).format(dateFormat);
  const today = moment(new Date());
  const tomorrow = moment(new Date()).add(1, 'days');
  if (new Date(shippingDate) < new Date(today.format(dateFormat))) {
    shippingDate = today.format(dateFormat);
  }
  if (shippingDate === today.format(dateFormat)) {
    return {
      date: shippingDate,
      type: ShippingDateType.Today
    };
  } else if (shippingDate === tomorrow.format(dateFormat)) {
    return {
      date: shippingDate,
      type: ShippingDateType.Tomorrow
    };
  } else {
    return {
      date: shippingDate,
      type: ShippingDateType.Custom
    };
  }
}

/**
 * Get the uri from where shipment was last saved
 */
export function getEditShipmentRoute(shipment: ShipmentModel): string {
  let editUri = `membership/shipping/edit/${shipment.id}`;

  switch (shipment.createType) {
    case ShipmentCreateType.Forward:
      editUri = `membership/shipping/forward/${shipment.id}`;
      break;
    case ShipmentCreateType.Boomerang:
      editUri = `membership/shipping/boomerang/${shipment.id}`;
      break;
    default:
      break;
  }

  if (shipment.savedShipmentState != null) {
    if (shipment.savedShipmentState === SavedShipmentState.InsuranceOnly
      || shipment.savedShipmentState === SavedShipmentState.InsuranceOnlyReview) {
      editUri = `membership/shipping/insurance-only/edit/${shipment.id}`;
    } else {
      editUri = editUri + '/addresses';
    }
  }
  return editUri;
}

export function isCarrierUPS(carrier: any) {
  return typeof carrier === 'number' ? carrier === CarrierType.UPS
    : typeof carrier === 'string' ? CarrierType[CarrierType.UPS] === carrier : false;
}

export function isCarrierFedEx(carrier: any) {
  return typeof carrier === 'number' ? carrier === CarrierType.FedEx
    : typeof carrier === 'string' ? CarrierType[CarrierType.FedEx] === carrier : false;
}

export function isCarrierUSPS(carrier: any) {
  return typeof carrier === 'number' ? carrier === CarrierType.USPS
    : typeof carrier === 'string' ? CarrierType[CarrierType.USPS] === carrier : false;
}
