import { CompanyLocation } from '../../../membership/modules/account/models/location.model';
import { NaturalCartItem } from '../../models/natural-cart';
import { ShoppingCartDetail } from '../../shopping-cart/models';
import { RESTRICTIONS } from '../config/location-restrictions';


export function isLocationRestricted(location: CompanyLocation) {
  return  ['US', 'USA'].includes(location.address.countryId) && RESTRICTIONS.stateIDs.includes(location.address.stateId);
}

export const MAX_ITEM_ID_VALUE = 2147483647;
export const MAX_CERTIFICATE_LENGTH = 20;


export function getItemFromCart(itemsInCart: Array<ShoppingCartDetail>, supplierItemIdentifier: string): ShoppingCartDetail {
  return itemsInCart.find(item => item.supplierItemIdentifier === supplierItemIdentifier);
}

export function getCartItem(itemsInCart: Array<NaturalCartItem>, supplierItemIdentifier: string): NaturalCartItem {
  return itemsInCart.find(item => item.supplierItemIdentifier === supplierItemIdentifier);
}
