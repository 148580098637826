import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  Inject
} from '@angular/core';
import {
  ComponentPortal,
  DomPortalOutlet
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { MessageSpinnerComponent } from '../components/message-spinner/message-spinner.component';

@Injectable({
  providedIn: 'root'
})
export class LoadingSpinnerService {

  // 1. Reference to our Portal.
  //    This is the portal we'll use to attach our LoadingSpinnerComponent.
  private loadingSpinnerPortal: ComponentPortal<MessageSpinnerComponent>;

  // 2. Reference to our Portal Host.
  //    We use DOMPortalHost as we'll be using document.body as our anchor.
  private bodyPortalHost: DomPortalOutlet;

  // 3. Inject the dependencies needed by the DOMPortalHost constructor
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    @Inject(DOCUMENT) private document: Document,
    private injector: Injector) {

    // 4. Create a Portal based on the LoadingSpinnerComponent
    this.loadingSpinnerPortal = new ComponentPortal(MessageSpinnerComponent);

    // 5. Create a PortalHost with document.body as its anchor element
    this.bodyPortalHost = new DomPortalOutlet(
      this.document.body,
      this.componentFactoryResolver,
      this.appRef,
      this.injector);
  }

  reveal(message: string) {
    // 6. Attach the Portal to the PortalHost.
    const componentRef = this.bodyPortalHost.attach(this.loadingSpinnerPortal);
    componentRef.instance.message = message;
    componentRef.changeDetectorRef.detectChanges();
  }

  hide() {
    // 7. Detach the Portal from the PortalHost
    this.bodyPortalHost.detach();
  }
}
