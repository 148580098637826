import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  faqPdf = '../../../../../assets/JMSS Zing Marketplace FAQ - Oct 2023 update.pdf';

  getFaqPdf() {
    return this.faqPdf;
  }

}
