import { Component, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { FaqService } from './faq.service';

@Component({
  selector: 'z-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  constructor(
    private faqService: FaqService,
    private domSanitizer: DomSanitizer
  ) { }

  documentUrl = '';
  sanitizedUrl: any;

  ngOnInit() {
    this.documentUrl = this.faqService.getFaqPdf();
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.documentUrl);
  }
}
