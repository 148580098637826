export abstract class Encoder {

    public static encode(value: string): string {
        let encodedValue = '';
        try {
            encodedValue = window.btoa(value);
        } catch {
            console.log('Value not sutable for encoding.');
        }
        return encodedValue;
    }
    public static decode(value: string): string {
        let decodedValue = '';
        try {
            decodedValue = window.atob(value);
        } catch {
            console.log('Value not properly decoded.');
        }
        return decodedValue;
    }
}

