import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { Notification } from '../../shared/models/notification.model';
import { ZingHubHttpClient } from '../signalr-clients/zing-hub-http-client';
import { PaginatedResponse } from '../../shared/models/paginated-response.model';
import { FilterDefinition } from '../../shared/models/filter-definition.model';
import { createDataQueryModel } from '../../../utils/odata';
import { FilterDescriptor } from '@progress/kendo-data-query';
import { AuthService } from './auth.service';

@Injectable()
export class NotificationService {

  private connection: HubConnection;
  checkNotifications = new EventEmitter<Boolean>();
  connectionEstablished = new EventEmitter<Boolean>();
  notificationCount: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  userId: any;
  hubConnectionRetryCount = 0;

  unseenNotificationsDescriptor: FilterDescriptor = {
    field: 'NoticeStatus',
    value: 'Unseen',
    operator: 'eq'
  };

  unseenNotificationsFilter: FilterDefinition = {
    skip: 0,
    take: 1,
    filter: { filters:  [ this.unseenNotificationsDescriptor ], logic: 'and' },
    sort: [{
      field: 'CreatedOn',
      dir: 'desc'
    }]
  };

  constructor(private http: HttpClient, private authService: AuthService) {
    this.createConnection();
    this.registerOnServerEvents();
    this.startConnection();
  }

  private createConnection() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.communicationApiURL}/notificationhub`, {
        httpClient: new ZingHubHttpClient(),
        accessTokenFactory: () => this.authService.accessToken
      })
      .withHubProtocol(new MessagePackHubProtocol())
      .build();
  }

  private registerOnServerEvents(): void {
    this.connection.on('CheckNotifications', () => {
      this.checkNotifications.emit(true);
    });

    this.connection.on('NotificationCount', count => {
      this.notificationCount.next(count);
    });
  }

  private startConnection(): void {
    this.connection
      .start()
      .then(() => {
        this.connectionEstablished.emit(true);
      }).catch(err => {
        if (this.hubConnectionRetryCount < 3) {
          this.hubConnectionRetryCount++;
          this.startConnection();
        } else {
          console.log('Hub connection failed', err);
        }
      });
  }

  /**
   * Invokes signal r hub method to get the notification count for the user
   * @param userId User identifier
   */
  public getUserNotificationCount(userId: string) {
    this.connection.invoke('GetUserNotificationCountAsync');
  }

  /**
   * Gets all notifications for the user
   * @param userId User identifier
   */
  public getAllNotifications(userId: string): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${environment.communicationApiURL}/api/usernotifications`);
  }

  /**
   * Marks notification as read
   * @param userId User identifier
   * @param notificationId Notification identifier
   */
  public markNotificationRead(userId: string, notificationId: string) {
    return this.http.put(`${environment.communicationApiURL}/api/usernotifications/${notificationId}/markasread`, {});
  }

  /**
   * Marks all notifications for the user as read
   * @param userId User identifier
   */
  public markAllNotificationRead(userId: string) {
    return this.http.put(`${environment.communicationApiURL}/api/usernotifications/markallasread`, {});
  }

  /**
   * Marks all notifications of a loggedon user as seen.
   */
  public markAllNotificationsAsSeen() {
    return this.http.put(`${environment.communicationApiURL}/api/usernotifications/markallasseen`, {});
  }

  public searchNotifications(filterDefinition: FilterDefinition) {
    const oDataModel = createDataQueryModel(filterDefinition);
    return this.http.post<PaginatedResponse<Notification>>(`${environment.communicationApiURL}/api/usernotifications/query`, oDataModel);
  }

}
