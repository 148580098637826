import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { MessagePackHubProtocol } from '@microsoft/signalr-protocol-msgpack';
import { Observable, BehaviorSubject, throwError, of, Subject } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { ZingHubHttpClient } from '../signalr-clients/zing-hub-http-client';
import { ReportRequest, ReportPrevalidateResponse } from '../../shared/models/report-request.model';
import { switchMap, take, catchError, skip, tap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { SubscriptionUsage } from '../../shared/models/subscription-usage.model';

export enum REPORT_GENERATION {
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE'
}

@Injectable()
export class ReportingService {
  dataReceived = new EventEmitter<{status: REPORT_GENERATION; uri?: string; error?: string}>();
  requestInProgress$ = new BehaviorSubject(false);

  private connectionEstablished$ = new BehaviorSubject(false);
  private _hubConnection: HubConnection;
  private groupId = null;
  hubConnectionRetryCount = 0;

  reportId$ = new Subject<string>();

  constructor(private http: HttpClient, private authService: AuthService) {
    this.createConnection();
    this.registerOnServerEvents();
  }

  private createConnection(): void {
    this._hubConnection = new HubConnectionBuilder()
      .withUrl(`${environment.reportingApiUrl}/reporthub`, {
        httpClient: new ZingHubHttpClient(),
        accessTokenFactory: () => this.authService.accessToken,
      })
      .withHubProtocol(new MessagePackHubProtocol())
      .build();
  }

  /**
   * Starts a connection with the Hub
   */
  private startConnection(): void {
    if (this.connectionEstablished$.value || this.groupId == null) {
      throw new Error('Connection already established or groupId is null.');
    }

    this._hubConnection.start()
      .then(() => this.subscribeToGroup())
      .then(() => this.connectionEstablished$.next(true))
      .catch(() => {
        // retry 3 times if error
        if (this.hubConnectionRetryCount < 3) {
          this.hubConnectionRetryCount++;
          this.startConnection();
        } else {
          this.endConnection();
        }
      });
  }

  /**
   * Registers the possible events that may be generated by the hub
   */
  private registerOnServerEvents(): void {
    this._hubConnection.on('messageReceived', (data: any) => {
      this.dataReceived.emit(data);
    });

    this._hubConnection.on('reportGenerated', (data: any) => {
      this.dataReceived.emit({ status: REPORT_GENERATION.SUCCESS, uri: data });
      this.endConnection();
    });

    this._hubConnection.on('reportFailed', (data: any) => {
      this.dataReceived.emit({ status: REPORT_GENERATION.FAILURE, error: data });
      this.endConnection();
    });
  }

  /**
   * Ends the connection with the Hub
   */
  private endConnection(): void {
    if (this.connectionEstablished$.value) {
      this.unsubscribeFromGroup()
        .then(() => this._hubConnection.stop())
        .then(() => this.reset())
        .catch(() => this.reset());
    } else {
      this.reset();
    }
  }

  /**
   * Resets the field values
   */
  private reset(): void {
    this.connectionEstablished$.next(false);
    this.requestInProgress$.next(false);
    this.groupId = null;
  }

  /**
   * Internal subscribe to the group the connection is at
   */
  private async subscribeToGroup() {
    return this._hubConnection.invoke('subscribeToGroup', this.groupId);
  }

  /**
   * Internal unsubscribe from the group the connection is at
   */
  private async unsubscribeFromGroup() {
    return this._hubConnection.invoke('unsubscribeFromGroup', this.groupId);
  }

  /**
   * Sets the group for the client to listen on for report update
   *
   * @param groupId The identifier returned by the api call when requesting a report
   */
  private initializeGroup(groupId: string) {
    if (this.groupId == null) {
      this.groupId = groupId;
    }
  }

  /**
   * Makes a request to the report api for a report
   *
   * @param requestData The necessary data for the report service
   */
  public requestReport(requestData: ReportRequest): Observable<ReportPrevalidateResponse> {
    return this.http.post<ReportPrevalidateResponse>(`${environment.reportingApiUrl}/api/Reports`, requestData);
  }

  /**
   * Process Request Report
   *
   * @param requestid
   */
  public requestReportDetails(requestid: string): Observable<ReportRequest> {
    return this.http.get<ReportRequest>(`${environment.reportingApiUrl}/api/Reports/${requestid}`);
  }

  /**
   * Process Request Report
   *
   * @param requestid
   */
  public processReport(requestid: string): Observable<ReportPrevalidateResponse> {
    return this.http.get<ReportPrevalidateResponse>(`${environment.reportingApiUrl}/api/Reports/${requestid}/process`);
  }

  /**
   * Downloads the report from the specified url
   *
   * @param url The uri containing the blob
   */
  public receiveReport(uri: string): Observable<Blob> {
    return this.http.get<Blob>(`${environment.reportingApiUrl}${uri}`,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/octet-stream',
          'Authorization': 'Bearer ' + this.authService.accessToken
        }), responseType: 'blob' as 'json'
      });
  }

  /**
   * Request a report, connects to report request hub, then processes a it
   *
   * @param requestData
   */
  public processReportRequest(requestData: ReportRequest): Observable<any> {
    if (this.requestInProgress$.value) {
      return throwError('Request already in progress');
    }

    let requestId = '';
    return of({}).pipe(
      tap(() => this.requestInProgress$.next(true)),
      switchMap(() => this.requestReport(requestData)),
      switchMap(res => {
        requestId = res.id;
        this.reportId$.next(requestId);
        this.hubConnectionRetryCount = 0;
        this.initializeGroup(requestId);
        this.startConnection();
        return this.connectionEstablished$;
      }),
      skip(1), // skip initial false
      take(1),
      switchMap(isConnected => isConnected
        ? this.processReport(requestId)
        : throwError('Could not connect to reporting hub.')
      ),
      catchError(err => {
        this.endConnection();
        return throwError(err);
      })
    );
  }

  /**
   * Creates Subscription Usage
   *
   * @param subscriptionUsage
   */
  public createSubscriptionUsage(subscriptionUsage: SubscriptionUsage): Observable<void> {
    return this.http.post<void>(`${environment.reportingApiUrl}/api/subscriptionusages`, subscriptionUsage);
  }
}

