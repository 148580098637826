import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { AuthService } from '../modules/core/services/auth.service';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {

  constructor(private authService: AuthService) {

  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.authService.accessToken;
    if (accessToken !== null && accessToken !== undefined && accessToken !== '') {
      const cloneReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
      });
      return next.handle(cloneReq);
    }
    return next.handle(req);
  }

}
