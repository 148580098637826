import { DefaultHttpClient, HttpRequest, HttpResponse } from '@microsoft/signalr';
import { environment } from '../../../../environments/environment';

/** Error thrown when an action is aborted. This is copy from @microsoft/signalr npm package */
export class AbortError extends Error {
  // @ts-ignore: Intentionally unused.
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
  private __proto__: Error;

  /** Constructs a new instance of {@link AbortError}.
   *
   * @param errorMessage A descriptive error message.
   */
  constructor(errorMessage: string = 'An abort occurred.') {
    const trueProto = new.target.prototype;
    super(errorMessage);

    // Workaround issue in Typescript compiler
    // https://github.com/Microsoft/TypeScript/issues/13965#issuecomment-278570200
    this.__proto__ = trueProto;
  }
}

/**
 * Te purpose of extending this DefaultHttpClient from @microsoft/signalr
 * is to be able to inject custom headers into every requests
 * https://github.com/aspnet/SignalR/blob/master/clients/ts/signalr/src/DefaultHttpClient.ts
 */
export class ZingHubHttpClient extends DefaultHttpClient {
  private readonly ocpApimTrace: string = 'Ocp-Apim-Trace';
  private readonly ocpApimSubscriptionKey: string = 'Ocp-Apim-Subscription-Key';

  constructor() {
    super(null);
  }

  /** @inheritDoc */
  public send(request: HttpRequest): Promise<HttpResponse> {
    // Check that abort was not signaled before calling send
    if (request.abortSignal && request.abortSignal.aborted) {
      return Promise.reject(new AbortError());
    }

    if (!request.method) {
      return Promise.reject(new Error('No method defined.'));
    }
    if (!request.url) {
      return Promise.reject(new Error('No url defined.'));
    }

    const enableTrace = environment.apiManagement.enableTrace;
    const subscriptionKey = environment.apiManagement.SubscriptionKey;

    request.headers[this.ocpApimTrace] = enableTrace.toString();
    request.headers[this.ocpApimSubscriptionKey] = subscriptionKey;

    return super.send(request);
  }
}
