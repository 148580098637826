import { CompanyDetails } from '../register/store/reducers/register.reducer';

export enum InviteStatus {
  Sent = 0,
  NotSent = 1,
  Accepted = 2,
  NotAccepted = 3
}

export interface InviteCompany {
  name: string;
  feinId: string;
  city: string;
  state: string;
  addressLine1?: string;
  addressLine2?: string;
  stateName?: string;
  postalCode?: string;
  countryId: string;
  countryCode: string;
  phone: string;
}

export interface Invite {
  id: string;
  email: string;
  invitedBy: string;
  invitedOn: Date;
  inviteCode: string;
  firstName: string;
  lastName: string;
  inviteExpiresOn: Date;
  inviteStatus: InviteStatus;
  isActive: boolean;
  isBusinessOwner: boolean;
  company: InviteCompany;
  personInfo: {
    firstName: string;
    lastName: string;
    middleName: string;
    cellPhone: string;
    countryCode: string;
  };
  locationId: string;
}

export function getComanyDetailsState(company: InviteCompany): CompanyDetails {
  const companyDetails = { ...company };
  companyDetails['companyName'] = company.name;
  companyDetails['stateId'] = company.state;
  companyDetails['postCode'] = company.postalCode;
  companyDetails['countryId'] = company.countryId === 'US' ? 'USA' : company.countryId;
  delete companyDetails.name;
  delete companyDetails.state;
  return companyDetails;
}
