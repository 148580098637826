import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { SecurityQuestion } from '../models/security-question';
import { Observable, of } from 'rxjs';
import { Invite } from '../../guest/modules/models/invite';
import { map, tap } from 'rxjs/operators';
import { encodeBase64 } from '@progress/kendo-file-saver';

@Injectable({
  providedIn: 'root'
})
export class AccountService {

  public usersSecurityQuestions: Array<SecurityQuestion> = new Array<SecurityQuestion>();

  constructor(
    private http: HttpClient
  ) {
    this.usersSecurityQuestions = [];
  }

  getSecurityQuestions() {
    const url = environment.identityApiURL + '/api/SecurityQuestions/search';
    return this.http
      .post<Array<SecurityQuestion>>(url, {})
      .pipe(map(response => response['items']));
  }

  register(data: any) {
    const url = environment.identityApiURL + '/Account/Register';
    return this.http.post(url, data);
  }

  registerViaInvite(data: any) {
    const url = environment.identityApiURL + `/Account/RegisterViaInvite/${data.inviteCode}`;
    return this.http.post(url, data);
  }

  resetPassword(data: {
    email: string,
    token: string,
    newPassword: string
  }) {
    const url = environment.identityApiURL + '/Account/ResetPassword';
    return this.http.post(url, data);
  }

  resetClPassword(data: {
    email: string;
  }) {
    const url = environment.identityApiURL + '/account/CLRegistrationResetPassword';
    return this.http.post(url, data);
  }

  loginWith2fa(provider: string, twoFactorCode: string) {
    const url = environment.identityApiURL + '/Account/LoginWith2fa';
    const providerType = provider === 'email' ? 'Email' : 'Phone';
    return this.http.post(url, { providerType, twoFactorCode });
  }

  verifyInviteCode(inviteCode: string): Observable<Invite> {
    const url = environment.membershipApiURL + `/api/Invites/Code?invitecode=${inviteCode}`;
    return this.http.get<Invite>(url);
  }
  /**
   * Get Person's Security questions.
   * @param userId ReferenceId of the Person.
   */
  getUsersSecurityQuestions(userId: string): Observable<Array<SecurityQuestion>> {
    if (this.usersSecurityQuestions.length === 0) {
      const url = environment.identityApiURL + `/Account/GetSecurityQuestionFromUser/${userId}`;
      return this.http
        .get<Array<SecurityQuestion>>(url).pipe(tap((result) => {
          if (result) {
            this.usersSecurityQuestions = result;
          }
        }));
    } else {
      return of(this.usersSecurityQuestions);
    }
  }
  /**
   * Update current User's security questions.
   * @param changedQuestions Updated security questions with User Id for which questions are updated.
   */
  changeSecurityQuestions(changedQuestions) {
    const url = environment.identityApiURL + `/Account/ChangeSecurityQuestions`;

    const answer1 = changedQuestions['securityQAs'][0]['answerText'];
    const answer2 = changedQuestions['securityQAs'][1]['answerText'];

    changedQuestions['securityQAs'][0]['answerText'] = encodeBase64(answer1);
    changedQuestions['securityQAs'][1]['answerText'] = encodeBase64(answer2);

    return this.http
      .post(`${url}`, changedQuestions);
  }

  getExistingUserDetails(guid: string): Observable<any> {
    const url = environment.membershipApiURL + `/api/usermigration/${guid}`;
    return this.http.get(url);
  }
}
