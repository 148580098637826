import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AgreementModalConfig, defaultAgreementModalConfig } from '../models/agreement-modal.config';

@Component({
  selector: 'z-agreement-modal',
  templateUrl: './agreement-modal.component.html',
  styleUrls: ['./agreement-modal.component.scss']
})
export class AgreementModalComponent implements OnInit {

  @Output() continue = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  config: AgreementModalConfig = defaultAgreementModalConfig;

  formGroup: FormGroup;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
    if (this.config.checkbox) {
      this.formGroup = new FormGroup({
        checkbox: new FormControl(false, Validators.requiredTrue)
      });
    }
  }

  onCloseModal() {
    this.cancel.emit();
    this.bsModalRef.hide();
  }

  onAgree() {
    this.continue.emit();
  }
}
