import { Injectable } from '@angular/core';
import * as Bowser from 'bowser';
import { ListOfSupportedBrowsers } from '../../../../assets/browsers/browsers';

@Injectable({
  providedIn: 'root'
})
export class UnsupportedBrowserService {

  detectedBrowser = Bowser.getParser(window.navigator.userAgent);

  constructor() { }

  checkForBrowserValidity() {
    if (ListOfSupportedBrowsers === undefined || this.detectedBrowser === undefined) {
      return true;
    } else if (ListOfSupportedBrowsers.os.indexOf(this.detectedBrowser.getOSName()) === -1 && this.detectedBrowser.getOSName()) {
      return false;
    } else if (ListOfSupportedBrowsers.browsers.indexOf(this.detectedBrowser.getBrowserName()) === -1) {
      return false;
    } else if (this.detectedBrowser.satisfies(ListOfSupportedBrowsers.minversions) === false && this.detectedBrowser.getBrowserVersion()) {
      return false;
    } else {
      return true;
    }
  }
}
