import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';

@Injectable()
export class ContentTypeJsonInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.headers.has('Content-Type') && (req.method === 'POST' || req.method === 'PUT') && !req.headers.has('uploadFile')) {
      // cloned headers, updated with the Content-Type.
      let cloneReq;

      if(req.headers.has('Accept')){
        cloneReq = req.clone({
          headers: req.headers
            .set('Content-Type', 'application/json')
        });
      } else {
        cloneReq = req.clone({
          headers: req.headers
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json, text/plain, */*')
        });
      }

      // send cloned request with header to the next handler.
      return next.handle(cloneReq);
    }

    return next.handle(req);
  }

}
