export enum DateRangeType {
  Today = 1,
  Last7Days = 2,
  CurrentMonth = 3,
  LastMonth = 4,
  Last3Months = 5,
  Last6Months = 6,
  AllHistory = 7,
  SpecificDateRange = 8,
  Last60Days = 9,
  Last15Days = 10,
  Last30Days = 11,
}
