import { PackagingType } from '../enums/packaging-type';
import { ShipmentStatus, CarrierType, Quote, AddressValidationStatus } from './shipment';
import { ServiceType } from '../enums/service-type';
import { PackageType } from '../enums/package-type';
import { PurchaseDetails } from '../store/reducers/shipping.reducer';
import { DeliveryConfirmationType } from '../enums/delivery-confirmation-type';
import { SavedShipmentState } from '../enums/saved-shipment-state';
import { InternationalDetail } from './international-shipment.model';
import { ClaimValidity } from '../enums/claim-validity.enum';
import { ShipmentPriceType } from '../enums/shipment-price-type.enum';
import { HighRiskDeliveryWarning } from '../enums/high-risk-delivery-warning.enum';
import { HoldForPickupAddress } from './hold-for-pickup-address.model';

export class OriginalShipmentDetails {
  id: string;
  childShipmentCreateType?: ShipmentCreateType;
  name: string;
  zingTrackingId: string;
  carrierTrackingId: string;
  trackingInfo: string;
  senderReference: string;
  receiverReference: string;
}

export enum ShipmentActionType {
  New = 'new',
  View = 'view',
  TrackingDetails = 'trackingDetails',
  Billing = 'billing',
  Save = 'save',
  Edit = 'edit',
  Delete = 'delete',
  AccessLabel = 'accessLabel',
  Boomerang = 'boomerang',
  Forward = 'forward',
  Track = 'track',
  PrintLabel = 'printLabel',
  RequestReview = 'requestReview',
  FileAClaim = 'fileAClaim',
  Void = 'void',
  EmailBoomerangLabel = 'emailBoomerangLabel',
  DownloadTransactionReceipt = 'downloadTransactionReceipt',
  ReturnLabel = 'returnLabel'
}

interface AddressOptions {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  stateId?: string;
  state?: string;
  postalCode?: string;
  countryCode?: string;
  latitude?: number;
  longitude?: number;
  altitude?: number;
  createNewContact?: boolean;
  updateExistingContact?: boolean;
  makeDefaultContact?: boolean;
}

export class Address {

  addressLine1 = '';
  addressLine2 = '';
  city = '';
  stateId = '';
  state = '';
  postalCode = '';
  countryCode = '';
  latitude = 0;
  longitude = 0;
  altitude = 0;

  constructor(
        addressOptions: AddressOptions = {}
  ) {
    Object.keys(addressOptions)
      .filter(propName => this.hasOwnProperty(propName))
      .forEach(propName => this[propName] = addressOptions[propName]  === null ? '' : addressOptions[propName]);
  }
}

interface ContactOptions extends AddressOptions {
  id?: string;
  firstName?: string;
  lastName?: string;
  referenceName?: string;
  phone?: {
    callingCode: string;
    number: string;
  };
  emailAddress?: string;
  company?: string;
  isFavorite?: boolean;
  isResidential?: boolean;
  isDefaultAddress?: boolean;
  isEmailRequired?: boolean;
  validationStatus?: AddressValidationStatus;
  highRiskDeliveryWarning?: HighRiskDeliveryWarning;
  sendShipmentNotification?: boolean;
  skipStandardizedCheck?: boolean;
}

export class Contact extends Address {

  id = '00000000-0000-0000-0000-000000000000';
  firstName = '';
  lastName = '';
  phone = {
    callingCode: '',
    number: ''
  };
  emailAddress = '';
  isEmailRequired = true;
  referenceName = '';
  company = '';
  isFavorite = false;
  isEditFavorite = false;
  isResidential = false;
  isDefaultAddress = false;
  userId = '';
  companyId = '';
  clientId = 0;
  isUninsured = false;
  validationStatus = AddressValidationStatus.None;
  highRiskDeliveryWarning = HighRiskDeliveryWarning.None;
  sendShipmentNotification = false;
  skipStandardizedCheck = false;

  constructor(
        contactOptions: ContactOptions = {}
  ) {
    super(contactOptions);
    Object.keys(contactOptions)
      .filter(propName => this.hasOwnProperty(propName))
      .forEach(propName => this[propName] = contactOptions[propName] === null ? '' : contactOptions[propName]);
    this.countryCode = this.countryCode === 'US' ? 'USA' : this.countryCode;
  }

  set address(value: Address) {
    Object.keys(value)
      .filter(propName => this.hasOwnProperty(propName))
      .forEach(propName => this[propName] = value[propName]);
  }

  get address() {
    return new Address({
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      stateId: this.state,
      state: '',
      postalCode: this.postalCode,
      countryCode: this.countryCode
    });
  }

  get phoneNumber() {
    if (this.phone.callingCode && this.phone.number) {
      return this.phone.callingCode + '-' + this.phone.number;
    } else if (this.phone.number) {
      return this.phone.number;
    } else {
      return '';
    }
  }

  set phoneNumber(phoneNumber: string) {
    if (phoneNumber !== null && phoneNumber !== '' && typeof phoneNumber !== 'undefined') {
      const parts = phoneNumber.split('-');
      if (parts.length === 2) {
        this.phone.callingCode = phoneNumber.split('-')[0];
        this.phone.number = phoneNumber.split('-')[1];
      } else if (phoneNumber.startsWith('+1', 0)) {
        this.phone.callingCode = phoneNumber.substr(0, 2);
        this.phone.number = phoneNumber.substr(2);
      } else {
        this.phone.callingCode = '';
        this.phone.number = phoneNumber;
      }
    }
  }

  get isInternational() {
    return this.countryCode && !(this.countryCode === 'US' || this.countryCode === 'USA');
  }

  get isLocal() {
    return this.countryCode && (this.countryCode === 'US' || this.countryCode === 'USA');
  }

  get contactName() {
    return [this.firstName, this.lastName].filter(el => !!el).join(' ');
  }

  displayName() {
    let retValue = '';
    if (this.company) {
      retValue = this.company;
    }
    if (this.firstName && this.lastName) {
      retValue += (retValue ? ' c/o ' : '') + this.firstName + ' ' + this.lastName;
    } else if (this.firstName) {
      return retValue += (retValue ? ' c/o ' : '') + this.firstName;
    } else if (this.lastName) {
      return retValue += (retValue ? ' c/o ' : '') + this.lastName;
    }
    return retValue;
  }

  getNameWithAddress() {
    let retValue = this.displayName();
    retValue += ', ' + [
      this.addressLine1,
      this.addressLine2,
      this.city,
      this.state,
      this.postalCode
    ]
    // From address details get only the values which are not empty
      .filter(addressPart => addressPart)
      .join(', ');
    return retValue;
  }

  toJSON() {
    return {
      id: this.id,
      firstName: this.firstName,
      lastName: this.lastName,
      phoneNumber: this.phoneNumber || null,
      emailAddress: this.emailAddress,
      referenceName: this.referenceName,
      company: this.company,
      isFavorite: this.isFavorite,
      isResidential: this.isResidential,
      addressLine1: this.addressLine1,
      addressLine2: this.addressLine2,
      city: this.city,
      state: this.state,
      postalCode: this.postalCode,
      countryCode: this.countryCode,
      isDefaultAddress: this.isDefaultAddress,
      isEmailRequired: this.isEmailRequired,
      isUninsured: this.isUninsured,
      validationStatus: this.validationStatus,
      highRiskDeliveryWarning: this.highRiskDeliveryWarning,
      sendShipmentNotification: this.sendShipmentNotification,
      skipStandardizedCheck: this.skipStandardizedCheck
    };
  }
}

export class InvalidContact extends Contact {
  companyNameInvalid: boolean;
  firstNameInvalid: boolean;
  lastNameInvalid: boolean;
  addressLine1Invalid: boolean;
  cityInvalid: boolean;
  countryInvalid: boolean;
  postalCodeInvalid: boolean;
  phoneNumberInvalid: boolean;
  emailInvalid: boolean;
  blobKey: string;
}

export enum AddressSource {
  API,
  AddressBook,
  DefaultAddress,
  Custom,
  Company,
  DefaultCompanyLocation
}

interface ShipmentLocationOptions extends ContactOptions {
  companyId?: string;
  userId?: string;
  valid?: boolean;
  complete?: boolean;
  source?: AddressSource;
}

export class ShipmentLocation extends Contact {

  companyId = '';
  userId = '';
  valid = false;
  complete = false;
  source = AddressSource.Custom;
  createNewContact = false;
  updateExistingContact = false;
  makeDefaultContact = false;

  constructor(
        shipmentLocationOptions: ShipmentLocationOptions = {}
  ) {
    super(shipmentLocationOptions);
    Object.keys(shipmentLocationOptions)
      .filter(propName => this.hasOwnProperty(propName))
      .forEach(propName => this[propName] = shipmentLocationOptions[propName] === null ? '' : shipmentLocationOptions[propName]);
  }

  toJSON() {
    return {
      ...super.toJSON(),
      createNewContact: this.createNewContact,
      updateExistingContact: this.updateExistingContact,
      makeDefaultContact: this.makeDefaultContact,
      source: this.source
    };
  }
}

export interface Weight {
  value: number;
  unit: WeightUnit;
}

export interface ShipmentPackage {
  packagingTypes: Array<PackagingType>;
  packageDetails: Array<number>;
  weights: Array<Weight>;
  dimensions?: {
    length?: number;
    width?: number;
    height?: number;
  };
}
export interface ShipmentModel {
  id?: string;
  origin: ShipmentLocation;
  destination?: ShipmentLocation;
  holdForPickupAddress?: HoldForPickupAddress;
  name?: string;
  createType?: ShipmentCreateType;
  originalShipmentId?: string;
  cargoId?: string;
  carriers?: Array<CarrierType>;
  insuranceValue?: number;
  ignoreBlock?: boolean;
  signatureOptions?: DeliveryConfirmationType[];
  approvalCode?: string;
  isHoldForPickup?: boolean;
  isMailRegistered?: boolean;
  shipmentQuoteId?: string;
  selectedQuoteId?: string;
  shippingDate?: Date;
  package?: ShipmentPackage;
  status: ShipmentStatus;
  services?: Array<ServiceType>;
  selectedQuoteDetails?: Quote;
  sealNumber?: string;
  senderReference?: string;
  receiverReference?: string;
  includeSenderReference?: boolean;
  includeReceiverReference?: boolean;
  purchase?: PurchaseDetails;
  hasApprovalCode?: boolean;
  tracking?: Tracking;
  createdOn?: Date;
  zingTrackingNumber?: string;
  rates?: Array<Rate>;
  modifiedOn?: Date;
  companyId?: string;
  approvalContact?: ApprovalContact;
  shippingPresetId?: string;
  declaredValueEntered?: number;
  clientId?: number;
  codSelected?: boolean;
  codService?: CodService;
  thirdPartySelected?: boolean;
  thirdPartyService?: ThirdPartyService;
  voidDetail?: VoidDetail;
  claimDetail?: ClaimDetail;
  declaredValue?: number;
  blockCoverage?: number;
  shipmentSource?: ShipmentSource;
  savedShipmentState?: SavedShipmentState;
  invoiceValue?: number;
  internationalDetail?: InternationalDetail;
  isInternational?: boolean;
  insuranceOnly?: boolean;
  insuranceOnlyTrackingNumber?: string;
  eStoreDetail?: EStoreDetail;
  isSaturdayDelivery?: boolean;
  isDeclined?: boolean;
  isScanBasedBilling?: boolean;
  carrierShipDate?: Date;
  carrierDeliveryDate?: Date;
  isReconciled?: boolean;
  purchaseError?: string;
  approvalData?: Approval;
  purchaseState?: ShipmentPurchaseState;
  priceTypePrefferred?: ShipmentPriceType;
  isFedexOneRate?: boolean;
}

export enum ShipmentPurchaseState {
  Processing,
  Success,
  Error
}

export interface Approval {
  approvedShipmentId: number;
  comment: string;
  error: { errorCode: string; errorMessage: string }[];
}

export interface ShipmentRecnciliationModel {
  shipmentId: number;
  shipmentGid: string;
  purchasedOn: Date;
  trackingNumber: string;
  reference: string;
  fromCompany: string;
  toCompany: string;
  toContact: string;
  cargo: number;
  carrier: number;
  service: number;
  comment: string;
  reviewComment: string;
  reconAmount: number;
  reconciledOn: Date;
  originalPrice: number;
  totalPrice: number;
  invoiceReference: string;
  clientId: number;
}

export interface Month {
  month: number;
  year: number;
}

export interface ShippingPreset {
  id?: string;
  origin?: ShipmentLocation;
  destination?: ShipmentLocation;
  name: string;
  cargoId?: string;
  signatureOptions?: DeliveryConfirmationType[];
  package?: ShipmentPackage;
  carriers?: Array<CarrierType>;
  services?: Array<ServiceType>;
  isMailRegistered?: boolean;
  hasApprovalCode?: boolean;
  isFavorite?: boolean;
  rank?: number;
  userId?: string;
  isDefault?: boolean;
}

export interface Tracking {
  id: string;
  trackingNumber: string;
  trackingUrl: string;
  emails: Array<string>;
  phoneNumbers: Array<string>;
  error: string;
  signedBy: string;
  activities: Array<TrackingActivity>;
}

export interface TrackingActivity {
  description: string;
  carrierName: string;
  carrierActivityCode: string;
  status: TrackingStatus;
  city: string;
  state: string;
  zipCode: string;
  countryCode: string;
  createdDate: Date;
}

export interface EStoreDetail {
  orderId: string;
  orderSource: OrderSource;
  storeType: StoreType;
  customersStoreRef: string;
  shipVia: string;
}

export enum OrderSource {
  Undefined = 0,
  Store = 1,
  Shiprush = 2,
  Integral = 3,
  File = 4,
  Shippo = 5,
  Api2Cart = 6
}

export enum StoreType {
  Undefined = 0,
  Ebay = 1,
  Amazon = 2,
  SolidCommerce = 3,
  Magento = 4,
  Volusion = 5,
  BigCommerce = 6,
  WooCommerce = 7,
  Shopify = 8,
  OpenCart = 9,
  UltraCart = 10,
  Contusult = 11,
  PayPal = 12,
  Yahoo = 13,
  ZenCart = 14,
  XCart = 15,
  OsCommerce = 16,
  Custom = 17,
  PostIn = 18,
  Etsy = 19,
  ChannelAdvisor = 20,
  CReloaded = 21,
  UberCart = 22,
  AspDotnetStoreFront = 23,
  ThreeDCart = 24,
  LoadedCommerce = 25,
  SellerCloud = 26,
  AceShop = 27,
  CsCart = 28,
  CubeCart = 29,
  DemandWare = 30,
  Ecwid = 31,
  Gambio = 32,
  Hybris = 33,
  Interspire = 34,
  JoeCart = 35,
  MijoShop = 36,
  Neto = 37,
  Oxid = 38,
  OsCmax = 39,
  Pinnacle = 40,
  PrestaShop = 41,
  SsPremium = 42,
  ShopWare = 43,
  SquareSpace = 44,
  TomatoCart = 45,
  VirtueMart = 46,
  WpeCommerce = 47,
  Walmart = 48,
  WebAsyst = 49,
  XtCommerce = 50,
  XtCommerceEveyton = 51,
  LightSpeed = 52,
  ShipStation = 53,
  BatchFile = 1000,
  Royal4Wise = 1001,
  Ftp = 1002,
}

export enum TrackingStatus {
  NotChecked = -1,
  Forwarded = 10,
  Exception = 20,
  LeftNotice = 30,
  Undeliverable = 40,
  Returned = 50,
  Unknown = 60,
  Unscanned = 70,
  Enroute = 80,
  Delivered = 90,
  Unavailable = 100,
  Finalized = 110,
  ManualClose = 111,
  StaleTracking = 21
}

export enum ShipmentCreateType {
  Regular = 0,
  Forward = 1,
  Boomerang = 2
}

export enum WeightUnit {
  Ounces = 0,
  Pounds = 1,
  Grams = 2
}

export interface ShipmentQuote {

  gId: string;
  clientId: string;
  originPostalCode: string;
  destinationPostalCode: string;
  isResidential: string;
  packageDimX: number;
  packageDimY: number;
  packageDimZ: number;
  value: number;
  weightZ: number;
  cargoType: any;
  shipingDate: Date;
  magicSeal: boolean;
  ignoreBlock: boolean;
  registeredMail: boolean;
  carriers: Array<CarrierType>;
  services: Array<ServiceType>;
  packageDetail: PackageType;
  signatures: Array<any>;
  quotes: Array<Quote>;
}

export interface Rate {
  price: number;
  insurancePrice: number;
  freightPrice: number;
  carrier: CarrierType;
  service: ServiceType;
  signature: DeliveryConfirmationType;
  deliveryDate: Date;
  serviceDescription: string;
  accountNickname: string;
  claimValidity?: ClaimValidity;
  totalAdminFees: number;
}

export class Claim {
  id?: string;
  clientId?: string;
  shipmentId?: number;
  shipmentGid?: string;
  claimantAddressSameAsClient?: boolean;
  fromAddress?: ClaimAddress;
  toAddress?: ClaimAddress;
  shipDate?: Date;
  claimDueDate?: Date;
  claimFiledOn?: Date;
  trackingNumber?: string;
  insuredValue?: string;
  claimantAddress?: ClaimAddress;
  packageCount?: number;
  weights?: Array<Weight>;
  postagePurchased?: boolean;
  status?: ClaimStatus;
  currentStatus?: string;
  cargo?: string;
  nextSteps?: string;
  updateNotes?: string;
  carrierClaimNumber?: string;
  claimValue?: number;
  lossCircumstances?: string;
  lossType?: LossDamageType;
  claimReason?: ClaimReasonsType;
  isRolexOrGiaTheft?: boolean;
  policeReportInformation?: string;
  packageContents?: string;
  damageDescription?: string;
  fileNames?: Array<string>;
  claimId?: string;
}

export interface ClaimAddress {
  id: string;
  clientId?: string;
  addressType: ClaimAddressType;
  reference?: string;
  company: string;
  contact: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  state: string;
  zip: string;
  countryCode: string;
  phoneNumber: string;
  emailAddress: string;
  sendNotificationEmail?: boolean;
  residential?: boolean;
  phone?: {
    callingCode: string;
    number: string;
  };
}

export interface ClaimDocument {
  id: string;
  file: File;
}

export class ApprovalContact {
  contact: string;
  emailAddress: string;
  phoneNumber: string;
}

export enum ClaimStatus {
  AllShipments = 0,
  AllClaims = 1,
  NoClaim = 2,
  NotSubmitted = 3,
  Submitted = 4,
  Acknowledged = 5,
  NeedShipperInfo = 6,
  NeedRecipientInfo = 7,
  NeedCarrierInfo = 8,
  WithUnderwriter = 9,
  Investigating = 10,
  Approved = 11,
  Paid = 12,
  Withdrawn = 13,
  Denied = 14,
  NpolPending = 15,
  Archived = 16
}

export enum LossDamageType {
  Unknown = 0,
  Complete = 1,
  Partial = 2
}

export enum ClaimReasonsType {
  Unknown = 0,
  ScannedAsDeliveredButRecipientDidNotReceive = 1,
  CarrierFailedToDeliver = 2,
  ContentsDamagedInShipping = 3,
  ContentsStolenOrMissing = 4,
  Other = 5
}

export enum ClaimAddressType {
  Unspecified = 0,
  Origin = 1,
  Destination = 2
}

export interface QuickQuote {
  origin: {
    state: string;
    postalCode: string;
  };
  destination: {
    state: string;
    postalCode: string;
  };
  weight: Array<Weight>;
  declaredValue: number;
  cargoType: string;
  signature: DeliveryConfirmationType;
  package?: {
    packageDetails: Array<number>;
    packagingTypes: Array<PackagingType>;
  };
  carriers: Array<number>;
  services: Array<ServiceType>;
}

export interface VoidDetail {
  voidedBy: string;
  voidedOn: Date;
}

export interface ClaimDetail {
  id: string;
  claimId: number;
  claimFiledOn: Date;
}

export interface CodService {
  fundType?: CodFundType;
  collectionAmount?: string;
  fedExRemittanceAddress?: ShipmentLocation;
  fedExRemittanceAddressSelected?: boolean;
}

export interface ThirdPartyService {
  accountNumber?: string;
  accountZipCode?: string;
  type?: ThirdPartyServiceType;
}

export enum ShipmentSource {
  Platform = 0,
  IShip = 1,
  External = 2
}

export enum LabelType {
  None = 0,
  No_TAB = 1,
  Three_Quarter_Inch_Tab = 2,
  Two_Inch_Tab = 3
}

export enum CodFundType {
  None = 0,
  Secure = 1,
  Any = 2
}

export enum ThirdPartyServiceType {
  NoneSelected = 0,
  ThirdPartyFedEx = 1,
  RecipientFedEx = 2,
  ThirdPartyUPS = 3,
  RecipientUPS = 4
}
