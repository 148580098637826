import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import {
  DiamondSearchParameters,
  DiamondSearchResults,
  DiamondDetailsParameters,
  DiamondDetailsResult,
  BasicSearchFilter
} from '../models';
import { BasicSearchFilterOptions, SearchFilterOptions } from '../shared/models';

import { DiamondDetailsEmailMessage } from '../models/diamond-details-email-message';
import { environment } from '../../../../environments/environment';
import { DiamondQuestionEmail } from '../models/diamond-question-email';
import { SearchPreset } from '../models/search-preset';
import { SelectedSearchCriteria } from '../../../elements/diamond-search/diamond-search.model';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
  private readonly apiUrl = environment.marketplaceApiURL;

  constructor(private http: HttpClient) { }

  emailDiamondDetails(diamondDetailsEmail: DiamondDetailsEmailMessage) {
    return this.http.post(`${this.apiUrl}/api/emails/${diamondDetailsEmail.enquireType}/send`, diamondDetailsEmail);
  }

  emailDiamondQuestion(diamondQuestionEmail: DiamondQuestionEmail) {
    return this.http.post(`${this.apiUrl}/api/emails/sendquestion`, diamondQuestionEmail);
  }

  getSearchFilterOptions() {
    return this.http.get<SearchFilterOptions>(`${this.apiUrl}/api/products/searchcriterias`);
  }

  getDiamondSearchResults(diamondSearchParameters: DiamondSearchParameters) {
    return this.http.post<DiamondSearchResults>(`${this.apiUrl}/api/diamonds/search`, diamondSearchParameters);
  }

  getBasicSearchFilters() {
    return this.http.get<BasicSearchFilterOptions>(`${this.apiUrl}/api/searchcriteria/filters`);
  }

  getDiamondBasicSearchResults(filters: BasicSearchFilter) {
    return this.http.post<DiamondSearchResults>(`${this.apiUrl}/api/diamonds/search/basic`, filters);
  }

  getSelectedSearchCriteria(criteriaID: string) {
    return this.http.get<SelectedSearchCriteria>(`${this.apiUrl}/api/searchcriteria/selection/${criteriaID}`);
  }

  getDiamondDetailsResult(diamondDetailsParameters: DiamondDetailsParameters) {
    return this.http.post<DiamondDetailsResult>(`${this.apiUrl}/api/diamonds/details`, diamondDetailsParameters);
  }

  fetchSearchPresets() {
    return this.http.get<SearchPreset[]>(`${this.apiUrl}/api/diamonds/searchpresets`);
  }

  addSearchPreset(searchPreset: SearchPreset) {
    return this.http.post<SearchPreset>(`${this.apiUrl}/api/diamonds/searchpresets`, searchPreset);
  }

  deleteSearchPreset(id: string) {
    return this.http.delete(`${this.apiUrl}/api/diamonds/searchpresets/${id}`);
  }

  saveSelectedCriteria(criteria: SelectedSearchCriteria) {
    return this.http.post<{ criteriaId: string }>(`${this.apiUrl}/api/searchcriteria/selection`, criteria);
  }
}
