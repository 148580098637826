import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../../store/root.state';
import { Company } from '../models/company.model';
import { CompanyActions, CompanyActionTypes } from './company.actions';
import { CompanyLocation } from '../models/location.model';
import { BusinessUnit } from '../models/business-unit-model';

export interface ZingState extends fromRoot.ZingState {
  companyState: CompanyState;
}

export interface CompanyState {
  error?: any;
  isLoading?: boolean;
  company: Company;
  location: CompanyLocation;
  locations: Array<CompanyLocation>;
  businessUnits: Array<BusinessUnit>;
  isLoadingBU?: boolean;
  isLoadingLocations?: boolean;
}

export const initialState: CompanyState = {
  company: null,
  location: null,
  locations: null,
  businessUnits: null
};

export const getCompanyFeatureState = createFeatureSelector<CompanyState>('company');

export const getCompanyState = createSelector(
  getCompanyFeatureState,
  state => state
);

export const getIsLoading = createSelector(
  getCompanyFeatureState,
  state => state.isLoading
);

export const getCompany = createSelector(
  getCompanyFeatureState,
  state => state.company
);

export const getLocation = createSelector(
  getCompanyFeatureState,
  state => state.location
);

export const getBusinessUnits = createSelector(
  getCompanyFeatureState,
  state => state.businessUnits
);

export const getAllLocations = createSelector(
  getCompanyFeatureState,
  state => state.locations
);

export const getLocationsLoading = createSelector (
  getCompanyFeatureState,
  state => state.isLoadingBU || state.isLoadingLocations
);

export function companyReducer(state = initialState, action: CompanyActions): CompanyState {
  switch (action.type) {
    case CompanyActionTypes.LoadCompany:
      return {
        ...state,
        isLoading: true
      };
    case CompanyActionTypes.LoadCompanySuccess:
      return {
        ...state,
        isLoading: false,
        company: action.payload.company,
        error: null
      };
    case CompanyActionTypes.LoadCompanyFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CompanyActionTypes.LoadLocation:
      return {
        ...state,
        isLoading: true
      };
    case CompanyActionTypes.LoadLocationSuccess:
      return {
        ...state,
        isLoading: false,
        location: action.payload.location,
        error: null
      };
    case CompanyActionTypes.LoadLocationFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case CompanyActionTypes.LoadAllLocations:
      return {
        ...state,
        isLoading: true,
        isLoadingLocations: true
      };
    case CompanyActionTypes.LoadAllLocationsSuccess:
      return {
        ...state,
        isLoading: false,
        locations: [...action.payload.locations],
        error: null,
        isLoadingLocations: false
      };
    case CompanyActionTypes.LoadAllLocationsFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        isLoadingLocations: false
      };
    case CompanyActionTypes.LoadBusinessUnits:
      return {
        ...state,
        isLoading: true,
        isLoadingBU: true
      };
    case CompanyActionTypes.LoadBusinessUnitsSuccess:
      return {
        ...state,
        isLoading: false,
        businessUnits: [...action.payload.businessUnits],
        error: null,
        isLoadingBU: false,
      };
    case CompanyActionTypes.LoadBusinessUnitsFail:
      return {
        ...state,
        isLoading: false,
        isLoadingBU: false,
        error: action.payload
      };
    case CompanyActionTypes.UpdateBusinessUnits:
      return {
        ...state,
        isLoading: true
      };
    case CompanyActionTypes.UpdateBusinessUnitsSuccess:
      return {
        ...state,
        isLoading: false
      };
    case CompanyActionTypes.UpdateBusinessUnitsFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    default:
      return state;
  }
}
