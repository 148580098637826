
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as twoFAAction from '../actions/two-factor-auth.actions';
import { VerificationService } from '../../modules/shared/services/verification.service';

@Injectable()
export class TwoFAEffects {
  constructor(
    private actions$: Actions,
    private verificationService: VerificationService,
  ) { }

  
  loadEmailAndPhone$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(twoFAAction.TwoFactorAuthActionTypes.LoadAuthMethods),
      mergeMap(action =>
        this.verificationService
          .getTwoFactorAuthProvider(action['payload'])
          .pipe(
            map(data => {
              return (new twoFAAction.LoadAuthMethodsSuccess(data));
            }),
            catchError(err => of(new twoFAAction.LoadAuthMethodsFail(err)))
          )
      )
    ));

  
  sendVerificationCode$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType<Action>(twoFAAction.TwoFactorAuthActionTypes.SendVerificationCode),
      mergeMap(action => {
        const { method, id } = action['payload'];

        return this.verificationService
          .sendVerificationCode(method, id)
          .pipe(
            map(_ => (new twoFAAction.SendVerificationCodeSuccess())),
            catchError(err => of(new twoFAAction.SendVerificationCodeFail(err)))
          );
      })
    ));

  
  verifyCode$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(twoFAAction.TwoFactorAuthActionTypes.VerifyCode),
      mergeMap((action: twoFAAction.VerifyCode) => {
        const { id, token, method } = action.payload;
        return this.verificationService
          .verifyCode(id, token, method)
          .pipe(
            map(_ => (new twoFAAction.VerifyCodeSuccess())),
            catchError(err => of(new twoFAAction.VerifyCodeFail(err)))
          );
      })
    ));

  
  getSecurityQuestion$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(twoFAAction.TwoFactorAuthActionTypes.GetSecurityQuestion),
      mergeMap(action =>
        this.verificationService
          .getRandomSecurityQuestion(action['payload'])
          .pipe(
            map((question: { id: string, questionText: string }) => (new twoFAAction.GetSecurityQuestionSuccess(question))),
            catchError(err => of(new twoFAAction.GetSecurityQuestionFail(err)))
          )
      )
    ));

  
  verifyAnswer$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(twoFAAction.TwoFactorAuthActionTypes.VerifyAnswer),
      mergeMap((action: twoFAAction.VerifyAnswer) => {
        const { identityUserId, questionId, answer } = action.payload;
        return this.verificationService
          .verifyAnswer(identityUserId, questionId, answer)
          .pipe(
            map(res => (new twoFAAction.VerifyAnswerSuccess(res))),
            catchError(err => of(new twoFAAction.VerifyAnswerFail(err)))
          );
      })
    ));
}
