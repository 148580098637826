import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'z-forbidden',
  templateUrl: './forbidden.component.html',
  styles: [`
    .forbidden {
      height: 100vh;
      background-color: #F4F4F4;
    }
  `]
})
export class ForbiddenComponent {

  constructor(
    private location: Location
  ) { }

  goBack() {
    this.location.back();
  }

}
