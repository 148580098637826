import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { ConsentDefinition } from '../models/consent-definition.model';
import { Observable } from 'rxjs';
import { UserConsent } from '../../membership/modules/shipping/models/user-consent.model';
import { ConsentServiceType } from '../enums/consent-service-type';
import { UserConsentType } from '../enums/user-consent-type';

@Injectable({
  providedIn: 'root'
})
export class TermsAndConditionsService {

  userConsent = new UserConsent();

  private identityApiURL = environment.identityApiURL + '/api';

  constructor(private http: HttpClient) { }

  checkAcceptance(consent: ConsentServiceType): Observable<UserConsent> {
    const url = `${this.identityApiURL}/UserConsents/Active/${consent}`;
    return this.http.get<UserConsent>(url);
  }

  getActiveTermsAndConditionsAgreement(consent: ConsentServiceType): Observable<ConsentDefinition> {
    const url = `${this.identityApiURL}/ConsentDefinitions/active/${consent}`;
    return this.http.get<ConsentDefinition>(url);
  }

  manageTerms(consentDef: ConsentDefinition, consent: UserConsentType): Observable<any> {
    this.userConsent.consentId = consentDef.id;
    this.userConsent.userConsentType = consent;
    const url2 = `${this.identityApiURL}/UserConsents`;
    return this.http.post(url2, this.userConsent);
  }

  update(userConsent: UserConsent) {
    const url = `${this.identityApiURL}/UserConsents/${userConsent.id}`;
    return this.http.put(url, userConsent);
  }

}
