<div class="modal-header" [ngClass]="config.headerClass ?? 'mb-2'">
    <h4 class="modal-title roboto-condensed-bold font-24" [innerHTML]="config.headerText | translate"></h4>
    <i *ngIf="!config.hideCloseButton" class="mdi mdi-close font-20 cursor-pointer float-end" (click)="onCloseModal()"></i>
</div>

<div *ngIf="config.bodyText" [class]="config.bodyContainerClass">
    <i [ngClass]="config.bodyIcon"></i>
    <div [ngClass]="config.bodyTextClass ?? 'px-3 py-2 modal-text'" [innerHTML]="config.bodyText | translate : config.translationData"></div>
</div>

<form [formGroup]="formGroup">
    <fieldset class="col-lg-12 mt-1 form-group" *ngFor="let control of config.controls">
        <label class="color-dark-gray font-14 ml-025rem ">
            {{ control.formDescription | translate }}
        </label>
        <label *ngIf="!control.showLabelBelowInput" [for]="control.controlName"
            class="zing-ui theme-name-label mb-0"  [ngClass]="{ 'required-label': control.isRequired } ">
            {{ control.label | translate }}
        </label>
        <input *ngIf="control.type !== 'textarea'; else textArea" [type]="control.type" class="zing-ui form-control" [formControl]="control.formControl"
            placeholder="{{ control.placeholder | translate }}">
        <label *ngIf="control.showLabelBelowInput && !hasError(control.formControl)"
            [for]="control.controlName" class="zing-ui theme-name-label mb-0 mt-025rem ml-025rem">
            {{ control.label | translate }}
        </label>

        <ng-template #textArea>
            <textarea [formControl]="control.formControl" class="form-control"
                style="resize: none;"
                placeholder="{{ control.placeholder | translate }}"
                [rows]="control.rows ?? null" [maxlength]="control.maxLength ?? null" [cols]="control.columns ?? null">
            </textarea>
            <label [for]="control.controlName" class="zing-ui theme-name-label mb-0 mt-025rem float-end">
                {{ "Labels.Counter" | translate: { value: control.formControl.value.length, maxLength: control.maxLength } }}
            </label>
        </ng-template>

        <ng-container *ngIf="hasError(control.formControl)">
            <ng-container *ngFor="let error of control.errors">
                <ng-container *ngIf="hasErrorOfType(control.formControl, error.type)">
                    <small *ngIf="!error.hasErrorDetails" class="mt-025rem ml-025rem">
                        {{ error.message  | translate }}
                    </small>
                    <small *ngIf="error.hasErrorDetails" class="mt-025rem ml-025rem">
                        {{ error.message  | translate: { value: control.formControl.value } }}
                    </small>
                </ng-container>
            </ng-container>
        </ng-container>
    </fieldset>
</form>

<div class="float-end pr-2 mt-2" *ngIf="!config.hideButtons">
    <button class="btn w-auto mb-2 modal-btn" [ngClass]="!config.inverted ? config.continueButtonClass : config.cancelButtonClass"
      type="button" (click)="!config.inverted ? onAgree() : onCloseModal()" [disabled]="!config.inverted && formGroup.invalid">
      {{ (!config.inverted ? config.continueButtonText : config.cancelButtonText) | translate }}
    </button>
    <button class="btn w-auto ml-2 mb-2 modal-btn" [ngClass]="!config.inverted ? config.cancelButtonClass : config.continueButtonClass"
      type="button" (click)="!config.inverted ? onCloseModal() : onAgree()" [disabled]="config.inverted && formGroup.invalid">
      {{ (!config.inverted ? config.cancelButtonText : config.continueButtonText) | translate }}
    </button>
</div>

<z-spinner class="wholesale-spinner" [inProgress]="config.showSpinner$ | async"></z-spinner>
