import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';

export class NotificationModalConfig {
  headerText?: string;
  headerClass?: string;
  bodyText?: string;
  continueButtonText?: string;
  cancelButtonText?: string;
  bodyIcon?: string;
  bodyContainerClass?: string;
  bodyTextClass?: string;
  inverted?: boolean;
  hideButtons?: boolean;
  hideCloseButton?: boolean;
  continueButtonClass?: string;
  cancelButtonClass?: string;
  translationData?: object;
  showSpinner$?: Observable<boolean>;
}

export const defaultNotificationModalConfig = {
  headerText: '',
  bodyText: '',
  continueButtonText: '',
  cancelButtonText: '',
  inverted: false,
  hideButtons: false,
  hideCloseButton: false,
  continueButtonClass:  'btn-outline-info',
  cancelButtonClass: 'btn-info btn-rounded',
  translationData: {}
};

export class FormModalConfig extends NotificationModalConfig {
  controls: FormControlConfig[];
}

export class FormControlConfig {
  controlName: string;
  formControl: FormControl;
  placeholder: string;
  showLabelBelowInput?: boolean;
  formDescription?: string;
  label?: string;
  type: string;
  errors: { type: string; message: string; hasErrorDetails?: boolean }[];
  rows?: number;
  columns?: number;
  maxLength?: number;
  isRequired?: boolean;
}

export const defaultFormModalConfig = {
  ...defaultNotificationModalConfig,
  continueButtonClass:  'btn-info btn-rounded',
  cancelButtonClass: 'btn-outline-info',
  controls: []
};
