import { Component } from '@angular/core';
import { Location } from '@angular/common';

@Component({
  selector: 'z-page-not-found',
  templateUrl: './page-not-found.component.html',
  styles: [`
    .page-not-found {
      height: 100vh;
      background-color: #F4F4F4;
    }
  `]
})
export class PageNotFoundComponent {

  constructor(
    private location: Location
  ) { }

  goBack() {
    this.location.back();
  }

}
