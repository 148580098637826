export class Address {
  constructor(
    public addressLine1: string = null,
    public addressLine2?: string,
    public city: string = null,
    public stateId: string = null,
    public stateName?: string,
    public postCode: string = null,
    public countryId: string = null,
    public latitude?: number,
    public longitude?: number,
    public altitude?: number,
    public countryName?: string
  ) { }
}
