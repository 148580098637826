import { storeReducers, ZingState } from './store/root.state';
import { CallbackComponent } from './modules/core/callback.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutingModule } from './app-routing.module';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxStripeModule } from 'ngx-stripe';

import { NguCarouselModule } from '@ngu/carousel';
import { AppComponent } from './app.component';

import { GuestGuard } from './route-guards/guest-guard.service';
import { MemberGuard } from './route-guards/member-guard.service';
import { MemberCanGuard } from './route-guards/member-can-guard.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import {
  AuthModule,
} from 'angular-auth-oidc-client';

import { AuthService } from './modules/core/services/auth.service';
import { StoreModule, MetaReducer } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { NotificationService } from './modules/core/services/notification.service';
import { httpInterceptorProviders } from './interceptors';
import { getOidcConfig } from './config/oidc.config';
import { TwoFAEffects } from './store/effects/two-factor-auth.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StartingComponent } from './modules/core/starting.component';
import { AutoLoginComponent } from './modules/core/auto-login/auto-login.component';
import { CompanyService, ProfileService, CompanyDocumentsService } from './modules/shared/services';
import { RoleGuard } from './route-guards/role-guard.service';
import { SubscriptionGuard } from './route-guards/subscription-guard.service';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { AnalyticsService } from './modules/shared/services/analytics.service';
import { ForbiddenWordsService } from './modules/core/services/forbidden-words.service';
import { SharedModule } from './modules/shared/shared.module';
import { ReportingService } from './modules/core/services/reporting.service';
import { ConfigurationService } from './modules/core/services/configuration.service';
import { GoogleAnalyticsService } from './modules/shared/services/google-analytics.service';
import { ApplicationBrowserGuard } from './route-guards/application-browser-guard.service';
import { ShippingUserDataService } from './modules/membership/services/shipping-user-data.service';
import { AccountUserDataService } from './modules/membership/services/account-user-data.service';
import { MarketplaceEffects } from './modules/marketplace/store';
import { GTMService } from './modules/shared/services/gtm.service';
import { VideoModalComponent } from '../app/modules/guest/components/video-modal/video-modal.component';
import { FaqComponent } from './modules/membership/modules/shipping/components/faq/faq.component';
import { ShoppingCartEffects } from './modules/marketplace/shopping-cart/store';
import { ParticipantAgreementComponent } from './modules/core/participant-agreement/participant-agreement.component';
import { TermsOfUseComponent } from './modules/core/terms-of-use/terms-of-use.component';
import { FeatureFlagGuard } from './route-guards/feature-flag.guard';
import { provideNgxMask } from 'ngx-mask';
import { ParnerGatewayEnabledGuard } from './modules/membership/modules/dashboard/route-guards/partner-gateway-enabled.guard';
import { EmailService } from './modules/core/services/email.service';

/*
  ngrx-store-freeze is a meta-reducer that prevents state from being mutated

  - Recursively freezes the current state, the dispatched action payload if provided and the new state.
  - When mutation occurs, an exception will be thrown.
  - Should be used only in development to ensure that the state remains immutable.
*/
export const metaReducers: MetaReducer<ZingState>[] = false // TODO : Find better solution.
  ? []
  : [];

@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    StartingComponent,
    AutoLoginComponent,
    PageNotFoundComponent,
    ForbiddenComponent,
    TermsOfUseComponent,
    VideoModalComponent,
    FaqComponent,
    ParticipantAgreementComponent
  ],
  imports: [
    SharedModule,
    AuthModule.forRoot({
      config: getOidcConfig()
    }),
    StoreModule.forRoot(storeReducers, { metaReducers, runtimeChecks:
      { strictStateImmutability: false, strictActionImmutability: false } }),
    EffectsModule.forRoot([
      TwoFAEffects,
      MarketplaceEffects,
      ShoppingCartEffects
    ]),
    // Instrumentation must be imported after importing StoreModule (config is optional)
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    BrowserModule.withServerTransition({ appId: 'zing' }),
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    AppRoutingModule,
    NguCarouselModule,
    TranslateModule.forRoot(),
    NgxStripeModule.forRoot(environment.stripeApiKey),
    ServiceWorkerModule.register('/ngsw-worker.js', { enabled: false }) // TODO - Figure out caching issue and then enable
  ],
  providers: [
    httpInterceptorProviders,
    AuthService,
    NotificationService,
    EmailService,
    GuestGuard,
    MemberGuard,
    MemberCanGuard,
    RoleGuard,
    SubscriptionGuard,
    ApplicationBrowserGuard,
    ProfileService,
    ShippingUserDataService,
    AccountUserDataService,
    CompanyService,
    CompanyDocumentsService,
    AnalyticsService,
    GoogleAnalyticsService,
    ForbiddenWordsService,
    ReportingService,
    ConfigurationService,
    GTMService,
    FeatureFlagGuard,
    provideNgxMask(),
    ParnerGatewayEnabledGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {

  }
}
