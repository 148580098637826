import { HttpClient } from '@angular/common/http';

import { Observable, BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';

export abstract class UserDataService<TUserData extends { id?: string }> {

  protected abstract apiUrl: string;

  protected userDataSubject$ = new BehaviorSubject<TUserData>(null);
  userData$ = this.userDataSubject$.asObservable();

  get userData() {
    return this.userDataSubject$.value;
  }

  constructor(protected http: HttpClient) { }

  /**
   * Load current user data.
   */
  loadUserData(): Observable<TUserData> {
    return this.http.get<TUserData>(`${this.apiUrl}/api/usersettings`)
      .pipe(
        tap(data => this.userDataSubject$.next(data))
      );
  }

  /**
   * Load user data of specific person.
   * @param personId Id of person
   */
  getUserData(personId: string): Observable<TUserData> {
    return this.http.get<TUserData>(`${this.apiUrl}/api/usersettings`,
      { params: { personId } }
    );
  }

  /**
   * Save user data for specific user (not logged in user)
   * @param personId Id of person
   */
  saveUserData(userData: TUserData): Observable<any> {
    const url = `${this.apiUrl}/api/usersettings`;
    return this.http.post(url, userData);
  }

  /**
   * Update user data for specific user (not logged in user)
   * @param personId Id of person
   */
  updateUserData(userData: TUserData): Observable<any> {
    const url = `${this.apiUrl}/api/usersettings/${userData.id}`;
    return this.http.put(url, userData);
  }

}
