<div id="shopping-cart-modal" class="zing-ui shopping-cart-modal-wrapper">
  <div class="modal-header header-bottom-border">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-hidden="true" (click)="bsModalRef.hide()">&times;</button>
  </div>
  <div class="modal-body py-0">
    <h5 class="my-0">{{content}}</h5>
  </div>
  <div class="modal-footer mb-2 pt-0">
    <button *ngIf="showContinueShoppingButton" type="button" id="continue-shopping-button" class="btn btn-outline-info btn-rounded w-auto mt-0" (click)="onContinueShopping()">
      {{'Labels.ShoppingCart.ContinueShopping' | translate}}
    </button>
    <button type="button" class="btn btn-info btn-rounded w-auto mt-0" (click)="onCheckOut()">      
      {{ (showContinueShoppingButton ? 'Labels.ShoppingCart.GoToCart' : 'Labels.ShoppingCart.ManageCart') | translate }} 
    </button>
  </div>
</div>
