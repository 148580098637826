import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';

import { filter, map, tap, take } from 'rxjs/operators';

import { ProfileService } from '../modules/shared/services';
import { BusinessServiceType } from '../modules/shared/enums/business-service-type';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(
    private profileService: ProfileService,
    private router: Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const requiredPermission = route.data.requiredPermission as BusinessServiceType;

    return this.profileService.personLoadedObs$.pipe(
      filter(loaded => loaded),
      map(() => this.profileService.hasPermission(requiredPermission)),
      tap(hasPermission => {
        if (!hasPermission) {
          this.router.navigateByUrl('/forbidden');
        }
      }),
      take(1)
    );
  }

}
