import { Action, createAction, props } from '@ngrx/store';

import {
  SearchFilterOptions,
  SearchParameters
} from '../../shared/models';
import { DiamondSearchResults } from '../../models';
import { State } from '@progress/kendo-data-query';
import { ShoppingCartDetail } from '../../shopping-cart/models';
import { SearchPreset } from '../../models/search-preset';
import { NaturalCartItem } from '../../models/natural-cart';

export enum MarketplaceActionTypes {
  SetDefaultSearchParameters = '[Marketplace] Set Default Search Parameters',
  GetSearchFilterOptions = '[Marketplace] Get Search Filter Options',
  GetSearchFilterOptionsSuccess = '[Marketplace] Get Search Filter Options Success',
  GetSearchFilterOptionsFail = '[Marketplace] Get Search Filter Options Fail',

  GetDiamondSearchResult = '[Marketplace] GetDiamondSearch Action',
  GetDiamondSearchResultSuccess = '[Marketplace] GetDiamondSearch Success',
  GetDiamondSearchResultFail = '[Marketplace] GetDiamondSearch Fail',

  ChangeResultOptions = '[Marketplace] Change Result Options',
  GetDefaultSearchResults = '[Marketplace] Get Default Search Results',
  ClearSearchResults = '[Marketplace] Clear Search Results',
  ClearSearchOptions = '[Marketplace] Clear Search Options',

  AddDiamondIdToCompare = '[Marketplace] Add Diamond Id To Compare',
  RemoveDiamondIdToCompare = '[Marketplace] Remove Diamond Id To Compare',
  FetchSearchPresets = '[Marketplace] Fetch Search Presets',
  FetchSearchPresetsSuccess = '[Marketplace] Fetch Search Presets Success',
  FetchSearchPresetsFail = '[Marketplace] Fetch Search Presets Fail',
  AddSearchPreset = '[Marketplace] Update Search Preset',
  AddSearchPresetSuccess = '[Marketplace] Update Search Preset Success',
  AddSearchPresetFail = '[Marketplace] Update Search Preset Fail',
  DeleteSearchPreset = '[Marketplace] Delete Search Preset',
  DeleteSearchPresetSuccess = '[Marketplace] Delete Search Preset Success',
  DeleteSearchPresetFail = '[Marketplace] Delete Search Preset Fail'
}

export class SetDefaultSearchParameters implements Action {
  readonly type = MarketplaceActionTypes.SetDefaultSearchParameters;
  constructor(public payload: SearchParameters) { }
}

export class FetchSearchFilterOptions implements Action {
  readonly type = MarketplaceActionTypes.GetSearchFilterOptions;
}

export class FetchSearchFilterOptionsSuccess implements Action {
  readonly type = MarketplaceActionTypes.GetSearchFilterOptionsSuccess;
  constructor(public payload: SearchFilterOptions) { }
}

export class FetchSearchFilterOptionsFail implements Action {
  readonly type = MarketplaceActionTypes.GetSearchFilterOptionsFail;
  constructor(public payload: any) { }
}

export class GetDiamondSearchResult implements Action {
  readonly type = MarketplaceActionTypes.GetDiamondSearchResult;
  constructor(public payload: SearchParameters) { }
}

export class GetDiamondSearchResultSuccess implements Action {
  readonly type = MarketplaceActionTypes.GetDiamondSearchResultSuccess;
  constructor(public payload: DiamondSearchResults) { }
}

export class GetDiamondSearchResultFail implements Action {
  readonly type = MarketplaceActionTypes.GetDiamondSearchResultFail;
  constructor(public payload: any) { }
}

export class ChangeResultOptions implements Action {
  readonly type = MarketplaceActionTypes.ChangeResultOptions;
  constructor(public payload: State) { }
}

export class GetDefaultSearchResults implements Action {
  readonly type = MarketplaceActionTypes.GetDefaultSearchResults;
}

export class ClearSearchResults implements Action {
  readonly type = MarketplaceActionTypes.ClearSearchResults;
}

export class ClearSearchOptions implements Action {
  readonly type = MarketplaceActionTypes.ClearSearchOptions;
  constructor() { }
}

export class AddDiamondIdToCompare implements Action {
  readonly type = MarketplaceActionTypes.AddDiamondIdToCompare;
  constructor(public payload: number) { }
}

export class RemoveDiamondIdToCompare implements Action {
  readonly type = MarketplaceActionTypes.RemoveDiamondIdToCompare;
  constructor(public payload: number) { }
}

export class FetchSearchPresets implements Action {
  readonly type = MarketplaceActionTypes.FetchSearchPresets;
  constructor() { }
}

export class FetchSearchPresetsSuccess implements Action {
  readonly type = MarketplaceActionTypes.FetchSearchPresetsSuccess;
  constructor(public payload: SearchPreset[]) { }
}

export class FetchSearchPresetsFail implements Action {
  readonly type = MarketplaceActionTypes.FetchSearchPresetsFail;
  constructor(public payload: any) { }
}

export class AddSearchPreset implements Action {
  readonly type = MarketplaceActionTypes.AddSearchPreset;
  constructor(public payload: SearchPreset) { }
}

export class AddSearchPresetSuccess implements Action {
  readonly type = MarketplaceActionTypes.AddSearchPresetSuccess;
  constructor(public payload: SearchPreset) { }
}

export class AddSearchPresetFail implements Action {
  readonly type = MarketplaceActionTypes.AddSearchPresetFail;
  constructor(public payload: any) { }
}

export class DeleteSearchPreset implements Action {
  readonly type = MarketplaceActionTypes.DeleteSearchPreset;
  constructor(public id: string) { }
}

export class DeleteSearchPresetSuccess implements Action {
  readonly type = MarketplaceActionTypes.DeleteSearchPresetSuccess;
  constructor(public id: string) { }
}

export class DeleteSearchPresetFail implements Action {
  readonly type = MarketplaceActionTypes.DeleteSearchPresetFail;
  constructor(public payload: any) { }
}


export const searchSimilar = createAction('[Marketplace] Search Similar', props<{ cartItem: NaturalCartItem }>());

export type MarketplaceActions =
  FetchSearchFilterOptions
  | FetchSearchPresets
  | FetchSearchPresetsSuccess
  | FetchSearchPresetsFail
  | AddSearchPreset
  | AddSearchPresetSuccess
  | AddSearchPresetFail
  | DeleteSearchPreset
  | DeleteSearchPresetSuccess
  | DeleteSearchPresetFail
  | FetchSearchFilterOptionsSuccess
  | FetchSearchFilterOptionsFail
  | GetDiamondSearchResult
  | GetDiamondSearchResultSuccess
  | GetDiamondSearchResultFail
  | ChangeResultOptions
  | GetDefaultSearchResults
  | ClearSearchResults
  | ClearSearchOptions
  | AddDiamondIdToCompare
  | RemoveDiamondIdToCompare
  | SetDefaultSearchParameters;
