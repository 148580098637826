export enum CreditCardType {
    Visa = 'Visa',
    MasterCard = 'MasterCard',
    Discover = 'Discover',
    AmericanExpress = 'AmericanExpress',
    DinersClub = 'DinersClub',
    JCB = 'JCB',
    Unknown = 'Unknown'
}

export class CreditCardTypeUtil {
    public static toNumber(mode: CreditCardType): number {
        let number;
        switch (mode) {
            case CreditCardType.Visa:
                number = 0;
                break;
            case CreditCardType.MasterCard:
                number = 1;
                break;
            case CreditCardType.Discover:
                number = 2;
                break;
            case CreditCardType.AmericanExpress:
                number = 3;
                break;
            case CreditCardType.DinersClub:
                number = 4;
                break;
            case CreditCardType.JCB:
                number = 5;
                break;
            default:
                number = -1;
                break;
        }
        return number;
    }

    public static parse(mode: number): CreditCardType {
        let card;
        switch (mode) {
            case 0:
                card = CreditCardType.Visa;
                break;
            case 1:
                card = CreditCardType.MasterCard;
                break;
            case 2:
                card = CreditCardType.Discover;
                break;
            case 3:
                card = CreditCardType.AmericanExpress;
                break;
            case 4:
                card = CreditCardType.DinersClub;
                break;
            case 5:
                card = CreditCardType.JCB;
                break;
            default:
                card = CreditCardType.Unknown;
                break;
        }
        return card;
    }
}
