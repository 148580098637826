import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthorizationInterceptor } from './authorization.interceptor';
import { ContentTypeJsonInterceptor } from './content-type.interceptor';
import { FieldEncodeInterceptor } from './field-encode.interceptor';
import { SubscriptionKeyInterceptor } from './subscription-key.interceptor';
import { TokenInterceptor } from './token.interceptor';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: ContentTypeJsonInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: SubscriptionKeyInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: FieldEncodeInterceptor, multi: true },
  { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true }
  // TODO: Enable ForbiddenInterceptor after related issues found during regression testing are fixed(#286707)
  // { provide: HTTP_INTERCEPTORS, useClass: ForbiddenInterceptor, multi: true }
];
