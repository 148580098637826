<div class="row shopping-cart-items-table d-flex flex-wrap mt-4">
  <div class="table-wrapper col-md-12 d-flex">
    <div class="column d-flex">
      <div class="titles-row">
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.Measurements' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.Depth' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.Table' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.CrownHeight' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.CrownAngle' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.FluorescenceIntesity' | translate }}</p>
      </div>
      <div class="values-row">
        <p class="font-14 text-muted py-1 mb-0" *ngIf="diamondDetails?.measurements; else placeholder">{{diamondDetails?.measurements + ' mm'}}</p>
        <p class="font-14 text-muted py-1 mb-0" *ngIf="diamondDetails?.totalDepth; else placeholder">{{diamondDetails?.totalDepth + '%'}}</p>
        <p class="font-14 text-muted py-1 mb-0" *ngIf="diamondDetails?.tableWidth; else placeholder">{{diamondDetails?.tableWidth + '%'}}</p>
        <p class="font-14 text-muted py-1 mb-0" *ngIf="diamondDetails?.crownHeight; else placeholder">{{diamondDetails?.crownHeight + '%'}}</p>
        <p class="font-14 text-muted py-1 mb-0" *ngIf="diamondDetails?.crownAngle; else placeholder">{{diamondDetails?.crownAngle + '%'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.fluorescenceIntensity || '-'}}</p>
      </div>
    </div>
    <div class="column ml-5 d-flex">
      <div class="titles-row">
        <p class="font-14 font-weight-bold py-1 mb-0 first">{{ 'Labels.DiamondDetails.Fluorescence' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.PavilionDepth' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.PavilionAngle' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.CuletSize' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.CuletCondition' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.Girdle' | translate }}</p>
      </div>
      <div class="values-row">
        <p class="font-14 text-muted py-1 mb-0 first">{{diamondDetails?.fluorescenceColor || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.pavilionDepth || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.pavilionAngle || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.culetSize || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.culetCondition || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.girdle || '-'}}</p>
      </div>
    </div>
    <div class="column ml-5 d-flex">
      <div class="titles-row-last">
        <p class="font-14 font-weight-bold py-1 mb-0 first">{{ 'Labels.DiamondDetails.Shade' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.Milky' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.BlackInclusion' | translate }}</p>
        <p class="font-14 font-weight-bold py-1 mb-0">{{ 'Labels.DiamondDetails.EyeClean' | translate }}</p>        
        <p class="font-14 font-weight-bold py-1 mb-0 empty"></p>
      </div>
      <div class="values-row-last">
        <p class="font-14 text-muted py-1 mb-0 first">{{diamondDetails?.shade || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.milky || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.blackInclusion  || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0">{{diamondDetails?.eyeClean || '-'}}</p>
        <p class="font-14 text-muted py-1 mb-0 empty"></p>
      </div>
    </div>    
  </div>

  <ng-template #placeholder>
    <p class="font-13 text-muted py-1 mb-0">-</p>
  </ng-template>
</div>