export enum SavedShipmentState {
  /// <summary>
  /// Insurance Only
  /// </summary>
  InsuranceOnly = 9,

  /// <summary>
  /// Insurance Only Review
  /// </summary>
  InsuranceOnlyReview = 10,

  /// <summary>
  /// Quote
  /// </summary>
  Quote = 11,

  /// <summary>
  /// New Addresses Page
  /// </summary>
  AddressesPage = 12
}
