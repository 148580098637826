import { Component, OnInit } from '@angular/core';
import { OidcSecurityService, PublicEventsService, EventTypes } from 'angular-auth-oidc-client';
import { ActivatedRoute, Router } from '@angular/router';
import { Encoder } from '../../utils/encoder';
import { filter, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'z-callback',
  template: `
    <div class="starting d-flex flex-column justify-content-center align-items-center">
      <div class="m-3em d-flex align-items-center">
        <img src="/assets/icons/icon-logo-white.svg" height="120" alt="zing logo" />
      </div>
    </div>
  `,
  styles: [`
    @keyframes fadeInAndOut {
      0% {
        opacity: 0.05;
        transform: scale(1);
      }
      50% {
        opacity: 1;
        transform: scale(1.02);
      }
      100% {
        opacity: 0.05;
        transform: scale(1);
      }
    }

    .starting img {
      animation: fadeInAndOut 1.5s infinite;
    }
  `]
})
export class CallbackComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private oidcSecurityService: OidcSecurityService,
    private router: Router,
    private eventService: PublicEventsService
  ) { }

  ngOnInit() {
    this.eventService.registerForEvents()
      .pipe(filter(notification => notification.type === EventTypes.NewAuthenticationResult))
      .subscribe(
        authorizationResult => {
          if (authorizationResult.value.isAuthenticated) {
            const redirectTo = sessionStorage.getItem('redirectTo');
            if (redirectTo) {
              location.href = environment.baseURL +redirectTo;
              sessionStorage.setItem('redirectTo', '');
              localStorage.setItem('savedState', '');
            }
          }
        });

    this.route.queryParams
      .subscribe(params => {
        const currentState = params['state'];
        const savedState = localStorage.getItem('savedState');
        // states don't match reset redirection url
        if (Encoder.decode(savedState) !== Encoder.decode(currentState)) {
          localStorage.setItem('redirectTo', '');
        }
        // if just the states don't match redirect to application
        // TODO: refactor this and handle it better, this is a Qick Fix
        this.oidcSecurityService.getState()
          .pipe(take(1), filter(res => res !== currentState))
          .subscribe(() => this.router.navigate(['/login']));
        this.oidcSecurityService.checkAuth(window.location.toString()).pipe(take(1)).subscribe();
      });
  }
}
