import { NewTransactionDetails } from '../../order/models';
import { TransactionDetails } from '../models';
import { NaturalShoppingCart } from '../models/natural-cart';
import { getCartInformation } from '../shared/utils/cart-helpers';

export function getShoppingCartTransactionDetails(cart: NaturalShoppingCart, discountAmount = 0): TransactionDetails {

  const cartInformation = getCartInformation(cart);

  return {
    priceTotal: cartInformation.totalProductPriceAmount,
    totalTransactionCost: cartInformation.totalTransactionCostAmount - discountAmount,
    totalTaxAmount: cartInformation.totalTaxAmount,
    authenticationAndShipping: cartInformation.totalAuthenticationAndShippingAmount,
    servicingFee: cartInformation.totalServicingFeeAmount - discountAmount
  };
}

export function getOrderTransactionDetails(transactionDetails: NewTransactionDetails): TransactionDetails {
  return {
    priceTotal: transactionDetails.totalPriceTotal,
    totalTransactionCost: transactionDetails.totalTransactionCost,
    totalTaxAmount: transactionDetails.totalTaxAmount,
    authenticationAndShipping: transactionDetails.totalAuthenticationAndShippingAmount,
    servicingFee: transactionDetails.totalServicingFeeAmount
  };
}
