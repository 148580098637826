import { Component, OnInit } from '@angular/core';

import { DomSanitizer } from '@angular/platform-browser';
import { ConsentDefinition } from '../../shared/models/consent-definition.model';

@Component({
  selector: 'z-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.scss']
})
export class TermsOfUseComponent implements OnInit {

  readonly documentUrl =  '../../../../../assets/Zing API Terms of Use - 08.30.2022.pdf';
  sanitizedUrl: any;

  constructor(
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit() {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.documentUrl);
  }
}
