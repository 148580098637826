import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ProfileService } from '../modules/shared/services/profile.service';
import { UserRoleCode } from '../modules/membership/modules/account/models/user-role-code.enum';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {

  constructor(
    public router: Router,
    private profileService: ProfileService
  ) { }

  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {

    const roles = route.data['roles'] as Array<UserRoleCode>;

    if (this.profileService.person === null) {
      try {
        this.profileService.person = await this.profileService.get().toPromise();
        this.profileService.notifyPersonLoaded();
      } catch (error) {
        console.log(error);
      }
    }

    const isPermitted = this.profileService.isPersonPermittedAccess(roles);

    if (!isPermitted) {
      this.router.navigateByUrl('/dashboard');
      return false;
    }

    return true;
  }
}
