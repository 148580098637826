<div class="container-fluid">
    <div class="row text-center mt-4">
        <div class="col-12 heading-30-bold-zing-blue">
            Participant Agreement
        </div>
    </div>
    <div class="row d-flex justify-content-center">
        <div class="col-log-10 main-section w-75">
            <div class="form-container">
                <div class="row participant-agreement-panel">
                    <div class="col-12">
                        <div class="participant-agreement-body">
                            <iframe id="pdfWrapper" [src]="sanitizedUrl"  frameborder="0" type="application/pdf" width="100%" height="100%"></iframe>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <a [href]="sanitizedUrl" class="btn btn-md btn-zing-blue w-100" download>Download</a>
                </div>
            </div>
        </div>
    </div>
</div>