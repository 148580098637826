import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { AutoLogoutService } from '../modules/shared/services/auto-logout.service';

@Injectable({
  providedIn: 'root'
})
export class MemberGuard implements CanActivate {

  constructor(
    public router: Router,
    private oidcSecurityService: OidcSecurityService,
    private autoLogoutService: AutoLogoutService
  ) { }

  canActivate(): Observable<boolean> | boolean {
    return this.oidcSecurityService.checkAuth()
      .pipe(
        take(1),
        map((response: LoginResponse) => {
          if (response.isAuthenticated) {
            this.autoLogoutService.setIdleConfig();
            this.autoLogoutService.setUserLoggedIn(true);
            return true;
          }
          // url doesn't have a hash part
          if (!window.location.hash) {
            this.router.navigate(['/login']);
          }
          return false;
        })
      );
  }
}

