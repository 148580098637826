import { ServiceType } from '../enums/service-type';
import { ShippingColumnName } from '../enums/shipping-column-type';
import { Tracking, Weight, VoidDetail, ClaimDetail, ShipmentLocation, ShipmentPurchaseState, ThirdPartyService } from './base-shipment-models';
import { SortingType } from '../../shared/enums/sortingType';
import { DeliveryConfirmationType } from '../enums/delivery-confirmation-type';
import { NameValuePair } from './name-value-pair.model';
import { PackageDetailType } from '../enums/package-detail-type';
import { AddressDisplayMode } from '../components/shipment-address-selection/shipment-address-selection.component';
import { FilterModalType } from '../enums/filter-modal.enum';
import { ShipmentPriceType } from '../enums/shipment-price-type.enum';
import { StoreType } from '../../account/models/store-type.model';

export enum ShipmentStatus {
  Saved = 0,
  Created = 1,
  Parked = 2,
  ApprovalRequired = 3,
  Approved = 4,
  AwaitingPayment = 5,
  Processed = 6,
  Rejected = 7,
  Voided = 8,
  Boomerang = 9,
  InTransit = 10,
  Exception = 11,
  Delivered = 12,
  Complete = 13,
  EditRequired = 14,
  ReadyForPurchase = 15,
  NeedsReview = 16
}
export enum CarrierType {
  None = 0,
  DHL = 1,
  EasternConnection = 2,
  FedEx = 3,
  Lonestar = 4,
  Newgistics = 5,
  Ontrac = 6,
  UPS = 7,
  DHLGM = 8,
  USPS = 9,
  RequiresInput = 99
}

export enum ShipmentLocationType {
  Origin = 'Origin',
  Destination = 'Destination'
}

interface Address {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  stateId: string;
  postCode: string;
  countryId: string;
  latitude?: number;
  longitude?: number;
  altitude?: number;
}

export enum AddressSource {
  API,
  AddressBook,
  DefaultAddress,
  Company,
  Custom
}
export interface ShipmentReferences {
  shipmentName: string;
  shipperReference: string;
  receiverReference: string;
  totalPrice: number;
}

export interface ShipmentInsurance {
  value: number;
  notes: string;
  account: string;
}

export class Quote {
  id?: string;
  carrier: string;
  service: ServiceType;
  carrierImg: string;
  text: string;
  price: number;
  shipmentPrices?: ShipmentPrice[];
  priceTypePrefferred?: ShipmentPriceType;
  requiresApproval: boolean;
  expectedDeliveryDate: Date;
  requiresSeal: boolean;
  softError?: string;
  hardError?: string;
  valueReducedByBlock?: number;
  valueOfBlockUsed?: number;
  packageDetail?: PackageDetailType;
  packageImage?: string;
  packageName?: string;
  deliveryConfirmation?: DeliveryConfirmationType;
  isSaturdayDelivery?: boolean;
  insuranceOnly?: boolean;
  carrierServiceDescription?: string;
  deliveryConfirmationName?: string;
  warningMessage?: string;
  cargo?: string;
  totalAdminFees?: number;
  adminFeesVerbiage?: string;

  get unavailable() {
    return this.softError || this.hardError || !this.price;
  }
}

export interface QuoteOptions {
  clientId?: number;
  originPostalCode: string;
  destinationPostalCode: string;
  origin?: ShipmentLocation;
  destination?: ShipmentLocation;
  isDestinationResidential?: boolean;
  packageDimX?: number;
  packageDimY?: number;
  packageDimZ?: number;
  value: number;
  weights: Array<Weight>;
  cargo: number;
  shippingDate?: Date;
  magicSeal?: boolean;
  ignoreBlock?: boolean;
  registeredMail?: boolean;
  carriers: Array<number>;
  services: Array<ServiceType>;
  packageDetails?: Array<number>;
  signatures: Array<number>;
  invoiceValue?: number;
  insuranceOnly?: boolean;
  insuranceOnlyTrackingNumber?: string;
  approvalCode?: string;
  shipmentId?: string;
  codSelected?: boolean;
  thirdPartySelected?: boolean;
  thirdPartyService?: ThirdPartyService;
  isFedexOneRate?: boolean;
  isHoldForPickup?: boolean;
  hfpuLocationID?: string;
}

export interface SignatureOption extends NameValuePair {
  displayText: string;
  carrier?: string;
}

export interface ShippingColumn {
  columnName: ShippingColumnName;
  sortingType?: SortingType;
  columnTitle?: string;
  orderNo?: number;
  columnData?: string;
  isVisible?: boolean;
  isMandatory?: boolean;
  filterable: boolean;
  isLocked?: boolean;
  sortable?: boolean;
  filterModalType?: FilterModalType;
}

export interface ShippingUserData {
  columns?: ShippingColumn[];
  savedShipmentsColumns?: ShippingColumn[];
  action?: RestrictionAction;
  userId?: string;
  personId?: string;
  id?: string;
  clientSetting?: ClientSetting;
  accountLinkingStatus?: LinkingStatus;
  accountData?: AccountData;
  skipBrinksIntroScreen?: boolean;
  destinationDisplayMode?: AddressDisplayMode;
  ecommerceIntegrations?: EcommerceIntegration[];
}

export interface AccountData {
  username: string;
  password: string;
}

export interface ClientSetting {
  clientId: number;
  isApproved: true;
  isActive: true;
}

export interface CreateShipment {
  allowed?: boolean;
  domesticShipmentsOnly?: boolean;
  allowShipmentsBelowValue?: boolean;
  allowShipmentsBelowCost?: boolean;
  shipmentsBelowValue?: number;
  shipmentsBelowCost?: number;
}

export interface RestrictionAction {
  manageShipments: PermissionLevel;
  createShipments: CreateShipment;
  isShippingAllowed: boolean;
}

export enum PermissionLevel {
  CreatedByUser = 0,
  CreatedByEntireOrganization = 1
}
export interface PermissionLevelDropDownOption {
  name: string;
  value: number;
}

export interface ShipmentSummaryRecord {
  id?: string;
  zingTrackingNumber: string;
  shipTo: string;
  destination: string;
  carrierTrackingId: string;
  carrierName: string;
  service: string;
  status: string;
  shipmentStatus?: ShipmentStatus;
  filterStatus: string;
  clientID: number;
  //accountName: string;
  signature: string;
  packaging: string;
  name: string;
  weight: string;
  shipmentCost: number;
  sendersReference: string;
  shipFrom: string;
  shipFromContact: string;
  shipFromAddress: string;
  shipFromZipCode: string;
  shipFromCountry: string;
  receiversReference: string;
  residential: boolean;
  shipToContact: string;
  shipToAddress: string;
  shipToZipCode: string;
  shipToCountry: string;
  createdOn: string;
  tracking?: Tracking;
  voidDetail?: VoidDetail;
  claimDetail?: ClaimDetail;
  shipmentCreated: string;
  declaredValue?: number;
  insuredValue?: number;
  voided: boolean;
  eCommOrders: string;
  insuranceOnly: boolean;
  isInternational: boolean;
  isScanBasedBilling: boolean;
  shipFromCity: string;
  shipFromState: string;
  shipToCity: string;
  shipToState: string;
  carrierShipDate: string;
  carrierDeliveryDate: string;
  voidedOn: string;
  needsReviewError?: string;
  isDeclined?: boolean;
  claimReportingPeriod?: string;
}

export interface ShipmentReconciliationRecord {
  shipmentId: string;
  shipmentGid: string;
  trackingNumber: string;
  reconAmount: number;
  shipmentDate: Date;
  reconDate: Date;
  from: string;
  to: string;
  carrier: string;
  service: string;
}

export interface ShipmentPurchaseRecord {
  shipmentId?: string;
  shipmentCost?: number;
  carrierTrackingId?: string;
  senderReference?: string;
  shipTo?: string;
  purchaseState?: ShipmentPurchaseState;
  purchaseError?: string;
}

export interface ShipmentPrice {
  price: number;
  shipmentPriceType: ShipmentPriceType;
}

export enum LinkingStatus {
  NotLinked = 0,
  Skipped = 1,
  InProgress = 2,
  Completed = 3,
  Failed = 4
}

export enum ShipmentType {
  Domestic = 'domestic',
  International = 'international',
  InsuranceOnly = 'insurance',
  None = 'none'
}

export enum AddressValidationStatus {
  None = 0,
  Validated = 1,
  Overridden = 2
}

export interface EcommerceIntegration {
  storeType: StoreType;
  storeId: string;
  termsAccepted: boolean;
  termsAcceptanceDate: Date;
}
