import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { ShoppingCart, CartCheckout } from '../models';
import { ShippingMethodType } from '../../../shopping-cart/models/shipping-method-type';

@Injectable({
  providedIn: 'root'
})
export class DiamondMarketplaceCartService {

  private readonly apiUrl = environment.marketplaceApiURL;

  constructor(private http: HttpClient) { }

  getCart(locationId?: string, shippingMethod?: ShippingMethodType) {
    let params = new HttpParams();

    if (locationId) {
      params = params.append('locationId', locationId);
    }

    if (shippingMethod) {
      params = params.set('shippingMethodType', shippingMethod.toString());
    }

    return this.http.get<ShoppingCart>(`${this.apiUrl}/api/cart/marketplace`, { params });
  }

  updateCart(supplierItemIdentifier: string) {
    return this.http.post(`${this.apiUrl}/api/cart/marketplace/items`, { supplierItemIdentifier });
  }

  removeCartItem(id: string) {
    return this.http.delete(`${this.apiUrl}/api/cart/marketplace/items/${id}`);
  }

  checkout(cartCheckout: CartCheckout) {
    return this.http.post(`${this.apiUrl}/api/cart/marketplace/checkout`, cartCheckout);
  }

}
