import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { ModuleType } from '../../shopping-cart/models/module-type';
import { ShoppingCartValidateRequest } from '../../shopping-cart/models/validate-request';
import { NaturalShoppingCart } from '../models/natural-cart';
import { RetailCartCheckout } from '../models/retail-cart-checkout';

@Injectable({
  providedIn: 'root'
})
export class DiamondCartService {
  baseUrl = environment.shoppingCartApiUrl;

  constructor(private http: HttpClient) { }

  getCart() {
    return this.http.get<NaturalShoppingCart>(`${this.baseUrl}/api/cart?moduleType=${ModuleType.NaturalDiamonds}`);
  }

  addItem(supplierItemId: string) {
    return this.http.post(this.baseUrl + '/api/cart/retail/items', {
      supplierItemId,
      moduleType: ModuleType.NaturalDiamonds
    });
  }

  removeItem(cartId: string, itemId: string) {
    return this.http.delete(this.baseUrl + `/api/cart/${cartId}/items/${itemId}`);
  }

  checkout(checkout: RetailCartCheckout) {
    return this.http.post(this.baseUrl + `/api/cart/retail/items/checkout`, checkout);
  }

  validate(validate: ShoppingCartValidateRequest) {
    return this.http.post(this.baseUrl + `/api/cart/validate`, validate);
  }
}
