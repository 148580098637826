import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import {
  Orders,
  OrderDetails
} from '../models';
import { ODataModel } from '../../../utils/odata';
import { PaginatedResponse } from '../../shared/models/paginated-response.model';
import { UpdateProductStatus } from '../models/update-product-status.model';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  protected readonly apiUrl = `${environment.marketplaceApiURL}/api/marketplace`;
  private readonly ordersUrl = `${environment.marketplaceApiURL}/api/orders`;

  constructor(private http: HttpClient) { }

  searchOrders(odata: ODataModel) {
    return this.http.post<PaginatedResponse<Orders>>(`${this.apiUrl}/orderdetails/search`, odata)
      .pipe(
        map(ordersResponse => ({
          ...ordersResponse,
          items: ordersResponse.items.map(i => ({
            ...i,
            orderDate: new Date(i.orderDate)
          }))
        }))
      );
  }

  getOrderDetails(orderId: string): Observable<OrderDetails> {
    return this.http.get<OrderDetails>(`${this.apiUrl}/orders/${orderId}`)
      .pipe(
        map(orderDetails => ({
          ...orderDetails,
          customerOrderStatus: orderDetails.customerOrderStatus.map(os => ({
            ...os,
            orderStatusDate: new Date(os.orderStatusDate + 'Z')
          })),
        }))
      );
  }

  updateSpecialInstructions(orderId: string, specialInstructionText: string) {
    return this.http.post(`${this.ordersUrl}/${orderId}/notes`, { specialInstructionText });
  }

  setProductStatuses(orderId: string, productStatuses: UpdateProductStatus) {
    return this.http.post(`${this.apiUrl}/orders/${orderId}/products/statuses`, productStatuses);
  }
}
