import { Observable } from 'rxjs';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Injectable } from '@angular/core';
import { Router, CanLoad, Route } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Encoder } from '../utils/encoder';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MemberCanGuard implements CanLoad {

  constructor(
    public router: Router,
    private oidcSecurityService: OidcSecurityService,
  ) { }
  canLoad(): Observable<boolean> | boolean {

    return this.oidcSecurityService.checkAuth()
      .pipe(
        map((response: LoginResponse) => {
          if (response.isAuthenticated) {
            this.clearStorage();
            return true;
          }
          // if user is not authenticated and url doesn't have a hash part save
          // the url as a state to return to after login
          if (window.location.origin.includes(environment.baseURL) || !window.location.hash) {

            this.oidcSecurityService.setState(Encoder.encode(location.href)).subscribe();
            let path = location.href;
            if (location.href.includes('jewelersmutual.com/')) {
              path = location.href.split('jewelersmutual.com')[1];
            } else {
              path = location.href.split('localhost:4200')[1];
            }
            sessionStorage.setItem('redirectTo', path);
            localStorage.setItem('savedState', Encoder.encode(location.href));
          }
          this.router.navigate(['/login']);
          return false;
        }),
        take(1)
      );
  }

  clearStorage() {
    sessionStorage.setItem('redirectTo', '');
    sessionStorage.setItem('savedState', '');
    localStorage.removeItem('reTryAttempt');
  }
}
