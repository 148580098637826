import { Component, OnInit, OnDestroy } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'z-auto-login',
  template: `
    <div class="starting d-flex flex-column justify-content-center align-items-center">
      <img src="./assets/icons/icon-logo-white.svg" height="120" alt="zing logo" />
    </div>
    <div style="display: none">
      Zing by Jewelers Mutual, Zing Marketplace
    </div>
  `,
  styles: [`
    @keyframes fadeInAndOut {
      0% {
        opacity: 0.05;
        transform: scale(1);
      }
      50% {
        opacity: 1;
        transform: scale(1.02);
      }
      100% {
        opacity: 0.05;
        transform: scale(1);
      }
    }

    .starting img {
      animation: fadeInAndOut 1.5s infinite;
    }
  `]
})
export class AutoLoginComponent implements OnInit {

  sub: Subscription;
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private authService: AuthService
  ) {
  }

  ngOnInit() {
    if (this.authService.logoffRunning) {
      this.authService.logoffRunning = false;
      return;
    }
    // 177327 Signup link redirection fix
    localStorage.removeItem('oidcIsAuthenticated');
    this.oidcSecurityService.authorize();
  }

}
