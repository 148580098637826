import { CertItemDropdownSelection, ColorType, PriceType, SearchParameters } from '../models';

export const DEFAULT_SEARCH_PARAMETERS: SearchParameters = {
  //#region Basic Search Parameters

  customSearch: false,

  shapes: [],
  specialShapes: [],
  price: [0, 999999],
  carat: [0.0025, 10],
  naturalFancyColorIntensity: 'All',
  naturalFancyColorOvertone: ['A'],
  naturalFancyColor: ['A'],
  color: ['D', 'Z'],
  colorType: ColorType.DiamondColor,
  priceType: PriceType.TotalPrice,
  clarity: ['FL', 'I3'],
  cutGrade: ['ID', 'UN'],
  lightPerformances: ['0', '10'],
  proportions: ['0', '10'],
  polish: ['ID', 'UN'],
  symmetry: ['ID', 'PR'],
  fluorescenceIntensity: ['ALL'],
  fluorescenceColor: ['A'],
  gradingLabs: ['All'],
  reportTypes: ['All'],
  provenanceReports: ['All'],

  //#endregion

  //#region Advance Search Parameters

  idexItemId: null,
  sourceCountryCodes: ['ALL'],
  totalDepthMin: null,
  totalDepthMax: null,
  tableWidthMin: null,
  tableWidthMax: null,
  measurementsLengthMin: null,
  measurementsLengthMax: null,
  measurementsWidthMin: null,
  measurementsWidthMax: null,
  measurementsHeightMin: null,
  measurementsHeightMax: null,
  culetSizes: ['All'],
  culetConditions: ['All'],
  girdleMin: 'All',
  girdleMax: 'All',
  milkies: ['All'],
  blackInclusions: ['All'],
  eyeCleans: ['All'],
  shades: ['All'],
  gradingReportNumber: null,
  certItemDropdownSelection: CertItemDropdownSelection.CertificateNumber

  //#endregion
};

export const DEFAULT_SEARCH_PARAMETERS_AGS: SearchParameters = {
  //#region Basic Search Parameters

  customSearch: true,

  shapes: [],
  specialShapes: [],
  price: [0, 999999],
  carat: [0.0025, 10],
  naturalFancyColorIntensity: 'All',
  naturalFancyColorOvertone: ['A'],
  naturalFancyColor: ['A'],
  color: ['D', 'Z'],
  colorType: ColorType.DiamondColor,
  priceType: PriceType.TotalPrice,
  clarity: ['FL', 'I3'],
  cutGrade: ['ID', 'PR'],
  lightPerformances: ['0', '10'],
  proportions: ['0', '10'],
  polish: ['ID', 'UN'],
  symmetry: ['ID', 'PR'],
  fluorescenceIntensity: ['ALL'],
  fluorescenceColor: ['A'],
  gradingLabs: ['All'],
  reportTypes: ['All'],
  provenanceReports: ['All'],
  programs: ['AGS'],

  //#endregion

  //#region Advance Search Parameters

  idexItemId: null,
  sourceCountryCodes: ['ALL'],
  totalDepthMin: null,
  totalDepthMax: null,
  tableWidthMin: null,
  tableWidthMax: null,
  measurementsLengthMin: null,
  measurementsLengthMax: null,
  measurementsWidthMin: null,
  measurementsWidthMax: null,
  measurementsHeightMin: null,
  measurementsHeightMax: null,
  culetSizes: ['All'],
  culetConditions: ['All'],
  girdleMin: 'All',
  girdleMax: 'All',
  milkies: ['All'],
  blackInclusions: ['All'],
  eyeCleans: ['All'],
  shades: ['All'],
  gradingReportNumber: null,
  certItemDropdownSelection: CertItemDropdownSelection.CertificateNumber

  //#endregion
};

export const DEFAULT_PRICING_LIMIT: [number, number, number, number, number] = [0, 10000, 25000, 100000, 999999];

export const DEFAULT_CARAT_VALUES = [
  0.0025, 0.005, 0.01, 0.02, 0.03, 0.04,
  0.05, 0.06, 0.1, 0.2, 0.25, 0.33, 0.5,
  0.75, 0.9, 1, 1.25, 1.5, 2.0, 2.5, 3.0,
  3.5, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0
];

export const DEFAULT_LGD_CARAT_VALUES = [
  0, 0.0025, 0.005, 0.01, 0.02, 0.03, 0.04,
  0.05, 0.06, 0.1, 0.2, 0.25, 0.33, 0.5,
  0.75, 0.9, 1, 1.25, 1.5, 2.0, 2.5, 3.0,
  3.5, 4.0, 5.0, 6.0, 7.0, 8.0, 9.0, 10.0
];

export const DEFAULT_MEDIA = ['Image', 'Video'];
