export const WebsiteRegex = /^(http\:\/\/|https\:\/\/|HTTP\:\/\/|HTTPS\:\/\/)?([a-zA-Z0-9][a-zA-Z0-9\-]*\.)+[a-zA-Z0-9][a-zA-Z0-9\-]*$/;
export const PhoneNumberRegex = /^[0-9]{10,15}$/;
export const PhoneNumberInternationalRegex = /^[0-9]{9,25}$/;
export const USZipCodeRegex = /^[0-9]{5}(-[0-9]{4})?$/;
export const CanadaZipCodeRegex = /^[ABCEGHJKLMNPRSTVXY]{1}[0-9]{1}[A-Z]{1} *[0-9]{1}[A-Z]{1}[0-9]{1}$/;
export const InternationalZipCodeRegex = /^[0-9a-zA-Z\-\s]*$/;
export const AddressLineRegex = /^[A-Za-z0-9-\/#\s]*$/;
export const CityRegex = /^[A-Za-z-\/#\s]*$/;
export const StateRegex = /^[A-Za-z-\/#\s]*$/;
export const NameRegex = /^[a-zA-Z '.-]+$/;
export const BrinksContactRegex = /^[a-zA-Z0-9 \-.()\[\]#]+$/;
export const BrinksNameRegex = /^[a-zA-Z]+$/;
export const FeinIdRegex = /^[0-9]{9}$/;
export const DecimalRegex = /^[0-9]+(\.[0-9]{2})?$/;
export const OptionalLeadingZeroDecimalRegex = /^[0-9]*(\.[0-9]{1,2})?$/;
export const EStoreUrlRegex = /^https:\/\//;
export const AlphaNumericDashGlobalRegex = /[^a-z0-9]/gi;
export const InvalidFileCharacterRegex = /[*<>:"?/\\|]+/;

// Password Regex
export const PasswordHasUppercase = /(?=.*?[A-Z])/;
export const PasswordHasLowercase = /(?=.*?[a-z])/;
export const PasswordHasNumber = /(?=.*?[0-9])/;
export const PasswordHasSpecialChar = /(?=.*?[#?!@\$%^&*\-()_=+{}\[\];'\/"\\|<>,.`~:])/;
export const PasswordHasWhiteSpace = /\s/;
export const PasswordLengthRequirement = /^.{8,20}$/;
export const PasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*\-()_=+{}\[\];'\/"\\|<>,.`~:])([^\s]){8,20}$/;

// tslint:disable-next-line:max-line-length
export const EmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
// Email Regex with max length
// eslint-disable-next-line max-len
export const EmailIdRegex = /^(?!.{51})(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const DateTimeRegex = /'([1-9]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])T([0-1]\d|2[0-3]):([0-5]\d):([0-5]\d)Z)'/;
export const GuidRegex = /^(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})$/;
export const GuidQueryRegex = /'(\{{0,1}([0-9a-fA-F]){8}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){4}-([0-9a-fA-F]){12}\}{0,1})'/;
export const AccountPolicyNumberRegex = /^[0-9]+(-?[0-9]+)*$/;
export const AccountPolicyPostalCodeRegex = /^[a-zA-z0-9 ]+(-?[a-zA-z0-9 ]+)*$/;
export const AccountPolicyUSPostalCodeRegex = /^[0-9\-]*$/;
export const TariffCodeRegex = /^(\d(\.-?|-\.?)?){9}\d .{3,}$/;
export const PolicyNumberRegex = /^([5]{2}-[0-9]{6}|[3]{1}[0-9]{9})$/;
// FedEx Account Integration
export const FedexAccountNumberRegex = /^[0-9]{9}$/;
export const FedexMeterNumberRegex = /^[0-9]{7,9}$/;
// Appraisals
// Other Jewelry Style
export const AlphaNumericHyphenRegex = /^[a-zA-Z0-9\- ]{1,30}$/;
export const AlphaNumericRegex = /^[a-zA-Z0-9]+$/;
export const AlphaNumericSpaceRegex = /^[a-zA-Z0-9]([a-zA-Z0-9\s])*$/;
export const NumericRegex = /^[0-9]+$/;
export const AlphaNumericDashRegex= /^[a-zA-Z\d-]+$/;

export const AppraisalDisclaimerTextRegex = /^[a-zA-Z0-9\s\.\,\$\&\(\)\#\'"]*$/;

//Jeweler Pages
export const SubDomainRegex = /^[a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]$/;
