import { Action } from '@ngrx/store';
import { PaymentMethod } from '../models/payment-method.model';
import { PlatformModule } from '../models/platform-module.model';

export enum PaymentActionTypes {
    LoadPaymentMethods         = '[Payments] Load Payment Methods',
    LoadPaymentMethodsSuccess  = '[Payments] Load Payment Methods Success',
    LoadPaymentMethodsFail     = '[Payments] Load Payment Methods Fail',
    LoadPlatformModules        = '[Payments] Load Platform Modules',
    LoadPlatformModulesSuccess = '[Payments] Load Platform Modules Success',
    LoadPlatformModulesFail    = '[Payments] Load Platform Modules Fail',
    EditPaymentMethods         = '[Payments] Edit Payment Methods',
    UpdatePaymentMethod        = '[Payments] Update Payment Method',
    UpdatePaymentMethodSuccess = '[Payments] Update Payment Method Success',
    UpdatePaymentMethodFail    = '[Payments] Update Payment Method Fail',
    CreatePaymentMethod        = '[Payments] Create Payment Method',
    CreatePaymentMethodSuccess = '[Payments] Create Payment Method Success',
    CreatePaymentMethodFail    = '[Payments] Create Payment Method Fail'
}

export class LoadPaymentMethods implements Action {
    readonly type = PaymentActionTypes.LoadPaymentMethods;
    constructor(public payload: { companyId: string }) { }
}

export class LoadPaymentMethodsSuccess implements Action {
    readonly type = PaymentActionTypes.LoadPaymentMethodsSuccess;
    constructor(public payload: { paymentMethods: Array<PaymentMethod> }) { }
}

export class LoadPaymentMethodsFail implements Action {
    readonly type = PaymentActionTypes.LoadPaymentMethodsFail;
    constructor(public payload: any) { }
}

export class LoadPlatformModules implements Action {
    readonly type = PaymentActionTypes.LoadPlatformModules;
}

export class LoadPlatformModulesSuccess implements Action {
    readonly type = PaymentActionTypes.LoadPlatformModulesSuccess;
    constructor(public payload: { platformModules: Array<PlatformModule> }) { }
}

export class LoadPlatformModulesFail implements Action {
    readonly type = PaymentActionTypes.LoadPlatformModulesFail;
    constructor(public payload: any) { }
}

export class EditPaymentMethod implements Action {
    readonly type = PaymentActionTypes.EditPaymentMethods;
    constructor(public payload: any) {}
}

export class UpdatePaymentMethod implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentMethod;
    constructor(public payload: { companyId: string, paymentMethodId: string, paymentMethod: PaymentMethod }) { }
}

export class UpdatePaymentMethodSuccess implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentMethodSuccess;
  constructor(public payload: any) { }
}

export class UpdatePaymentMethodFail implements Action {
    readonly type = PaymentActionTypes.UpdatePaymentMethodFail;
    constructor(public payload: any) { }
}

export class CreatePaymentMethod implements Action {
    readonly type = PaymentActionTypes.CreatePaymentMethod;
    constructor(public payload: { companyId: string, paymentMethod: PaymentMethod }) { }
}

export class CreatePaymentMethodSuccess implements Action {
    readonly type = PaymentActionTypes.CreatePaymentMethodSuccess;
}

export class CreatePaymentMethodFail implements Action {
    readonly type = PaymentActionTypes.CreatePaymentMethodFail;
    constructor(public payload: any) { }
}

export type PaymentActions = LoadPaymentMethods
| LoadPaymentMethodsSuccess
| LoadPaymentMethodsFail
| LoadPlatformModules
| LoadPlatformModulesSuccess
| LoadPlatformModulesFail
| EditPaymentMethod
| UpdatePaymentMethod
| UpdatePaymentMethodSuccess
| UpdatePaymentMethodFail
| CreatePaymentMethod
| CreatePaymentMethodSuccess
| CreatePaymentMethodFail;
