import { Component, Input } from '@angular/core';
import { DiamondDetails } from '../../../models';

@Component({
  selector: 'z-cart-item-details',
  templateUrl: './cart-item-details.component.html',
  styleUrls: ['./cart-item-details.component.scss']
})
export class CartItemDetailsComponent {

  @Input()
  diamondDetails: DiamondDetails;

}
