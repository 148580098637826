import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { OrderInformation, OrderLocation, PaymentInformation } from '../../../order/models';
import { ShippingMethodType } from '../../../shopping-cart/models/shipping-method-type';

import * as fromStore from '../store';

@Injectable({
  providedIn: 'root'
})
export class OrderFacadeService {
  checkoutLoading$ = this.store.pipe(select(fromStore.getCheckoutLoading));
  order$ = this.store.pipe(select(fromStore.getOrder));
  orderLocation$ = this.store.pipe(select(fromStore.getOrderLocation));
  paymentInformation$ = this.store.pipe(select(fromStore.getPaymentInformation));
  checkoutError$ = this.store.pipe(select(fromStore.getCheckoutError));

  constructor(private store: Store<fromStore.ZingState>) { }

  clearOrder() {
    this.store.dispatch(new fromStore.ClearOrder());
  }

  saveShippingMethod(shippingMethod: ShippingMethodType) {
    this.store.dispatch(new fromStore.SaveShippingMethod(shippingMethod));
  }

  saveOrderLocation(orderLocation: OrderLocation) {
    this.store.dispatch(new fromStore.SavrOrderLocation(orderLocation));
  }

  saveOrderInformation(orderInformation: OrderInformation) {
    this.store.dispatch(new fromStore.SaveOrderInformation(orderInformation));
  }

  fetchPaymentInformation(zingOrderId: string) {
    this.store.dispatch(new fromStore.FetchPaymentInformation({ zingOrderId }));
  }

  clearPaymentInformation() {
    this.store.dispatch(new fromStore.ClearPaymentInformation());
  }

  updatePaymentInformation(paymentInformation: PaymentInformation) {
    this.store.dispatch(new fromStore.FetchPaymentInformationSuccess(paymentInformation));
  }
}
