<div class="d-flex cart-item-main">

  <div class="chevron-font">
    <div class="row toggle-container mb-2">
      <button [disabled]="!item.availableIndicator" class="custom-btn" id="toggle_destination"
        (click)="toggle(item.supplierItemIdentifier)">
        <label class="no-selection label-cursor">
          <i class="mdi" [ngClass]="(cartItemExpanded) ? 'mdi-chevron-up': 'mdi-chevron-down'"></i>
        </label>
      </button>
    </div>
  </div>

  <!-- Diamond Image -->
  <div class="mr-3 mb-3 image-thumb">
    <z-diamond-thumbnail 
      [supplierUrls]="item | shoppingCartDetailsToDiamondThumbnail" 
      [config]="imagesConfig"
      [displayAgsSpecificContent]="displayAgsSpecificContent">
    </z-diamond-thumbnail>
  </div>

  <div class="flex-fill container-cart">
    <div class="d-flex flex-lg-row w-100 mb-2" *ngIf="showAgsIdentifier(item)">
      <img class="ags-img" src="/assets/images/icons/shapes/partner-ags/Diamond.svg"  />
      <span class="ags-title">
        {{ 'Labels.ShoppingCart.AGS' | translate }}  
      </span>
    </div>
    <div class="details-wrapper d-flex">

      <div class="d-flex flex-wrap flex-fill">
        <!-- Unavailable item alert -->
        <div *ngIf="!item.availableIndicator" class="w-100">
          <div class="d-flex align-items-center not-available-alert font-14 alert alert-danger bg-white text-danger"
            role="alert">
            <i class="mdi mdi-alert-circle-outline mr-2 font-20"></i>
            <span>
              <span class="color-gray">
                {{ 'Labels.ShoppingCart.UnavailableItem' | translate }}
              </span>
              <a (click)="searchSimilar.emit(item)" class="text-underline roboto-bold search-similar">
                {{ 'Labels.ShoppingCart.SearchSimilar' | translate }}
              </a>
            </span>
          </div>
        </div>

        <!-- Diamond information -->
        <div *ngIf="agsUserAndItemAGSGraded; then agsDiamondInformationTemplate else nonAGSDiamondInformationTemplate">
        </div>
        <ng-template #agsDiamondInformationTemplate>
        <div class="d-flex flex-wrap diamond-info-wrapper w-100 ags-template">
          <div class="mr-5 mb-3 diamond-info-first-col">
            <!-- Item # -->
            <div class="mb-1 container-cart d-flex justify-content-between">
              <div class="font-20 color-gray ">
                <span>{{ 'Labels.DiamondDetails.ItemId' | translate }}</span>
                <span class="ml-1 roboto-bold">{{ item.supplierItemIdentifier }}</span>
              </div>

              <div class="d-none remove-item-sm">
                <ng-container *ngTemplateOutlet="removeButton"></ng-container>
              </div>
            </div>

            <!-- Location -->
            <div class="mb-1 text-wrap color-gray">
              <span>{{ 'Labels.DiamondOrderDetails.Location' | translate }}</span>
              <span class="ml-1">
                {{ item.sourceCountryCode }} {{ item.sourceRegionName ? ', ' + item.sourceRegionName : '' }}
              </span>
            </div>

            <!-- Cut grade-->
            <div class="mb-1" *ngIf="agsUserAndItemAGSGraded && item?.cutGrade"> 
              <span class="color-gray mr-1">{{ 'Labels.DiamondDetails.CutGrade' | translate }}</span>
              <span class="color-gray mr-1 roboto-bold">{{ item.cutGradeDescription || '-' }}</span>
            </div>

            <!-- ProvenanceReport -->
            <div class="mb-1 mt-1 color-gray" *ngIf="item?.provenanceReportName">
              <span class="mr-1">{{ 'Labels.DiamondDetails.ProvenanceReport' | translate }}</span>
              <a class="roboto-bold text-info text-underline report-link"
                *ngIf="isSupplierProvidedSource && item?.provenanceReportUrl | isSecureUrl; else provenanceReportTemplate"
                [zThirdPartyRedirect]="item.provenanceReportUrl">
                {{ item?.provenanceReportName }}
              </a>

              <ng-template #provenanceReportTemplate>
                <span id="provenance-report" class="font-weight-normal">{{ item?.provenanceReportName || '-' }}</span>
              </ng-template>
            </div>
          </div>

          <div class="d-flex mr-2 mb-3 diamond-info-second-col">
            <div class="mr-5">
              <div class="font-20 mb-1 color-gray">
                <img height="18" class="mr-1" *ngIf="diamondShapes?.includes(item?.shape)"
                  src="/assets/images/icons/shapes/{{ item.shape }} - Dark.svg" />
                <span class="roboto-bold">{{ item.shape }}</span>
              </div>
              <div class="mb-1 color-gray">
                <span>{{ 'Labels.DiamondDetails.Clarity' | translate }}</span>
                <span class="ml-1 roboto-bold">{{ item.clarity }}</span>
              </div>

              <!-- Light Performance -->
              <div *ngIf="agsUserAndItemAGSGraded && item.lightPerformance" class="mb-1 color-gray">
                <span>{{ 'Labels.DiamondDetails.LightPerformance' | translate }}</span>
                <span class="ml-1 roboto-bold">{{ item.lightPerformance }}</span>
              </div>
            </div>
            <div class="mr-2">
              <div class="font-20 roboto-bold color-gray mb-1">
                {{ item.carat }} {{ 'Labels.DiamondDetails.Carats' | translate }}
              </div>
              <div class="color-gray mb-1">
                <span>{{ 'Labels.DiamondDetails.Color' | translate }}</span>
                <span class="ml-1 roboto-bold font-13">{{ item.color }}</span>
              </div>
              <!-- Proportion factors -->
              <div *ngIf="agsUserAndItemAGSGraded &&  item.proportionFactor" class="mb-1 color-gray">
                <span class="mr-1">{{ 'Labels.DiamondDetails.ProportionFactor' | translate }}</span>
                <span class="roboto-bold">{{ item.proportionFactor }}</span>
              </div>
            </div>
          </div>
        </div>
          <!-- Diamond information -->
        <div class="d-flex flex-wrap diamond-info-wrapper w-100">
          <div class="mr-2 mb-3 diamond-info-first-col">
              <!-- Grading Lab -->
            <div class="mb-1 color-gray">
              <span class="mr-1">{{ 'Labels.DiamondDetails.GradingLab' | translate }}</span>
              <span>{{ item.gradingLab || '-' }}</span>
            </div>

            <!-- Report Number -->
            <div class="mb-1">
              <span class="color-gray mr-1">{{(showAgsIdentifier(item) ? 'Labels.DiamondDetails.AgsNumber' : 'Labels.DiamondDetails.ReportNumber') | translate }}</span>
              <ng-container *ngTemplateOutlet="provenanceReportNumberTemplate"></ng-container>
            </div>
 

            <!-- Report type -->
            <div class="mb-1" *ngIf="agsUserAndItemAGSGraded && item.reportType">
              <span class="color-gray mr-1">{{ 'Labels.DiamondDetails.AgsReportType' | translate }}</span>
              <span class="color-gray mr-1 roboto-bold">{{ item.reportType}}</span>
            </div>
            <div class="d-flex align-items-center mt-3" *ngIf="item.offIdexList">
              <span class="off-idex-value font-10 px-2 my-1">{{ item.offIdexList }}%</span>
              <span class="ml-1 color-gray">{{ 'Labels.DiamondDetails.OffIdexList' | translate }}</span>
            </div>

          </div>
 
        </div>
         
        </ng-template>
        <ng-template #nonAGSDiamondInformationTemplate>
          <div class="d-flex flex-wrap diamond-info-wrapper">
            <div class="mr-5 mb-3 diamond-info-first-col">
              <!-- Item # -->
              <div class="mb-1 container-cart d-flex justify-content-between">
                <div class="font-20 color-gray ">
                  <span>{{ 'Labels.DiamondDetails.ItemId' | translate }}</span>
                  <span class="ml-1 roboto-bold">{{ item.supplierItemIdentifier }}</span>
                </div>
  
                <div class="d-none remove-item-sm">
                  <ng-container *ngTemplateOutlet="removeButton"></ng-container>
                </div>
              </div>
  
              <!-- Location -->
              <div class="mb-1 text-wrap color-gray">
                <span>{{ 'Labels.DiamondOrderDetails.Location' | translate }}</span>
                <span class="ml-1">
                  {{ item.sourceCountryCode }} {{ item.sourceRegionName ? ', ' + item.sourceRegionName : '' }}
                </span>
              </div>
  
              <!-- Grading Lab -->
              <div class="mb-1 color-gray">
                <span class="mr-1">{{ 'Labels.DiamondDetails.GradingLab' | translate }}</span>
                <span>{{ item.gradingLab || '-' }}</span>
              </div>
  
              <!-- Report Number -->
              <div class="mb-1">
                <span class="color-gray mr-1">{{ (showAgsIdentifier(item) ? 'Labels.DiamondDetails.AgsNumber' : 'Labels.DiamondDetails.ReportNumber') | translate }}</span>
                <ng-container *ngTemplateOutlet="provenanceReportNumberTemplate"></ng-container>
              </div>
  
              <!-- ProvenanceReport -->
              <div class="mb-1 mt-1 color-gray" *ngIf="item?.provenanceReportName">
                <span class="mr-1">{{ 'Labels.DiamondDetails.ProvenanceReport' | translate }}</span>
                <a class="roboto-bold text-info text-underline report-link"
                  *ngIf="isSupplierProvidedSource && item?.provenanceReportUrl | isSecureUrl; else provenanceReportTemplate"
                  [zThirdPartyRedirect]="item.provenanceReportUrl">
                  {{ item?.provenanceReportName }}
                </a>
  
                <ng-template #provenanceReportTemplate>
                  <span id="provenance-report" class="font-weight-normal">{{ item?.provenanceReportName || '-' }}</span>
                </ng-template>
              </div>
            </div>
  
            <div class="d-flex mr-2 mb-3 diamond-info-second-col">
              <div>
                <div class="font-20 mb-1 color-gray">
                  <img height="18" class="mr-1" *ngIf="diamondShapes?.includes(item?.shape)"
                    src="/assets/images/icons/shapes/{{ item.shape }} - Dark.svg" />
                  <span class="roboto-bold">{{ item.shape }}</span>
                </div>
                <div class="mb-1 color-gray">
                  <span>{{ 'Labels.DiamondDetails.Clarity' | translate }}</span>
                  <span class="ml-1 roboto-bold">{{ item.clarity }}</span>
                </div>
  
                <div class="d-flex align-items-center mt-1" *ngIf="item.offIdexList">
                  <span class="off-idex-value font-10 px-2 my-1">{{ item.offIdexList }}%</span>
                  <span class="ml-1 color-gray">{{ 'Labels.DiamondDetails.OffIdexList' | translate }}</span>
                </div>
              </div>
  
              <div class="ml-5">
                <div class="font-20 roboto-bold color-gray mb-1">
                  {{ item.carat }} {{ 'Labels.DiamondDetails.Carats' | translate }}
                </div>
                <div class="color-gray">
                  <span>{{ 'Labels.DiamondDetails.Color' | translate }}</span>
                  <span class="ml-1 roboto-bold font-13">{{ item.color }}</span>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>

      <!-- Remove item action -->
      <div class="remove-item-lg">
        <ng-container *ngTemplateOutlet="removeButton"></ng-container>
      </div>
    </div>

    <div class="d-flex flex-lg-row flex-column flex-column-reverse">
      <!-- Save to Favorites and Search Similar Actions -->
      <div class="me-auto mb-2">

        <a class="roboto-bold text-info text-nowrap d-inline-block" (click)="searchSimilar.emit(item)">
          <i class="dripicons-search mr-1"></i>
          {{ 'Labels.ShoppingCart.SearchSimilar' | translate }}
        </a>
      </div>

      <div class="mb-3 mb-lg-0 diamond-info-last">
        <div class="text-lg-right font-14 color-gray mb-1">
          {{ 'Labels.DiamondDetails.PricePerCarat' | translate }}
          <span class="roboto-bold">{{ item.pricePerCarat | currency }}</span>
        </div>
        <div class="text-lg-right font-20 color-gray">
          <ng-container *ngTemplateOutlet="priceChangeTemplate"></ng-container>
          {{ 'Labels.ShoppingCart.DiamondPrice' | translate }}
          <span class="roboto-bold color-green">{{ item.price | currency }}</span>
        </div>
      </div>
    </div>

    <z-spinner [inProgress]="cartItemLoading"></z-spinner>

    <div @slideIn *ngIf="cartItemExpanded && !cartItemLoading" class="accord-body-wrap">
      <z-cart-item-details *ngIf="!cartItemError else ShowError" [diamondDetails]="diamondDetails">
      </z-cart-item-details>
      <ng-template #ShowError>
        <div class="alert alert-danger bg-danger align-items-center text-white border-0 mt-2 ml-1 alert-main"
          role="alert">
          <i class="uil-exclamation-circle mr-2 font-20 alert-icon-error"></i>
          <span class="font-13 mb-1 alert-text">{{ 'Errors.SystemError' | translate }}</span>
        </div>
      </ng-template>
    </div>

  </div>
</div>

<ng-template #provenanceReportNumberTemplate>
  <a class="roboto-bold text-info text-underline report-link" *ngIf="(item.reportUrl | isSecureUrl); else noGradingReportUrl"
    [title]="'Labels.DiamondDetails.GradingLabLinkTooltip' | translate" [zThirdPartyRedirect]="item.reportUrl">
    {{ item.gradingReportNumber || '-' }}
  </a>
  <ng-template #noGradingReportUrl>
    <span class="roboto-bold color-gray">{{ item.gradingReportNumber || '-' }}</span>
  </ng-template>
</ng-template>

<ng-template #priceChangeTemplate>
  <i *ngIf="item.isPriceUpdated" [ngbTooltip]="'Labels.DiamondDetails.PriceTotalUpdateTooltip' | translate"
    tooltipClass="zing-ui-tooltip left-tooltip" container="body" class="alert-icon mdi mdi-alert mr-1"
    [placement]="'top'" [triggers]="(responsivePopover$ | async)?.matches ? 'click' : 'hover'">
  </i>
</ng-template>

<ng-template #removeButton>
  <a class="text-nowrap mb-3 text-danger roboto-bold" (click)="remove.emit(item)">
    <i class="mdi mdi-close"></i> {{ 'Remove' | translate }}
  </a>
</ng-template>
