import {
  createFeatureSelector,
  createSelector
} from '@ngrx/store';

import {
  TwoFAActions,
  TwoFactorAuthActionTypes
} from '../actions/two-factor-auth.actions';
import * as fromRoot from '../root.state';

export interface ZingState extends fromRoot.ZingState {
  twoFactorState: TwoFactorState;
}

export interface TwoFactorState {
  error?: any;
  isLoading?: boolean;
  initiated: boolean;
  email: string;
  phoneNumber: string;
  accountLocked: boolean;
  verification: {
    selectedMethod: string;
    codeSent: boolean;
    verificationCode: string;
    codeVerified: boolean;
  };
  securityQuestion: {
    id: string,
    questionText: string,
    wrongAnswer: boolean,
    answerVerified: boolean,
    authenticationFailed: boolean
  };
  completed: boolean;
  isValidTwoFA: boolean;
}

export const initialState: TwoFactorState = {
  initiated: false,
  email: null,
  phoneNumber: null,
  accountLocked: false,

  verification: {
    selectedMethod: null,
    codeSent: false,
    verificationCode: null,
    codeVerified: false
  },
  securityQuestion: {
    id: null,
    questionText: null,
    wrongAnswer: false,
    answerVerified: false,
    authenticationFailed: false
  },
  completed: false,
  isValidTwoFA: true
};

const getTwoFAState = createFeatureSelector<TwoFactorState>('twoFactorState');

export const getSecurityQuestion = createSelector(
  getTwoFAState,
  state => state.securityQuestion
);

export const getVerification = createSelector(
  getTwoFAState,
  state => state.verification
);

export const getIsLoading = createSelector(
  getTwoFAState,
  state => state.isLoading
);

export const getTwoFactorAuthProviderDetails = createSelector(
  getTwoFAState,
  state => ({
    email: state.email, phoneNumber: state.phoneNumber,
    securityQuestion: state.securityQuestion, isValidTwoFA: state.isValidTwoFA
  })
);

export const getError = createSelector(
  getTwoFAState,
  state => state.error
);

export const getIsCompleted = createSelector(
  getTwoFAState,
  state => state.completed
);

export function twoFAReducer(state = initialState, action: TwoFAActions): TwoFactorState {

  switch (action.type) {
    case TwoFactorAuthActionTypes.LoadAuthMethods:
      return {
        ...state,
        isLoading: true
      };
    case TwoFactorAuthActionTypes.LoadAuthMethodsSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        // eslint-disable-next-line max-len
        email: (action.payload['providers'] != null && action.payload['providers'][0] != null) ? action.payload['providers'][0].value : '',
        // eslint-disable-next-line max-len
        phoneNumber: (action.payload['providers'] != null && action.payload['providers'][1] != null) ? action.payload['providers'][1].value : '',
        securityQuestion: {
          id: (action.payload['securityQuestion'] != null) ? action.payload['securityQuestion'].id : '',
          questionText: (action.payload['securityQuestion'] != null) ? action.payload['securityQuestion'].questionText : '',
          wrongAnswer: false,
          answerVerified: false,
          authenticationFailed: false
        },
        isValidTwoFA: action.payload['isValid']
      };
    case TwoFactorAuthActionTypes.LoadAuthMethodsFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case TwoFactorAuthActionTypes.SendVerificationCode:
      return {
        ...state,
        isLoading: true,
        verification: { ...state.verification, selectedMethod: action.payload.method }
      };
    case TwoFactorAuthActionTypes.SendVerificationCodeSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        verification: { ...state.verification, codeSent: true }
      };
    case TwoFactorAuthActionTypes.SendVerificationCodeFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        verification: { ...state.verification, codeSent: false }
      };
    case TwoFactorAuthActionTypes.VerifyCode:
      return {
        ...state,
        isLoading: true,
        verification: { ...state.verification, verificationCode: action.payload.token }
      };
    case TwoFactorAuthActionTypes.VerifyCodeSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        verification: { ...state.verification, codeVerified: true }
      };
    case TwoFactorAuthActionTypes.VerifyCodeFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        verification: { ...state.verification, codeVerified: false, codeSent: false }
      };
    case TwoFactorAuthActionTypes.GetSecurityQuestion:
      return {
        ...state,
        isLoading: true
      };
    case TwoFactorAuthActionTypes.GetSecurityQuestionSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        securityQuestion: {
          ...action.payload,
          wrongAnswer: false,
          answerVerified: false,
          authenticationFailed: false
        }
      };
    case TwoFactorAuthActionTypes.GetSecurityQuestionFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case TwoFactorAuthActionTypes.VerifyAnswer:
      return {
        ...state,
        isLoading: true
      };
    case TwoFactorAuthActionTypes.VerifyAnswerSuccess:
      const answerVerified = action.payload.verifyStatus && !action.payload.authenticationFailed;
      return {
        ...state,
        isLoading: false,
        error: null,
        securityQuestion: {
          ...state.securityQuestion,
          wrongAnswer: !action.payload.verifyStatus,
          answerVerified,
          authenticationFailed: action.payload.authenticationFailed
        },
        completed: state.verification.codeVerified && answerVerified
      };
    case TwoFactorAuthActionTypes.VerifyAnswerFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case TwoFactorAuthActionTypes.ResetTwoFactorAuth:
      return {
        ...state,
        verification: {
          selectedMethod: null,
          codeSent: false,
          verificationCode: null,
          codeVerified: false
        },
        securityQuestion: {
          id: null,
          questionText: null,
          wrongAnswer: false,
          answerVerified: false,
          authenticationFailed: false
        }
      };
    default: {
      return state;
    }
  }
}
