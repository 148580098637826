import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class BannerService {
  private placeholderUrl = environment.contentApiURL + '/api/banner';

  constructor(
    private http: HttpClient
  ) { }

  getBanners(screentype, anonymous = false): Observable<any> {
    const url = `${this.placeholderUrl}${anonymous ? '/anonymous' : ''}?screenType=${screentype}`;
    return this.http.get(url);
  }

  dismissBanner(banner): Observable<any> {
    const url = `${this.placeholderUrl}/${banner.id}`;
    return this.http.put(url, banner);
  }
}
