import { Component, Input } from '@angular/core';

@Component({
  selector: 'z-spinner',
  template: `
    <div [ngClass]="{
      'spinner' : !transparentBackground && !isRelativePosition,
      'positioned-spinner': isRelativePosition }"
         *ngIf="inProgress">
      <div class="lds-dual-ring">
        <div class="text-container">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  `,
  styles: ['.text-container{ position: absolute; margin-top: 110%; margin-left: -80%; white-space: nowrap; }']
})
export class SpinnerComponent {
  @Input() inProgress = false;
  @Input() transparentBackground = false;
  // if we want to display spinner in a specific position in parent
  @Input() isRelativePosition = false;
  @Input() text = '';

  constructor() {}
}
