import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class EmailService {

  constructor(private http: HttpClient) { }

  public requestJmccSupport(toEmail: string, templateName: string, templateModel: any): Observable<void> {
    const model = {
      to: [
        toEmail
      ],
      subject: 'JMCC Support Requested',
      isDisclaimerExcluded: false,
      templateName,
      templateModel
    };

    return this.sendEmail(model);
  }

  public sendEmail(model: any): Observable<void> {
    return this.http.post<void>(`${environment.communicationApiURL}/api/v2/emailmessages`, model);
  }
}
