import {
  transition,
  trigger,
  useAnimation
} from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { finalize, map, take } from 'rxjs/operators';
import { environment } from '../../../../../../environments/environment';
import { MarketplacePartnerAffiliationType } from '../../../../shared/enums/marketplace-partner-affiliation-types';
import { PartnerService, ProfileService } from '../../../../shared/services';
import { expandRowAnimation } from '../../../animations';
import { DiamondDetails, DiamondThumbnailConfig, DiamondThumbnailLabelAlign } from '../../../models';
import { NaturalCartItem } from '../../../models/natural-cart';
import { MarketplaceFacade, MarketplaceService } from '../../../services';
import { isShoppingCartAGSDiamond } from '../../../utils';

@Component({
  selector: 'z-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  animations: [
    trigger('slideIn', [
      transition(':enter', useAnimation(expandRowAnimation))
    ])
  ]
})
export class CartItemComponent implements OnInit, OnChanges {
  @Output() remove = new EventEmitter<NaturalCartItem>();
  @Output() searchSimilar = new EventEmitter<NaturalCartItem>();
  @Output() saveToFavorites = new EventEmitter<NaturalCartItem>();
  @Input() item: NaturalCartItem;
  @Output() expandedItemId = new EventEmitter<string>();
  @Input() displayAgsSpecificContent = false;

  readonly isSupplierProvidedSource = environment.supplierProvidedSourceEnabled;

  readonly imagesConfig: DiamondThumbnailConfig = {
    displaySupplierProvidedUrls: true,
    imgWidth: 110,
    fontSize: '14px',
    labelAlign: DiamondThumbnailLabelAlign.Left
  };

  responsive$ = this.breakpointObserver.observe('(max-width:600px)');
  responsivePopover$ = this.breakpointObserver.observe('(max-device-width: 1024px)');
  cartItemLoading = false;
  diamondShapes: string[];
  @Input() cartItemExpanded?: boolean;
  diamondDetails: DiamondDetails;
  cartItemError = false;
  agsUserAndItemAGSGraded = false;

  constructor(
    private marketplaceFacade: MarketplaceFacade,
    private marketplaceService: MarketplaceService,
    private cdRef: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private partnerService: PartnerService,
    private profileService: ProfileService,
  ) { }

  ngOnInit() {
    this.marketplaceFacade.shapes$
      .pipe(
        map(shapes => shapes && shapes.map(shape => shape.shapeName)),
        take(1)
      )
      .subscribe(shapes => this.diamondShapes = shapes);

    this.agsUserAndItemAGSGraded = this.item.gradingLab === 'AGS' && this.displayAgsSpecificContent;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['cartItemExpanded'] && changes['cartItemExpanded'].currentValue) {
      this.toggleSection(this.item.supplierItemIdentifier);
    }
  }

  toggle(diamondId: any) {
    this.expandedItemId.emit(diamondId);
  }

  parseNumber(diamondId: string) {
    // eslint-disable-next-line radix
    return Number.parseInt(diamondId);
  }

  toggleSection(diamondId: any) {
    this.cartItemError = false;

    if (this.cartItemExpanded && !this.diamondDetails) {
      this.cartItemLoading = true;
      this.getShoppingCartItem(diamondId);
    }
  }

  getShoppingCartItem(diamondId: any) {
    this.marketplaceService.getDiamondDetailsResult({
      diamondIds: [diamondId]
    })
      .pipe(
        take(1),
        finalize(() => {
          this.cdRef.markForCheck();
          this.cartItemLoading = false;
        })
      )
      .subscribe(s => {
        this.diamondDetails = s.diamonds[0];
      }, () => {
        this.cartItemError = true;
      });
  }

  showAgsIdentifier(diamond: NaturalCartItem): boolean {
    return this.isAgsPartner() && isShoppingCartAGSDiamond(diamond);
  }

  isAgsPartner(): boolean {
    return !!this.partnerService
      .getPartnerPermission(MarketplacePartnerAffiliationType.AGS, this.profileService.person, true);
  }
}
