import { Injectable } from '@angular/core';

import { of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LocationService } from '../../membership/modules/account/services/location.service';
import { CompanyService, ProfileService } from '../../shared/services';
import { getOrderLocations } from '../../marketplace/utils';
import { OrderLocation } from '../models/order-location';

@Injectable({
  providedIn: 'root'
})
export class OrderLocationFacade {

  private orderLocations: OrderLocation[];

  constructor(
    private locationService: LocationService,
    private profileService: ProfileService,
    private companyService: CompanyService
  ) { }

  getOrderLocations(forceLoad = false) {
    if (!this.orderLocations || forceLoad) {
      return this.locationService.getLocationsByCompanyId(this.companyService.company.id, true)
        .pipe(
          map(companyLocations => getOrderLocations(
            this.profileService.person,
            this.companyService.company,
            companyLocations
          )),
          tap(orderLocations => this.orderLocations = orderLocations)
        );
    } else {
      return of(this.orderLocations);
    }
  }

}
