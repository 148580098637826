<div class="page-not-found d-flex flex-column justify-content-center align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2 text-center heading-30-bold-primary">404 Page not found</div>
    </div>
    <div class="row mt-5">
      <div class="col-12 col-lg-4 offset-lg-4">
        <button (click)="goBack()" class="btn-md btn-secondary-white">
          {{ 'GoBack' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>