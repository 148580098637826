
import {map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { PaymentMethod } from '../models/payment-method.model';
import { PaymentType } from '../models/payment-type.enum';
import { CreditCardTypeUtil } from '../models/credit-card-type-enum';
import { Address } from '../models/address.model';
import { PlatformModule } from '../models/platform-module.model';
import { CreditCardDetails } from '../models/credit-card-details.model';
import { environment } from '../../../../../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  apiDomain = environment.paymentApiURL;

  paymentMethods = new Array<PaymentMethod>();
  platformModules = new Array<PlatformModule>();
  paymentMethodAdded = new Subject<boolean>();

  private creditCard: CreditCardDetails;
  private isCreditCardFetched: boolean;
  private companyId;

  constructor(
    private http: HttpClient
  ) {
    this.populatePlatformModulesArray();
  }

  fetch(companyId = null): Observable<CreditCardDetails> {
    if (companyId) {
      this.companyId = companyId;
    }
    if (this.isCreditCardFetched) {
      return of(this.creditCard);
    } else {
      return this.fetchCard(this.companyId);
    }
  }

  get isCreditCardAdded(): boolean {
    return this.isCreditCardFetched && !!this.creditCard;
  }

  get isCreditCardDisabled(): boolean {
    return this.isCreditCardFetched && this.creditCard !== null && !this.creditCard.isActive;
  }

  get lastFour(): string {
    if (this.isCreditCardAdded) {
      return this.creditCard.lastFour;
    }
    return '';
  }

  get creditCardType(): CreditCardTypeUtil {
    return this.isCreditCardAdded ? CreditCardTypeUtil.parse(this.creditCard.cardType) : '';
  }

  private fetchCard(companyId: string) {
    this.companyId = companyId;
    const url = `${this.apiDomain}/api/creditcards/search/${companyId}`;
    return this.http.get<CreditCardDetails>(url).pipe(tap(cc => this.cacheCreditCard(cc)));
  }

  private cacheCreditCard(cc: CreditCardDetails) {
    this.creditCard = cc;
    this.isCreditCardFetched = true;
  }

  getToken(data: any): Observable<any> {
    const url = `${this.apiDomain}/api/tokens`;
    return this.http.post(url, data);
  }

  save(data: CreditCardDetails): Observable<any> {
    const hasCreditCard = this.isCreditCardAdded;
    const url = `${this.apiDomain}/api/creditcards`;
    return this.http.post(url, data).pipe(tap(res => {
      if (res.succeeded) {
        this.isCreditCardFetched = false;
        this.fetch();
        this.paymentMethodAdded.next(!hasCreditCard);
      }
    }));
  }

  getAllPaymentMethods(companyId: string): Observable<PaymentMethod[]> {
    this.isCreditCardFetched = undefined;
    return this.fetch(companyId).pipe(map(card => {
      let count = 0;
      const methods: PaymentMethod[] = [];
      if (card) {
        const method = {
          id: `${count++}`,
          isDefault: true,
          autoGeneratedName: 'NAME CONCATENATED',
          billingAddress: new Address(card.ccAddress.addressLine1, card.ccAddress.addressLine2, card.ccAddress.city,
            card.ccAddress.state, '', card.ccAddress.postalCode, card.ccAddress.countryCode, 0, 0, 0),
          paymentType: PaymentType.CreditCard,
          isDeactivated: !card.isActive,
          creditCardInfo: {
            nameOnCard: card.nameOnCard,
            cardNumber: `XXXX XXXX XXXX ${card.lastFour}`,
            creditCardType: CreditCardTypeUtil.parse(card.cardType),
            expiryDate: `${('0' + card.cardExpirationMonth).slice(-2)} / ${('0' + card.cardExpirationYear).slice(-2)}`,
          }
        };
        methods.push(method);
      }
      this.paymentMethods = methods;
      return methods;
    }));
  }

  populatePlatformModulesArray(): void {
    const modules: PlatformModule[] = [
      {
        id: '123',
        name: 'Shipping'
      },
      {
        id: '234',
        name: 'Insurance'
      },
      {
        id: '345',
        name: 'Benchmarking'
      },
      {
        id: '456',
        name: 'Commercial Policy'
      }
    ];
    this.platformModules = modules;
  }

  getAllAvailableModules(): Observable<PlatformModule[]> {
    return of(this.platformModules);
  }

  updatePaymentMethod(companyId: string, paymentMethodId: string, paymentMethod: PaymentMethod): Observable<any> {
    this.paymentMethods.splice(this.paymentMethods.findIndex(x => x.id === paymentMethodId), 1, paymentMethod);
    return of({});
  }

  createPaymentMethod(companyId: string, paymentMethod: PaymentMethod): Observable<any> {
    paymentMethod.id = `${this.paymentMethods.length++}`;
    this.paymentMethods.push(paymentMethod);
    return of({});
  }
}
