import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TeamActions, TeamActionTypes, ZingError } from './team.actions';
import * as fromRoot from '../../../../../store/root.state';
import { TeamMember } from '../models/team-member';
import { PaginatedResponse } from '../../../../shared/models/paginated-response.model';

export interface ZingState extends fromRoot.ZingState {
  teamState: TeamState;
}

export interface TeamState {
  error?: ZingError;
  updateSaveShippingDataError?: any;
  isLoading?: boolean;
  isUserDataLoading?: boolean;
  rolesLoaded: boolean;
  accountUserDataError?: any;
  availableRoles: Array<any>;
  locationsLoaded: boolean;
  availableLocations: Array<any>;
  teamMembers: PaginatedResponse<TeamMember>;
  memberApproved: boolean;
  memberUpdated: boolean;
}

export const initialState: TeamState = {
  rolesLoaded: false,
  accountUserDataError: null,
  updateSaveShippingDataError: null,
  availableRoles: [],
  locationsLoaded: false,
  availableLocations: [],
  teamMembers: null,
  memberApproved: false,
  memberUpdated: false
};

const getTeamFeatureState = createFeatureSelector<TeamState>('team');

export const getTeamMembers = createSelector(
  getTeamFeatureState,
  state => state.teamMembers
);

export const getIsLoading = createSelector(
  getTeamFeatureState,
  state => state.isLoading
);

export const getRequestsFinished = createSelector(
  getTeamFeatureState,
  ({ isLoading, isUserDataLoading }) =>
  isLoading || isUserDataLoading
);

export const getError = createSelector(
  getTeamFeatureState,
  state => state.error
);

export const getRolesLoaded = createSelector(
  getTeamFeatureState,
  state => state.rolesLoaded
);

export const getAvailableRoles = createSelector(
  getTeamFeatureState,
  state => state.availableRoles
);

export const getLocationsLoaded = createSelector(
  getTeamFeatureState,
  state => state.locationsLoaded
);

export const getIAvailableLocations = createSelector(
  getTeamFeatureState,
  state => state.availableLocations
);

export const getMemberApproved = createSelector(
  getTeamFeatureState,
  state => state.memberApproved
);

export const getMemberUpdated = createSelector(
  getTeamFeatureState,
  state => state.memberUpdated
);

export const getUpdateSaveShippingDataError = createSelector(
  getTeamFeatureState,
  state => state.updateSaveShippingDataError
);

export const getAccountUserDataError = createSelector(
  getTeamFeatureState,
  state => state.accountUserDataError
);

export function teamReducer(
  state = initialState,
  action: TeamActions): TeamState {
  switch (action.type) {
    case TeamActionTypes.LoadLocations:
      return {
        ...state
      };
    case TeamActionTypes.LoadLocationsSuccess:
      return {
        ...state,
        error: null,
        locationsLoaded: true,
        availableLocations: [...action.payload]
      };
    case TeamActionTypes.LoadLocationsFail:
      return {
        ...state,
        locationsLoaded: false,
        error: action.payload
      };
    case TeamActionTypes.LoadRoles:
      return {
        ...state
      };
    case TeamActionTypes.LoadRolesSuccess:
      return {
        ...state,
        error: null,
        rolesLoaded: true,
        availableRoles: [...action.payload]
      };
    case TeamActionTypes.LoadRolesFail:
      return {
        ...state,
        rolesLoaded: false,
        error: action.payload
      };
    case TeamActionTypes.LoadTeamMembers:
      return {
        ...state,
        isLoading: true,
        memberApproved: false,
        memberUpdated: false
      };
    case TeamActionTypes.LoadTeamMembersSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        teamMembers: action.payload
      };
    case TeamActionTypes.LoadTeamMembersFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case TeamActionTypes.ResetTeamMembers:
      return {
        ...state,
        teamMembers: null
      };
    case TeamActionTypes.ApproveTeamMember:
    case TeamActionTypes.UpdateTeamMember:
      return {
        ...state,
        isLoading: true
      };
    case TeamActionTypes.ApproveTeamMemberSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        memberApproved: true,
        memberUpdated: false
      };
    case TeamActionTypes.UpdateTeamMemberSuccess:
      return {
        ...state,
        isLoading: false,
        error: null,
        memberApproved: false,
        memberUpdated: true,
      };
    case TeamActionTypes.ApproveTeamMemberFail:
    case TeamActionTypes.UpdateTeamMemberFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case TeamActionTypes.RejectTeamMember:
      return {
        ...state,
        isLoading: true
      };
    case TeamActionTypes.RejectTeamMemberSuccess:
      return {
        ...state,
        isLoading: false,
        error: null
      };
    case TeamActionTypes.RejectTeamMemberFail:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
      case TeamActionTypes.UpdateShippingUserData: {
        return {
          ...state,
          isUserDataLoading: true
        };
      }
    case TeamActionTypes.UpdateShippingUserDataSuccess: {
      return {
        ...state,
        updateSaveShippingDataError: null,
        isUserDataLoading: false
      };
    }
    case TeamActionTypes.UpdateShippingUserDataFail: {
      return {
        ...state,
        updateSaveShippingDataError: action.payload,
        isUserDataLoading: false
      };
    }
    case TeamActionTypes.SaveShippingUserData: {
      return {
        ...state,
        isUserDataLoading: true
      };
    }
    case TeamActionTypes.SaveShippingUserDataSuccess: {
      return {
        ...state,
        updateSaveShippingDataError: null,
        isUserDataLoading: false
      };
    }
    case TeamActionTypes.SaveShippingUserDataFail: {
      return {
        ...state,
        updateSaveShippingDataError: action.payload,
        isUserDataLoading: false
      };
    }
    case TeamActionTypes.PrepareForEdit: {
      return {
        ...state,
        memberApproved: false,
        memberUpdated: false,
        error: null,
        updateSaveShippingDataError: null,
        accountUserDataError: null,
      };
    }
    case TeamActionTypes.SaveAccountUserSettings:
    case TeamActionTypes.UpdateAccountUserSettings: {
      return {
        ...state
      };
    }
    case TeamActionTypes.SaveAccountUserSettingsSuccess:
    case TeamActionTypes.UpdateAccountUserSettingsSuccess: {
      return {
        ...state,
        accountUserDataError: null
      };
    }
    case TeamActionTypes.SaveAccountUserSettingsFail:
    case TeamActionTypes.UpdateAccountUserSettingsFail: {
      return {
        ...state,
        accountUserDataError: action.payload
      };
    }
    default:
      return state;
  }
}
