import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import * as fromStore from '../../../../marketplace/store';
import { PaymentSourceService } from '../../../../order/services';
import { AnalyticsCustomEvents } from '../../../../shared/enums/analytics-custom-events';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { ModuleType } from '../../../../shopping-cart/models/module-type';
import { DiamondCartFacadeService } from '../../../services/diamond-cart-facade.service';
import { DiamondMarketplaceCartService } from '../../services/diamond-marketplace-shopping-cart.service';
import { mapShoppingCart } from '../../utils/shopping-cart-utils';
import * as shoppingCartActions from '../actions/shopping-cart.actions';
import { getOrderLocation, getShippingMethod, ZingState } from '../reducers/shopping-cart.reducer';

@Injectable()
export class ShoppingCartEffects {

  constructor(
    private actions$: Actions,
    private shoppingCartService: DiamondMarketplaceCartService,
    private shoppingCartFacade: DiamondCartFacadeService,
    private store: Store<ZingState>,
    private analyticsService: AnalyticsService,
    private paymentSourceService: PaymentSourceService
  ) { }

  getCart$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(shoppingCartActions.ShoppingCartActionTypes.GetShoppingCart),
      concatMap(({ payload }:  shoppingCartActions.GetShoppingCart) => of(payload).pipe(
        withLatestFrom(
          this.store.pipe(select(fromStore.getReportTypes))
        )
      )),
      switchMap(([payload, reportTypes]) =>
        this.shoppingCartService.getCart(payload?.locationId, payload?.shippingMethod)
          .pipe(
            map(cart => mapShoppingCart(cart, reportTypes)),
            map(cart => new shoppingCartActions.GetShoppingCartSuccess(cart)),
            catchError(err => of(new shoppingCartActions.GetShoppingCartFail(err)))
          )
      )
    ));

  updateCart$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(shoppingCartActions.ShoppingCartActionTypes.AddToShoppingCart),
      mergeMap((action: shoppingCartActions.AddToShoppingCart) =>
        this.shoppingCartService.updateCart(action.payload)
          .pipe(
            map(() => new shoppingCartActions.AddToShoppingCartSuccess()),
            catchError(err => of(new shoppingCartActions.AddToShoppingCartFail(err)))
          )
      )
    ));

  removeCartItem$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(shoppingCartActions.ShoppingCartActionTypes.RemoveFromCart),
      switchMap(({ payload }: shoppingCartActions.RemoveFromCart) =>
        this.shoppingCartService.removeCartItem(payload.id)
          .pipe(
            switchMap(() => [
              new shoppingCartActions.RemoveFromCartSuccess(),
              new shoppingCartActions.GetShoppingCart({
                locationId: payload.locationId,
                shippingMethod: payload.shippingMethod
              })
            ]),
            catchError(err => of(new shoppingCartActions.RemoveFromCartFail(err)))
          )
      )
    ));

  checkout$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(shoppingCartActions.ShoppingCartActionTypes.Checkout),
      concatMap(({ payload }: shoppingCartActions.Checkout) => of(payload.paymentMethod).pipe(
        withLatestFrom(
          this.store.pipe(select(getOrderLocation)),
          this.store.pipe(select(getShippingMethod))
        )
      )),
      switchMap(([paymentMethod, orderLocation, shippingMethod]) =>
        this.shoppingCartFacade.checkout(paymentMethod, shippingMethod)
          .pipe(
            tap(() =>
              this.analyticsService.analytics.eventTrack.next({
                action: `${AnalyticsCustomEvents.DiamondOrderCompleted}-Success`
              })
            ),
            map(() => new shoppingCartActions.CheckoutSuccess()),
            catchError(err => {
              this.analyticsService.analytics.eventTrack.next({
                action: `${AnalyticsCustomEvents.DiamondOrderCompleted}-Error`
              });
              return of(new shoppingCartActions.CheckoutFail(err));
            })
          )
      )
    ));

  fetchPaymentInformation$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(shoppingCartActions.ShoppingCartActionTypes.FetchPaymentInformation),
      switchMap(({ payload: { zingOrderId } }: shoppingCartActions.FetchPaymentInformation) =>
        this.paymentSourceService.getByOrderId(zingOrderId)
          .pipe(
            map(paymentSource => new shoppingCartActions.FetchPaymentInformationSuccess(paymentSource)),
            catchError(err => of(new shoppingCartActions.FetchPaymentInformationFail(err)))
          )
      )));
}
