import 'moment-timezone';
import * as moment from 'moment';

declare var require: any;

export function formatDateWithTimeZone(date: any, format: string = 'MMMM DD, YYYY h:mm A z'): Date {
    const m = require('moment-timezone');
    return m.tz(date.toLocaleString(), m.tz.guess(true)).format(format);
  }

  export function formatDateWithTimeZoneUTC(date: any, format: string = 'MMMM DD, YYYY h:mm A z'): string {
    const m = require('moment-timezone');
    return m.tz(date.toUTCString(), m.tz.guess(true)).format(format);
  }

/**
 * This method is used for hidding the shipment delivery date in cases when we get the invalid date for international shipments.
 * In some cases, when delivery date is not defined, we are getting the invalid dates with year value 1979 or 1980.
 * Day and Month values are not constant, so 12/31/1980 is used as an upper limit for the check.
 * @param date The date expression: a `Date` object,  a number
 * (milliseconds since UTC epoch), or an ISO string (https://www.w3.org/TR/NOTE-datetime).
 * @returns Indication if the date is after the 12/31/1980.
 */
export function isValidDate(date: string): boolean {
    const formattedDate = date ? moment(date) : null;

    return formattedDate && !formattedDate.isSameOrBefore(new Date('12/31/1980'));
  }
