import { environment } from '../../environments/environment';
import {
  AuthWellKnownEndpoints, LogLevel, OpenIdConfiguration
} from 'angular-auth-oidc-client';

export function getOidcConfig(): OpenIdConfiguration {
  const domain = window.location.origin;

  const logging = localStorage.getItem('logLevelDebug') === 'true';

  const config: OpenIdConfiguration = {
    authority: environment.identityApiURL,
    redirectUrl: `${domain}/callback`,
    clientId: environment.clientId,
    responseType: 'code',
    // resource: 'partyAPI',
    scope: 'openid profile partyAPI platformAPI',
    // hd_param: string,
    postLogoutRedirectUri: domain,
    startCheckSession: true,
    silentRenew: true,
    silentRenewUrl: `${domain}/silent-renew.html`,
    disableIdTokenValidation: false,
    // silent_renew_offset_in_seconds:  60,
    // silent_redirect_url: string,
    postLoginRoute: '/membership/loading',
    // HTTP 403
    forbiddenRoute: '/forbidden',
    // HTTP 401
    unauthorizedRoute: '/signinerror',
    // auto_userinfo: boolean,
    // auto_clean_state_after_authentication: boolean,
    // trigger_authorization_result_event: boolean,
    logLevel: environment.authLogConsoleDebugActive || logging ? LogLevel.Debug : environment.authLogConsoleWarningActive
      ? LogLevel.Warn : LogLevel.Error,
    disableIatOffsetValidation: environment.disableIatOffsetValidation,
    maxIdTokenIatOffsetAllowedInSeconds: environment.authMaxTokenOffset,
    // storage: any,
    authWellknownEndpoints: getWellKnownEndpoints()
  };

  return config;
}

export function getWellKnownEndpoints(): AuthWellKnownEndpoints {
  const endpoints: AuthWellKnownEndpoints = {
    issuer: environment.identityApiURL,
    jwksUri: environment.identityApiURL + `/.well-known/openid-configuration/jwks`,
    authorizationEndpoint: environment.identityApiURL + `/connect/authorize`,
    tokenEndpoint: environment.identityApiURL + `/connect/token`,
    userInfoEndpoint: environment.identityApiURL + `/connect/userinfo`,
    endSessionEndpoint: environment.identityApiURL + `/connect/endsession`,
    checkSessionIframe: environment.identityApiURL + `/connect/checksession`,
    revocationEndpoint: environment.identityApiURL + `/connect/revocation`,
    introspectionEndpoint: environment.identityApiURL + `/connect/introspect`,
  };

  return endpoints;
}
