<div class="container-fluid">
    <div class="row text-center mt-4">
        <div class="col-12 heading-30-bold-zing-blue">
          Terms of Use
        </div>
    </div>
    <div class="row">
      <div class="col-lg-10 main-section">
        <div class="form-container"> 
          <div class="row tnc-panel">
            <div class="col-12">
  
              <div class="tnc-body">
                <iframe id="pdfWrapper" [src]="sanitizedUrl"  frameborder="0" type="application/pdf" width="100%" height="100%"></iframe>  
              </div>
              <div class="row">
                <div class="col-12">
                  <a [href]="sanitizedUrl" class="btn btn-md btn-zing-blue download-url" download>
                    Download
                  </a>
                </div>
                <div class="col-12 text-center">
  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
