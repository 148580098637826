import { Action } from '@ngrx/store';
import { Company } from '../models/company.model';
import { CompanyLocation } from '../models/location.model';
import { BusinessUnit } from '../models/business-unit-model';

export enum CompanyActionTypes {
  LoadCompany                = '[Company] Load Company',
  LoadCompanySuccess         = '[Company] Load Company Success',
  LoadCompanyFail            = '[Company] Load Company Fail',
  LoadLocation               = '[Company] Load Location',
  LoadLocationSuccess        = '[Company] Load Location Success',
  LoadLocationFail           = '[Company] Load Location Fail',
  LoadAllLocations           = '[Company] Load All Locations',
  LoadAllLocationsSuccess    = '[Company] Load All Locations Success',
  LoadAllLocationsFail       = '[Company] Load All Locations Fail',
  LoadBusinessUnits          = '[Company] Load Business Units',
  LoadBusinessUnitsSuccess   = '[Company] Load Business Units Success',
  LoadBusinessUnitsFail      = '[Company] Load Business Units Fail',
  UpdateBusinessUnits        = '[Company] Update Business Units',
  UpdateBusinessUnitsSuccess = '[Company] Update Business Units Success',
  UpdateBusinessUnitsFail    = '[Company] Update Business Units Fail'
}

export class LoadCompany implements Action {
  readonly type = CompanyActionTypes.LoadCompany;
}

export class LoadCompanySuccess implements Action {
  readonly type = CompanyActionTypes.LoadCompanySuccess;
  constructor(public payload: { company: Company }) { }
}

export class LoadCompanyFail implements Action {
  readonly type  = CompanyActionTypes.LoadCompanyFail;
  constructor(public payload: any) { }
}

export class LoadLocation implements Action {
  readonly type = CompanyActionTypes.LoadLocation;
  constructor(public payload: { companyId: string, locationId: string }) { }
}

export class LoadLocationSuccess implements Action {
  readonly type = CompanyActionTypes.LoadLocationSuccess;
  constructor(public payload: { location: CompanyLocation }) { }
}

export class LoadLocationFail implements Action {
  readonly type  = CompanyActionTypes.LoadLocationFail;
  constructor(public payload: any) { }
}

export class LoadAllLocations implements Action {
  readonly type = CompanyActionTypes.LoadAllLocations;
  constructor(public payload: { companyId: string }) { }
}

export class LoadAllLocationsSuccess implements Action {
  readonly type = CompanyActionTypes.LoadAllLocationsSuccess;
  constructor(public payload: { locations: Array<CompanyLocation> }) { }
}

export class LoadAllLocationsFail implements Action {
  readonly type  = CompanyActionTypes.LoadAllLocationsFail;
  constructor(public payload: any) { }
}

export class LoadBusinessUnits implements Action {
  readonly type = CompanyActionTypes.LoadBusinessUnits;
  constructor(public payload: { companyId: string }) { }
}

export class LoadBusinessUnitsSuccess implements Action {
  readonly type = CompanyActionTypes.LoadBusinessUnitsSuccess;
  constructor(public payload: { businessUnits: Array<BusinessUnit> }) { }
}

export class LoadBusinessUnitsFail implements Action {
  readonly type  = CompanyActionTypes.LoadBusinessUnitsFail;
  constructor(public payload: any) { }
}

export class UpdateBusinessUnits implements Action {
  readonly type = CompanyActionTypes.UpdateBusinessUnits;
  constructor(public payload: { companyId: string, businessUnits: Array<BusinessUnit> }) { }
}

export class UpdateBusinessUnitsSuccess implements Action {
  readonly type = CompanyActionTypes.UpdateBusinessUnitsSuccess;
}

export class UpdateBusinessUnitsFail implements Action {
  readonly type = CompanyActionTypes.UpdateBusinessUnitsFail;
  constructor(public payload: any) { }
}

export type CompanyActions = LoadCompany
| LoadCompanySuccess
| LoadCompanyFail
| LoadLocation
| LoadLocationSuccess
| LoadLocationFail
| LoadAllLocations
| LoadAllLocationsSuccess
| LoadAllLocationsFail
| LoadBusinessUnits
| LoadBusinessUnitsSuccess
| LoadBusinessUnitsFail
| UpdateBusinessUnits
| UpdateBusinessUnitsSuccess
| UpdateBusinessUnitsFail;
