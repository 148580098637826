<div>
    <div class="modal-header">
        <h2 class="heading-20-bold-primary modal-title">
        {{ 'Labels.IdleMessage' | translate }}
        </h2>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="continueSession()">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-footer px-2em d-flex flex-column">
        <button class="btn-md btn-primary-purple text-uppercase mr-0 mb-1em" id="submit"
        (click)="continueSession()">
        {{ 'Labels.ContinueSession' | translate }}
        </button>
        <button class="btn-md btn-secondary-white text-uppercase m-0" (click)="logout()" id="cancel">
        {{ 'Labels.LogoutButton' | translate }}
        </button>
    </div>
</div>