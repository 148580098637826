<div class="modal-header">
  <h4 class="modal-title">{{ title }}</h4>
  <button type="button" class="close" (click)="bsModalRef.hide()" aria-hidden="true">×</button>
</div>

<div class="modal-body">
  <p>{{ content }}</p>
</div>

<div class="modal-footer mt-1rem">
  <button type="button" class="btn btn-light" (click)="bsModalRef.hide()">
    {{ 'Labels.PaymentDetailsPage.Reject' | translate }}
  </button>
  <button type="button" class="btn btn-primary" (click)="termsAccepted = true; bsModalRef.hide()">
    {{ 'Labels.PaymentDetailsPage.Accept' | translate }}
  </button>
</div>
