import { TwoFactorState, twoFAReducer } from './reducers/two-factor-auth.reducer';
import { ActionReducerMap } from '@ngrx/store';
import { RegisterState, registerReducer } from '../modules/guest/modules/register/store/reducers/register.reducer';
import { TeamState, teamReducer } from '../modules/membership/modules/account/store/team.reducer';
import { CompanyState, companyReducer } from '../modules/membership/modules/account/store/company.reducer';
import { ShippingState, shippingReducer } from '../modules/membership/modules/shipping/store/reducers/shipping.reducer';
import { billingReducer, BillingState } from '../modules/membership/modules/billing/store/reducers/billing.reducer';
import { PaymentState, paymentReducer } from '../modules/membership/modules/account/store/payment.reducer';
import { MarketplaceState, marketplaceReducer } from '../modules/marketplace/store';
import { ShoppingCartState, shoppingCartReducer } from '../modules/marketplace/shopping-cart/store';

export interface ZingState {
  twoFactorState: TwoFactorState;
  register: RegisterState;
  team: TeamState;
  company: CompanyState;
  shipping: ShippingState;
  billing: BillingState;
  payment: PaymentState;
  marketplace: MarketplaceState;
  shoppingCart: ShoppingCartState;
}

const reducers = {
  twoFactorState: twoFAReducer,
  register: registerReducer,
  team: teamReducer,
  company: companyReducer,
  shipping: shippingReducer,
  billing: billingReducer,
  payment: paymentReducer,
  marketplace: marketplaceReducer,
  shoppingCart: shoppingCartReducer
};

export const storeReducers: ActionReducerMap<ZingState> = reducers;
