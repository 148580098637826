export const SELECT_ORDER_STATUSES = [
  { value: 'default', label: 'Select Status' },
  { value: 'Ordered', label: 'Ordered' },
  { value: 'Funded', label: 'Funded' },
  { value: 'In Transit', label: 'In Transit' },
  { value: 'Delivered', label: 'Delivered' },
  { value: 'Cancelled', label: 'Cancelled' }
];


export const ORDER_STATUSES = [
  {
    label: 'Ordered',
    value: 'Ordered',
    width: '0%'
  },
  {
    label: 'Funded',
    value: 'Funded',
    width: '33%'
  },
  {
    label: 'In Transit',
    value: 'In Transit',
    width: '66%'
  },
  {
    label: 'Delivered',
    value: 'Delivered',
    width: '99%'
  }
];

export const ORDER_CANCELLED_STATUS = 'Cancelled';
