import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormModalConfig, defaultFormModalConfig } from '../models/modal-config';

@Component({
  selector: 'z-form-modal',
  templateUrl: './form-modal.component.html',
  styleUrls: ['./form-modal.component.scss']
})
export class FormModalComponent implements OnInit, OnDestroy {
  componentActive = true;

  @Output() continue = new EventEmitter<any>();
  @Output() cancel = new EventEmitter<void>();

  config: FormModalConfig = defaultFormModalConfig;
  formGroup: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
    this.createForm();
  }

  ngOnDestroy(): void {
    this.formGroup.reset();
    this.componentActive = false;
  }

  createForm(): void {
    this.formGroup = this.formBuilder.group({});
    this.config.controls.forEach(controlConfig =>
      this.formGroup.addControl(controlConfig.controlName, controlConfig.formControl)
    );
  }

  onAgree() {
    this.continue.emit(this.formGroup.value);
  }

  onCloseModal() {
    this.bsModalRef.hide();
  }

  /**
   * Checks control for any type of validation error
   */
  hasError(ctrl: AbstractControl): boolean {
    return ctrl.invalid && (ctrl.dirty || ctrl.touched);
  }

  /**
   * Checks control for expected type of validation error
   */
  hasErrorOfType(ctrl: AbstractControl, error: string | string[]): boolean {
    if (Array.isArray(error)) {
      return this.hasErrors(ctrl, error);
    }

    return ctrl.invalid && (ctrl.dirty || ctrl.touched) && ctrl.hasError(error);
  }

  setErrors(ctrlName: string, errors: ValidationErrors) {
    this.formGroup.get(ctrlName).setErrors(errors);
  }

  /**
   * Checks control for expected type of validation errors
   * Returns true if any error is found
   */
  private hasErrors(ctrl: AbstractControl, errors: string[]): boolean {
    return ctrl.invalid && (ctrl.dirty || ctrl.touched) && errors.some(error => ctrl.hasError(error));
  }
}
