import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { ODataQueryModel } from '../models/odata-query-model';

@Injectable({
  providedIn: 'root'
})
export class RiskService {

  private riskApiUrl = environment.riskApiURL + '/api';
  private dataQuery: ODataQueryModel;
  private filter = {
    filter: {},
    top: 0,
    skip: 0
  };

  constructor(
    private http: HttpClient
  ) { }

  searchCompaniesByDetails(companyDetails: {
    feinId?: string,
    companyName?: string,
    city?: string,
    state?: string
  }) {


    if (companyDetails.feinId) {
      this.dataQuery = {
        filter: `taxId eq '${companyDetails.feinId}'`
      };

    } else {
      const { companyName, city, state } = companyDetails;
      this.dataQuery = {
        filter: `startswith(businessname, '${companyName}') and startswith(city, '${city}') and state eq '${state}'`
      };

    }

    return this.http.post<any>(`${this.riskApiUrl}/Businesses/search`, this.dataQuery);
  }

  searchCompaniesRegistration(companyDetails) {
    const data = { ...this.filter, filter: companyDetails };
    return this.http.post<any>(`${this.riskApiUrl}/Businesses/search`, data);
  }
}
