export const ListOfSupportedBrowsers = {
  os: [
    'Windows Phone',
    'Windows',
    'macOS',
    'iOS',
    'Android',
    'BlackBerry',
    'Linux',
    'Chrome OS'
  ],
  browsers: [
    'Chrome',
    'Firefox',
    'Safari',
    'Microsoft Edge',
    'Opera',
    'Googlebot',
    'Mozilla'
  ],
  minversions: {
    'chrome': '>=66',
    'firefox': '>=60',
    'safari': '>=11',
    'edge': '>=84',
    'opera': '>=55',
    'googlebot': '>=1',
    'mozilla': '>=5'
  }
};
