import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject, of, forkJoin, BehaviorSubject } from 'rxjs';
import { Company } from '../../membership/modules/account/models/company.model';
import { BaseHttpService } from './base-http.service';
import { environment } from '../../../../environments/environment';
import { BusinessServiceType } from '../enums/business-service-type';
import { delayWhen, map, catchError, tap } from 'rxjs/operators';
import { PaymentMethodService } from '../../membership/modules/account/services/payment-method.service';
import { ShippingUserDataService } from '../../membership/services/shipping-user-data.service';
import { AccountUserDataService } from '../../membership/services/account-user-data.service';
import { AnalyticsService } from './analytics.service';
import { MarketplacePartnerAffiliationType } from '../enums/marketplace-partner-affiliation-types';
import { MarketplacePartnerAffiliation } from '../models/marketplace-partner-affiliation';
import { ApprovalStatus } from '../../membership/modules/account/enums/approval-status';
import { RegistrationSource } from '../../membership/modules/account/models/person-source.enum';
import { RequestAccessResponseModel } from '../../membership/modules/shipping/models/request-access-model';

export class AlertType {
  constructor(public type: string, public hasAlert: boolean, public alertContent: string) {
  }
}

@Injectable()
export class CompanyService extends BaseHttpService<Company> {

  get companyDetails(): Company {
    return this.company;
  }

  get companyExists(): boolean {
    return typeof this.companyDetails !== 'undefined' && this.companyDetails !== null;
  }

  get hasAppraisal(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.Appraisal);
  }

  get hasPendingAppraisal(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionPending(BusinessServiceType.Appraisal);
  }

  get hasPointOfSaleIntegration(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.PointOfSaleIntegration);
  }

  get hasCarePlan(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.CarePlan);
  }

  get hasCarePlanInactive(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionInactive(BusinessServiceType.CarePlan);
  }

  get hasActivePosSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.PointOfSaleIntegration);
  }

  get hasInactivePosSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionInactive(BusinessServiceType.PointOfSaleIntegration);
  }

  get hasPendingPosSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionPending(BusinessServiceType.PointOfSaleIntegration);
  }

  // this will return true if user has subscription.  The subscription can be Active or Inactive
  // This only checks if the company subscribed to Care Plan,
  get hasCarePlanSubscription(): boolean {
    return this.companyExists && this.companyDetails.hasServiceSubscription(BusinessServiceType.CarePlan);
  }

  get hasShippingSubscription(): boolean {
    return this.companyExists && this.companyDetails.hasServiceSubscription(BusinessServiceType.Shipping);
  }

  get hasShipping(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.Shipping);
  }

  get shippingHasBeenDeactivated(): boolean {
    return this.companyDetails.subscriptions.some(sub => sub.serviceType == BusinessServiceType.Shipping &&
      sub.approvalStatus === ApprovalStatus.Rejected || sub.activeUntil != null && sub.isActive === false);
  }

  get hasMarketplace(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.MarketPlace);
  }

  get hasInactiveMarketplace(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionInactive(BusinessServiceType.MarketPlace);
  }

  get hasStudio(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.Studio);
  }

  get hasMarketPlaceSubscription(): boolean {
    return this.companyExists && this.companyDetails.hasServiceSubscription(BusinessServiceType.MarketPlace);
  }

  get hasBusinessInsurance(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.CommercialInsurance);
  }

  // this will return true if user has subscription.  The subscription can be Active or Inactive
  // This only checks if the company subscribed to Business Insurance,
  get hasBusinessInsuranceSubscription(): boolean {
    return this.companyExists && this.companyDetails.hasServiceSubscription(BusinessServiceType.CommercialInsurance);
  }

  get hasPersonalInsurance(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.PersonalInsurance);
  }

  get hasCustomService(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.Custom);
  }

  get hasPartnerGateway(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.PartnerGateway);
  }

  get hasOnlyPartnerGateway(): boolean {
    return this.companyExists
      && this.companyDetails.isSubscriptionActive(BusinessServiceType.PartnerGateway)
      && this.companyDetails.subscriptions.filter(s => s.isActive && s.approvalStatus === ApprovalStatus.Approved).length === 1;
  }

  get hasBusinessInsuranceSubscriptionPending(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionPending(BusinessServiceType.CommercialInsurance);
  }

  get hasCarePlanSubscriptionPending(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionPending(BusinessServiceType.CarePlan);
  }

  get isAppraisalSubscriptionInactive(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionInactive(BusinessServiceType.Appraisal);
  }
  get hasMarketPlaceSubscriptionPending(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionPending(BusinessServiceType.MarketPlace);
  }

  get isCompanyFromQuebec(): boolean {
    return this.companyExists &&
      this.hasCompanyAddress() &&
      (this.companyDetails.address.countryId === 'CA' || this.companyDetails.address.countryId === 'CAN') &&
      this.companyDetails.address.stateId === 'QC';
  }

  get hasMemoSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.Memo);
  }

  get hasActiveJewelerPagesSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.JewelerPages);
  }

  get hasInactiveJewelerPagesSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionInactive(BusinessServiceType.JewelerPages);
  }

  get hasAuctionSubscription(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionActive(BusinessServiceType.Auction);
  }

  get hasInactiveBusinessInsurance(): boolean {
    return this.companyExists && this.companyDetails.isSubscriptionInactive(BusinessServiceType.CommercialInsurance);
  }

  get isCanadaCompany(): boolean {
    const country = this.company.address?.countryId;
    return country === 'CAN' || country === 'CA';
  }

  public companyLoaded = new BehaviorSubject<boolean>(false);

  public companyApiDomain = environment.membershipApiURL + '/api/companies';
  public company: Company;

  public addressAdded = new Subject<boolean>();
  constructor(
    private http: HttpClient,
    private shippingUserDataService: ShippingUserDataService,
    private accountUserDataService: AccountUserDataService,
    private paymentMethodService: PaymentMethodService,
    private analyticsService: AnalyticsService
  ) {
    super(http);
    this.apiDomain = environment.membershipApiURL;
    this.endpoint = 'companies';
    this.alert = new AlertType('', false, '');
  }

  /**
   * Alert flag for alert component which will be triggered from other components.
   */
  public alert: AlertType;

  /**
   * Make http request for specific item
   *
   * @param refId Reference Id of the user logged in
   */
  getCompanyProfile(forceLoad: boolean = false): Observable<Company> {
    if (!this.company || forceLoad) {
      return this.http.get<Company>(`${this.getFullEndpointUrl()}/profile`)
        .pipe(
          map(res => new Company(res)),
          tap(company => {
            this.company = company;
            if (!!company.analyticsDeactivated) {
              this.analyticsService.disableAnalytics();
            }
            this.companyLoaded.next(true);
          }),
          delayWhen(company => {
            const ignoreFailRequest = catchError(() => of(null));
            const requests: Observable<any>[] = [this.accountUserDataService.loadUserData().pipe(ignoreFailRequest)];
            if (company.isSubscriptionActive(BusinessServiceType.Shipping)) {
              requests.push(this.paymentMethodService.fetch(company.companyId).pipe(ignoreFailRequest));
              requests.push(this.shippingUserDataService.loadUserData().pipe(ignoreFailRequest));
            }
            return forkJoin(requests);
          })
        );
    } else {
      return of(this.company);
    }
  }

  /**
   * Validates FEIN Id value.
   *
   * @param feinId FEIN Id of the Company.
   */
  validateFeinId(feinId: string) {
    return this.http
      .get<{
      federalTaxIdentificationNumber: string;
      feinIdExists: boolean;
    }>(`${this.companyApiDomain}/ValidateFeinId?feinId=${feinId}`);
  }

  /**
   * Updates person with company relationship.
   *
   * @param company Company to associate person with.
   */
  associatePersonWithCompany(company) {
    return this.http.post<boolean>(`${this.getFullEndpointUrl()}/associateCompany`, company);
  }

  hasCompanyAddress() {
    return this.company && this.company.address && !!this.company.address.addressLine1;
  }

  isCarePlanJeweler(): boolean {
    return this.company && this.company.isCarePlanJeweler();
  }
  isCarePlanRepairCenter(): boolean {
    return this.company && this.company.isCarePlanRepairCenter();
  }

  getParnetAffiliation(affiliationType: MarketplacePartnerAffiliationType): MarketplacePartnerAffiliation {
    if (this.companyExists) {
      return this.companyDetails.getPartnerAffiliation(affiliationType);
    }

    return undefined;
  }

  hasShipmentPreference(): boolean {
    const company = this.companyDetails;
    const shipmentPreference = company.subscriptions.find(sub => sub.serviceType === BusinessServiceType.Shipping &&
        (sub.preferredShipmentProviders || sub.submitterRegistrationId) && sub.avgShipmentsPerWeek.length > 0);

    return !!shipmentPreference || company.companySource === RegistrationSource.IShipJM;
  }

  getCompanyLogo() {
    return this.http.get(`${this.getFullEndpointUrl()}/${this.company.id}/logos`, {responseType: 'blob'});
  }

  uploadCompanyLogo(formData: FormData): Observable<Blob> {
    return this.http.post<Blob>(`${this.getFullEndpointUrl()}/${this.company.id}/logos`, formData, {
      headers: new HttpHeaders({
        uploadFile: 'true'
      }),
      responseType: 'text' as 'json'
    });
  }

  /**
   * Requests shipping for BA user and user's company
   *
   * @param companyId User's company ID
   */
  public requestShipping(companyId: string): Observable<RequestAccessResponseModel> {
    return this.http.put<RequestAccessResponseModel>(`${this.getFullEndpointUrl()}/requestshipping/${companyId}`, null);
  }
}

