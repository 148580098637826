import { Injectable } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { GuidRegex } from '../../../utils/regex';
import { CompanyService } from './company.service';
declare let gtag: Function;

@Injectable()
export class GoogleAnalyticsService {
  constructor(private router: Router,
    private companyService: CompanyService) {
  }

  public startPageTracking() {
      this.addScripts();
      this.listenForRouteChanges();
  }

  private listenForRouteChanges() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const pageUrl = this.formatUrl(event.urlAfterRedirects);

        if (pageUrl.length) {
          this.sendTrackEvent(pageUrl);
        }
      }
    });
  }

  private addScripts() {
    try {
      const script1 = document.createElement('script');
      script1.async = true;
      script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + environment.googleAnalyticsKey;
      document.head.appendChild(script1);

      const script2 = document.createElement('script');
      script2.innerHTML = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
            `;
      document.head.appendChild(script2);
    } catch (ex) {
      console.error(ex);
    }
  }

  private sendTrackEvent(pageUrl: string) {
    if (this.companyService.companyExists && !!this.companyService.companyDetails.analyticsDeactivated) {
      // do not send google analytics
      return;
    }
    try {
      gtag('config', environment.googleAnalyticsKey, { 'page_path': pageUrl });
    } catch (ex) {
      console.error(ex);
    }
  }

  private formatUrl(url: string) {
    if (typeof (url) === 'undefined' || !url || url.includes('loading')
     || url.includes('callback') || url.includes('login')) { // Do not track loading pages
      return '';
    }

    return url.split('#') // Keep fragments
      .map(hashPart => url.indexOf(hashPart) !== 0 ? hashPart : hashPart.split('?')
        .map(queryPart => url.indexOf(queryPart) === 0 ? queryPart : this.formatQueryParams(queryPart))
        .join('?')
        .split('/')
        .filter(urlPart => !urlPart.match(GuidRegex)) // Remove guids from url
        .join('/'))
      .join('#');
  }

  private formatQueryParams(params: string) {
    // Remove all query params except 'layout'
    return params.split('&').map(param => param.split('=')[0].includes('layout') ? param : '').join('');
  }
}
