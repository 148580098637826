import { ShipmentAddress } from '../modules/membership/modules/account/models/shipment-address.model';
import { ServiceType, ServiceOptionType } from '../modules/membership/modules/shipping/enums/service-type';
import { Contact } from '../modules/membership/modules/shipping/models/base-shipment-models';
import { CarrierType } from '../modules/membership/modules/shipping/models/shipment';
import { AlphaNumericDashGlobalRegex } from './regex';

export interface PhoneNumber {
  countryCode: string;
  number: string;
}

export function capitalize(s: string) {
  if (typeof s !== 'string') { return ''; }
  return s.charAt(0).toUpperCase() + s.slice(1);
}

export function formatPhoneNumber(
  phoneNumber: string,
  forDisplay = true
): PhoneNumber | string {

  if (!phoneNumber) { return ''; }

  phoneNumber = phoneNumber.trim();

  let countryCode = '';
  let number = '';

  const parts = phoneNumber.split('-');

  if (parts.length === 2) {
    countryCode = parts[0];
    number = parts[1];
  } else if (phoneNumber.startsWith('+1', 0)) {
    countryCode = phoneNumber.substr(0, 2);
    number = phoneNumber.substr(2);
  } else {
    countryCode = '';
    number = phoneNumber;
  }
  if (!forDisplay) {
    return {
      countryCode,
      number
    };
  }

  if (number.length <= 3) {
    return `${countryCode} (${number})`;
  }

  const areaCode = number.substr(0, 3);
  const firstPart = number.substr(3, 3);
  const rest = number.substr(6);

  let formated = `${countryCode} (${areaCode}) `;

  if (firstPart) {
    formated += firstPart;
  }

  if (rest) {
    formated += `-${rest}`;
  }

  return formated;
}

export function getTextForSearchResult(name: string, address: any) {
  let retValue = name;
  retValue += ', ' + [
    address.addressLine1,
    address.addressLine2,
    address.city,
    address.stateId,
    address.postalCode
  ]
    // From address details get only the values which are not empty
    .filter(addressPart => addressPart && addressPart.length > 0)
    .join(', ');
  return retValue;
}

/**
 * Map ServicesTypes to id and name objects
 *
 * @param services List of ServiceTypes that are chosen
 */
export function mapServiceTypesIntoGroups(
  services: Array<ServiceType> = []
): Array<{ id: string; name: string }> {
  let groups = [];

  services.forEach(s => {
    if (s === ServiceType.NextDayAM ||
      s === ServiceType.NextDayEarlyAM ||
      s === ServiceType.NextDayPM) {
      groups = [...groups, { id: ServiceOptionType.NextDay, name: 'Next Day' }];
    }
    if (s === ServiceType.TwoDayAM ||
      s === ServiceType.TwoDayPM ||
      s === ServiceType.ThirdDay) {
      groups = [...groups, { id: ServiceOptionType.TwoThreeDays, name: '2-3 Days' }];
    }
    if (s === ServiceType.GroundOrFirstClass) {
      groups = [...groups, { id: ServiceOptionType.Ground, name: 'Ground/1st Class' }];
    }
  });

  return groups;
}

export function mapGroupIntoServiceTypes(group) {

  let serviceTypes = [];
  if (group.id === ServiceOptionType.NextDay) {
    serviceTypes = [ServiceType.NextDayAM, ServiceType.NextDayEarlyAM, ServiceType.NextDayPM];
  } else if(group.id === ServiceOptionType.TwoThreeDays) {
    serviceTypes = [ServiceType.TwoDayAM, ServiceType.TwoDayPM, ServiceType.ThirdDay];
  } else {
    serviceTypes = [ServiceType.GroundOrFirstClass];
  }

  return serviceTypes;
}

/**
 * Map CarrierTypes to id and name objects
 *
 * @param carriers List of CarrierTypes that are chosen
 */
export function mapCarriersTypesIntoGroups(
  carriers: Array<CarrierType> = []
): Array<{ id: string; name: string }> {
  let groups = [];

  carriers.forEach(s => {
    if (s === CarrierType.FedEx) {
      groups = [...groups, { id: '1', name: 'FedEx', type: CarrierType.FedEx }];
    }
    if (s === CarrierType.USPS) {
      groups = [...groups, { id: '2', name: 'USPS', type: CarrierType.USPS }];
    }
    if (s === CarrierType.UPS) {
      groups = [...groups, { id: '3', name: 'UPS', type: CarrierType.UPS }];
    }
  });

  return groups;
}

/**
 * Map list of names to list of CarrierTypes
 *
 * @param carriers List of carrier type names
 */
export function mapCarrierNamesIntoCarrierTypes(
  carriers: string[]
): Array<CarrierType> {
  let carrierTypes = [];

  carriers.forEach(s => {
    if (s === 'FedEx' || s === 'All') {
      carrierTypes = [...carrierTypes, CarrierType.FedEx];
    }
    if (s === 'USPS' || s === 'All') {
      carrierTypes = [...carrierTypes, CarrierType.USPS];
    }
    if (s === 'UPS' || s === 'All') {
      carrierTypes = [...carrierTypes, CarrierType.UPS];
    }
  });

  return carrierTypes;
}

export function formatValueWithTwoDecimals(value: number | string) {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return null;
  }

  return (+value).toFixed(2);
}

export function formatValueWithCustomDecimals(value: number | string, decimals: number) {
  if (typeof value !== 'number' && typeof value !== 'string') {
    return null;
  }

  return (+value).toFixed(decimals);
}

export function limitValueToCustomLengthBeforeDecimalSeparator(value: string, beforeSeparator: number, separator: string = '.') {
  if (value) {
    const length = String(value).length;

    if (length > 3 && String(value).indexOf(separator) < 0) {
      value = value.substring(0, beforeSeparator) + '.' + value.substring(3, value.length);
    }
  }

  return value;
}

/**
 * Transform email addresses so that most of the
 * email address is masked
 * If email address has two letters mask the first one,
 * If it has one letter mask that letter
 * If it has more than two mask everyhting between first and last
 * example: a****t@m********r.com, *a@*a.com, *@m********r.com
 */
export function maskEmail(email: string | undefined): string {
  if (!email) {
    return '';
  }
  const emailParts: string[] = email.split('@');
  if (emailParts.length !== 2) {
    return '';
  }
  const emailPart = emailParts[0];
  const domainParts: string[] = emailParts[1].split('.');

  return `${maskString(emailPart)}@${maskString(domainParts[0])}.${domainParts[1]}`;
}

/**
 * Return masked string
 * example: a*****a, *a, *
 */
function maskString(value: string) {
  if (value.length > 2) {
    return `${value[0]}${'*'.repeat(value.length - 2)}${value[value.length - 1]}`;
  } else if (value.length === 2) {
    return `*${value[1]}`;
  } else {
    return '*';
  }
}

/**
 * Return full address details from contact model (in html format)
 */
export function getOneLineContactAddressMarkup(contact: Contact, displayCountry = true) {
  const address = [contact.addressLine1, contact.addressLine2].filter(el => !!el).join(' ');
  const address2 = `${[contact.city, contact.state].filter(el => !!el).join(', ')} ${contact.postalCode ? contact.postalCode : ''}`;
  const description = [address, address2, displayCountry ? contact.countryCode : ''].filter(el => !!el.trim()).join('<br/>');
  return description;
}

export function getOneLineContactAddress(contact: Contact, displayCountry = true) {
  const contnactInfo = [contact.company, contact.firstName, contact.lastName].filter(el => !!el).join(' ');
  const address = [contact.addressLine1, contact.addressLine2].filter(el => !!el).join(' ');
  const address2 = `${[contact.city, contact.state].filter(el => !!el).join(', ')} ${contact.postalCode ? contact.postalCode : ''}`;
  const description = [contnactInfo, address, address2, displayCountry ? contact.countryCode : ''].filter(el => !!el.trim()).join('<br/>');
  return description;
}

export function getOneLineAddress(contact: ShipmentAddress, displayCountry = true): string {
  const address = [contact.addressLine1, contact.addressLine2].filter(el => !!el).join(' ');
  const address2 = `${[contact.city, contact.state, contact.postalCode].filter(el => !!el).join(', ')}`;
  const description = [address, address2, displayCountry ? contact.countryCode : ''].filter(el => !!el.trim()).join('<br/>');
  return description;
}

export function getQuotesOneLineAddress(contact: Contact, displayCountry = false) {
  const contnactInfo = [contact.firstName, contact.lastName].filter(el => !!el).join(' ');
  const address = [contact.addressLine1, contact.addressLine2].filter(el => !!el).join(' ');
  const address2 = `${[contact.city, contact.state].filter(el => !!el).join(', ')} ${contact.postalCode ? contact.postalCode : ''}`;
  const description = [contact.company, contnactInfo, address, address2, displayCountry ? contact.countryCode : ''].filter(el => !!el.trim()).join('<br/>');
  return description;
}

/**
 * Formats and returns input string without blank spaces, all lower-case
 */
export function formatJewelerPagesCustomerUrl(url: string): string {

  if(!!url) {
    url = url.trim();
    url = url.replace(AlphaNumericDashGlobalRegex, '');
    url = url.toLowerCase();
  }

  return url;
}
