import { PaymentIntentStatus } from './payment-intent-status';

export enum PaymentMethodType {
  CreditCard = 0,
  ACH = 1,
  WireTransfer = 2
}

export const PaymentMethodNames = {
  [PaymentMethodType.CreditCard]: 'Credit Card',
  [PaymentMethodType.ACH]: 'ACH',
  [PaymentMethodType.WireTransfer]: 'Wire Transfer'
};

export const PaymentStatusNames = {
  [PaymentIntentStatus.processing]: 'Processing',
  [PaymentIntentStatus.succeeded]: 'Paid',
  [PaymentIntentStatus.requires_payment_method]: 'Failed'
};

export const PaymentMethodCodeToPaymentMethod = {
  'WT': {
    name: 'Wire Transfer',
    type: PaymentMethodType.WireTransfer
  },
  'ACH': {
    name: 'ACH',
    type: PaymentMethodType.ACH
  },
  'CC': {
    name: 'Credit Card',
    type: PaymentMethodType.CreditCard
  }
};

export const PaymentStatusCodeToName = {
  'AC': 'Processing',
  'SD': 'Paid',
  'SP': 'Paid',
  'FL': 'Failed'
};
