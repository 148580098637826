import { createFeatureSelector, createSelector } from '@ngrx/store';

import * as fromRoot from '../../../../../store/root.state';
import { ShoppingCartActions, ShoppingCartActionTypes } from '../actions/shopping-cart.actions';

import { OrderInformation, OrderLocation, PaymentInformation } from '../../../../order/models';
import { ShippingMethodType } from '../../../../shopping-cart/models/shipping-method-type';
import { ShoppingCart } from '../../models';

export interface ZingState extends fromRoot.ZingState {
  shoppingCart: ShoppingCartState;
}

export interface ShoppingCartState {
  shoppingCart: ShoppingCart;
  shoppingCartLoading: boolean;
  isAddToShoppingCartLoading: boolean;
  removeFromCartError: any;
  error?: any;

  checkoutLoading: boolean;
  orderLocation: OrderLocation;
  shippingMethod: ShippingMethodType;
  order: OrderInformation;
  paymentInformation: PaymentInformation;
  checkoutError?: any;
}

export const initialState: ShoppingCartState = {
  shoppingCart: null,
  shoppingCartLoading: false,
  isAddToShoppingCartLoading: false,
  removeFromCartError: null,
  error: null,
  checkoutLoading: false,
  orderLocation: null,
  shippingMethod: null,
  order: null,
  checkoutError: null,
  paymentInformation: null
};

const getShoppingCartState = createFeatureSelector<ShoppingCartState>('shoppingCart');

export const getShoppingCart = createSelector(
  getShoppingCartState,
  state => state.shoppingCart
);

export const getOrderLocation = createSelector(
  getShoppingCartState,
  state => state.orderLocation
);

export const getShippingMethod = createSelector(
  getShoppingCartState,
  state => state.shippingMethod
);

export const getPaymentInformation = createSelector(
  getShoppingCartState,
  state => state.paymentInformation
);

export const getShoppingCartLoading = createSelector(
  getShoppingCartState,
  state => state.shoppingCartLoading
);

export const getError = createSelector(
  getShoppingCartState,
  state => state.error
);

export const getRemoveFromCartError = createSelector(
  getShoppingCartState,
  state => state.removeFromCartError
);

export const getIsAddToShoppingCartLoading = createSelector(
  getShoppingCartState,
  state => state.isAddToShoppingCartLoading
);

export const getCheckoutLoading = createSelector(
  getShoppingCartState,
  state => state.checkoutLoading
);

export const getOrder = createSelector(
  getShoppingCartState,
  state => ({
    location: state.orderLocation,
    order: state.order,
    paymentInformation: state.paymentInformation
  })
);

export const getCheckoutError = createSelector(
  getShoppingCartState,
  state => state.checkoutError
);

export function shoppingCartReducer(state = initialState, action: ShoppingCartActions): ShoppingCartState {
  switch (action.type) {
    case ShoppingCartActionTypes.GetShoppingCart:
      return {
        ...state,
        shoppingCartLoading: true
      };
    case ShoppingCartActionTypes.GetShoppingCartSuccess:
      return {
        ...state,
        shoppingCartLoading: false,
        shoppingCart: action.payload,
        error: null
      };
    case ShoppingCartActionTypes.GetShoppingCartFail:
      return {
        ...state,
        shoppingCartLoading: false,
        shoppingCart: null,
        error: action.payload
      };
    case ShoppingCartActionTypes.AddToShoppingCart:
      return {
        ...state,
        isAddToShoppingCartLoading: true
      };
    case ShoppingCartActionTypes.AddToShoppingCartSuccess:
      return {
        ...state,
        isAddToShoppingCartLoading: false,
      };
    case ShoppingCartActionTypes.AddToShoppingCartFail:
      return {
        ...state,
        isAddToShoppingCartLoading: false,
        error: action.payload
      };
    case ShoppingCartActionTypes.ClearShoppingCart:
      return {
        ...state,
        shoppingCart: null,
        error: null
      };
    case ShoppingCartActionTypes.RemoveFromCart:
      return {
        ...state,
        shoppingCartLoading: true,
        removeFromCartError: null
      };
    case ShoppingCartActionTypes.RemoveFromCartFail:
      return {
        ...state,
        shoppingCartLoading: false,
        removeFromCartError: action.payload
      };
    case ShoppingCartActionTypes.ClearRemoveFromCartError:
      return {
        ...state,
        removeFromCartError: null
      };
    case ShoppingCartActionTypes.Checkout: {
      return {
        ...state,
        checkoutLoading: true
      };
    }
    case ShoppingCartActionTypes.CheckoutSuccess: {
      return {
        ...state,
        checkoutLoading: false,
        checkoutError: null
      };
    }
    case ShoppingCartActionTypes.CheckoutFail: {
      return {
        ...state,
        checkoutLoading: false,
        orderLocation: null,
        order: null,
        checkoutError: action.payload
      };
    }
    case ShoppingCartActionTypes.ClearCheckoutError: {
      return {
        ...state,
        checkoutError: null
      };
    }
    case ShoppingCartActionTypes.ClearOrder: {
      return {
        ...state,
        orderLocation: null,
        order: null,
        paymentInformation: null,
        shippingMethod: null
      };
    }
    case ShoppingCartActionTypes.SaveOrderInformation: {
      return {
        ...state,
        order: action.payload
      };
    }
    case ShoppingCartActionTypes.SaveOrderLocation: {
      return {
        ...state,
        orderLocation: action.payload
      };
    }
    case ShoppingCartActionTypes.SaveShippingMethod: {
      return {
        ...state,
        shippingMethod: action.payload
      };
    }
    case ShoppingCartActionTypes.FetchPaymentInformation: {
      return {
        ...state,
        checkoutLoading: true
      };
    }
    case ShoppingCartActionTypes.FetchPaymentInformationSuccess: {
      return {
        ...state,
        checkoutLoading: false,
        paymentInformation: action.payload
      };
    }
    case ShoppingCartActionTypes.FetchPaymentInformationFail: {
      return {
        ...state,
        checkoutLoading: false,
        checkoutError: action.payload
      };
    }
    case ShoppingCartActionTypes.ClearPaymentInformation: {
      return {
        ...state,
        paymentInformation: null
      };
    }
    default:
      return state;
  }
}
