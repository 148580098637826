import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';

@Injectable()
export class AuthService {

  logoffRunning = false;
  private _accessToken: string;

  get accessToken(): string {
    return this._accessToken;
  }

  tokenPayload;

  constructor(
    private oidcSecurityService: OidcSecurityService
  ) {
  }

  login(): void {
    // 177327 Signup link redirection fix
    localStorage.removeItem('oidcIsAuthenticated');
    this.oidcSecurityService.authorize();
  }

  jmLoginLink(): void {
    this.oidcSecurityService.getAuthorizeUrl()
      .pipe(
        take(1),
        map(url => url.replace(environment.identityApiURL, environment.jmIdentityLoginUrl)),
        tap(url => window.location.href = url))
      .subscribe();
  }

  logOut() {
    this.logoffRunning = true;
    this.oidcSecurityService.checkAuth().pipe(take(1)).subscribe(() => {
      this.oidcSecurityService.logoffAndRevokeTokens()
        .pipe(take(1))
        .subscribe(() => localStorage.setItem('oidcIsAuthenticated', 'false'));
    });
  }

  getUserData(): Observable<any> {
    return this.oidcSecurityService.userData$.pipe(map(({ userData }) => userData));
  }

  setAccessToken() {
    this.oidcSecurityService.getAuthenticationResult()
      .pipe(take(1))
      .subscribe(res => {
        this._accessToken = res?.access_token ?? '';
      });
    this.oidcSecurityService.getPayloadFromIdToken()
      .pipe(take(1))
      .subscribe(res => this.tokenPayload = res ?? '');
  }
}
