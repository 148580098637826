import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { take, tap } from 'rxjs/operators';
import { AppConfigurationService } from '../modules/core/services/app-configuration.service';
import { FeatureName } from '../modules/shared/enums/feature-name';

@Injectable()
export class FeatureFlagGuard implements CanActivate {

  constructor(
    private router: Router,
    private appConfigurationService: AppConfigurationService
  ) { }

  canActivate(route: ActivatedRouteSnapshot) {
    const flag = route.data.flag as FeatureName;
    const replaceWith = route.data.replaceWith; // TODO: remove when JmccDashboard feature flags are removed

    return this.appConfigurationService.getFeatureFlag(flag).pipe(
      take(1),
      tap(enabled => {
        if (!enabled) {
          if (replaceWith) {
            this.router.navigateByUrl(replaceWith);
          } else {
            this.router.navigateByUrl('/notfound');
          }
        }
      })
    );
  }
}
