export enum UserRoleCode {
  SuperAdministrator = 'SuperAdministrator',
  PlatformAdministrator = 'PlatformAdministrator',
  BusinessAdministrator = 'BusinessAdministrator',
  BusinessUser = 'BusinessUser',
  PlatformUser = 'PlatformUser',
  PlatformUserOther = 'PlatformUserOther',
  PlatformSupportL1 = 'PlatformSupportLevel1',
  PlatformSupportL2 = 'PlatformSupportLevel2',
  PlatformSupportL3 = 'PlatformSupportLevel3',
  BusinessLocationAdministrator = 'BusinessLocationAdministrator',
  BusinessPaymentAdministrator = 'BusinessPaymentAdministrator',
  JMCarePlanAdministrator = 'JMCarePlanAdministrator',
  JMCarePlanCallCenter = 'JMCarePlanCallCenter',
  JMCarePlanSales = 'JMCarePlanSales',
  JMCarePlanClaimProcessingManager = 'JMCarePlanClaimProcessingManager',
  JMCarePlanClaimProcessor = 'JMCarePlanClaimProcessor',
  JMCarePlanFinance = 'JMCarePlanFinance',
  JMCarePlanJeweler = 'JMCarePlanJeweler',
  JMCarePlanRepairCenter = 'JMCarePlanRepairCenter',
  JMPersonalLinesClaimsAdministrator = 'JMPersonalLinesClaimsAdministrator',
  JMPersonalLinesClaimsExaminer = 'JMPersonalLinesClaimsExaminer',
  JMPersonalLinesClaimsCallCenter = 'JMPersonalLinesClaimsCallCenter',
  JMPersonalLinesClaimsSpecialist = 'JMPersonalLinesClaimsSpecialist',
  JMAppraisalAdministrator = 'JMAppraisalAdministrator',
  JMPointOfSaleAdministrator = 'JMPointOfSaleAdministrator',
  JMCustomerService = 'JMCustomerService',
  JMJewelerProgramsFinance = 'JMJewelerProgramsFinance'
}
