import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { AddressSearchResult } from '../models/address-search-result';

import { AddressLayoutResult, AddressValidationResult } from '../models/address-layout';
import { CountryCode } from '../../appraisal/enums/country-code';

@Injectable({
  providedIn: 'root'
})
export class AddressService {

  private addressApiUrl = environment.riskApiURL + '/api' + '/addresses';

  constructor(
    private http: HttpClient
  ) { }

  search(addressQueryModel: {
    query: string,
    country: string,
    id?: string,
    secondaryAddress?: string
  }): Observable<AddressSearchResult[]> {
    if (addressQueryModel.country === 'US') {
      addressQueryModel.country = 'USA';
    }
    return this.http
      .post<AddressSearchResult[]>(`${this.addressApiUrl}/search`, addressQueryModel);
  }

  verify(address: AddressLayoutResult): Observable<AddressValidationResult> {
    if (address.country === 'US') {
      address.country = 'USA';
    }
    return this.http
      .post<AddressValidationResult>(`${this.addressApiUrl}/validate`, address);
  }

  searchByZipCode(zipCode: string): Observable<{
    city: string,
    state: string,
    zipCode: string
  }> {
    const url = environment.riskApiURL + `/api/zipcodes/search`;
    return this.http.post<{
      city: string,
      state: string,
      zipCode: string
    }>(url, { zipCode });
  }

  countryAlpha2ToAlpha3(countryId: string): string {
    switch (countryId) {
      case 'US':
        return CountryCode.USA;
      case 'CA':
        return CountryCode.CAN;
      default:
        return countryId;
    }
  }

  countryAlpha3ToAlpha2(countryId: string): string {
    switch (countryId) {
      case CountryCode.USA:
        return 'US';
      case CountryCode.CAN:
        return 'CA';
      default:
        return countryId;
    }
  }
}
