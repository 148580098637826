import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServiceModules } from '../enums/service-modules';
import { ModulePaymentMethod } from '../models/ModulePaymentMethods';

@Injectable()
export class ServiceModulesService {
  private placeholderUrl = environment.paymentApiURL + '/api/servicemodules/';

  constructor(
    private http: HttpClient
  ) { }

  public getDiamondMarketplacePaymentMethods(): Observable<ModulePaymentMethod[]> {
    return this.getPaymentMethods(ServiceModules.DMT);
  }

  public getPaymentMethods(serviceModules: ServiceModules): Observable<ModulePaymentMethod[]> {
    const url = `${this.placeholderUrl}${serviceModules}/paymentmethods`;
    return this.http.get<ModulePaymentMethod[]>(url);
  }
}
