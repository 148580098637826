import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { PaymentInformation, PaymentReport } from '../models';

@Injectable({
  providedIn: 'root'
})
export class PaymentSourceService {

  readonly paymentApiURL = environment.paymentApiURL;

  constructor(private http: HttpClient) { }

  getByOrderId(zingOrderId: string) {
    return this.http.get<PaymentInformation>(`${this.paymentApiURL}/api/payments/${zingOrderId}`);
  }

  getReportsByOrderIds(zingOrderIds: string[]) {
    return this.http.get<PaymentReport[]>(`${this.paymentApiURL}/api/payments/reports`, { params: { zingOrderIds } });
  }
}
