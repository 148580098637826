/* NgRx */
import { Action } from '@ngrx/store';

export enum TwoFactorAuthActionTypes {
  LoadAuthMethods = '[TwoFA] Load Auth Methods',
  LoadAuthMethodsSuccess = '[TwoFA] Load Auth Methods Success',
  LoadAuthMethodsFail = '[TwoFA] Load Auth Methods Fail',
  SendVerificationCode = '[TwoFA] Send Verification Code',
  SendVerificationCodeSuccess = '[TwoFA] Send Verification Code Success',
  SendVerificationCodeFail = '[TwoFA] Send Verification Code Fail',
  VerifyCode = '[TwoFA] Verify Code',
  VerifyCodeSuccess = '[TwoFA] Verify Code Success',
  VerifyCodeFail = '[TwoFA] Verify Code Fail',
  GetSecurityQuestion = '[TwoFA] Get Security Question',
  GetSecurityQuestionSuccess = '[TwoFA] Get Security Question Success',
  GetSecurityQuestionFail = '[TwoFA] Get Security Question Fail',
  VerifyAnswer = '[TwoFA] Verify Answer',
  VerifyAnswerSuccess = '[TwoFA] Verify Answer Success',
  VerifyAnswerFail = '[TwoFA] Verify Answer Fail',
  ResetTwoFactorAuth = '[TwoFA] Reset Two Factor Auth'
}

// Action Creators
export class LoadAuthMethods implements Action {
  readonly type = TwoFactorAuthActionTypes.LoadAuthMethods;
  constructor(public payload: string) { }
}

export class LoadAuthMethodsSuccess implements Action {
  readonly type = TwoFactorAuthActionTypes.LoadAuthMethodsSuccess;
  constructor(public payload: any) { }
}

export class LoadAuthMethodsFail implements Action {
  readonly type = TwoFactorAuthActionTypes.LoadAuthMethodsFail;
  constructor(public payload: any) { }
}

export class SendVerificationCode implements Action {
  readonly type = TwoFactorAuthActionTypes.SendVerificationCode;
  constructor(public payload: { id: string, method: string }) { }
}

export class SendVerificationCodeSuccess implements Action {
  readonly type = TwoFactorAuthActionTypes.SendVerificationCodeSuccess;
}

export class SendVerificationCodeFail implements Action {
  readonly type = TwoFactorAuthActionTypes.SendVerificationCodeFail;
  constructor(public payload: any) { }
}

export class VerifyCode implements Action {
  readonly type = TwoFactorAuthActionTypes.VerifyCode;
  constructor(public payload: { id: string, token: string, method: string }) { }
}

export class VerifyCodeSuccess implements Action {
  readonly type = TwoFactorAuthActionTypes.VerifyCodeSuccess;
}

export class VerifyCodeFail implements Action {
  readonly type = TwoFactorAuthActionTypes.VerifyCodeFail;
  constructor(public payload: any) { }
}

export class GetSecurityQuestion implements Action {
  readonly type = TwoFactorAuthActionTypes.GetSecurityQuestion;
  constructor(public payload: string) { }
}

export class GetSecurityQuestionSuccess implements Action {
  readonly type = TwoFactorAuthActionTypes.GetSecurityQuestionSuccess;
  constructor(public payload: { id: string, questionText: string }) { }
}

export class GetSecurityQuestionFail implements Action {
  readonly type = TwoFactorAuthActionTypes.GetSecurityQuestionFail;
  constructor(public payload: any) { }
}

export class VerifyAnswer implements Action {
  readonly type = TwoFactorAuthActionTypes.VerifyAnswer;
  constructor(public payload: { identityUserId: string, questionId: string, answer: string }) { }
}

export class VerifyAnswerSuccess implements Action {
  readonly type = TwoFactorAuthActionTypes.VerifyAnswerSuccess;
  constructor(public payload: { verifyStatus: boolean, authenticationFailed: boolean }) { }
}

export class VerifyAnswerFail implements Action {
  readonly type = TwoFactorAuthActionTypes.VerifyAnswerFail;
  constructor(public payload: any) { }
}

export class ResetTwoFactorAuth implements Action {
  readonly type = TwoFactorAuthActionTypes.ResetTwoFactorAuth;
}

export type TwoFAActions = LoadAuthMethods
  | LoadAuthMethodsSuccess
  | LoadAuthMethodsFail
  | SendVerificationCode
  | SendVerificationCodeSuccess
  | SendVerificationCodeFail
  | VerifyCode
  | VerifyCodeSuccess
  | VerifyCodeFail
  | GetSecurityQuestion
  | GetSecurityQuestionSuccess
  | GetSecurityQuestionFail
  | VerifyAnswer
  | VerifyAnswerSuccess
  | VerifyAnswerFail
  | ResetTwoFactorAuth;
