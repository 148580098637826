import { Component, OnDestroy, OnInit, Renderer2 } from '@angular/core';

import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'z-mandate-terms-modal',
  templateUrl: './mandate-terms-modal.component.html'
})
export class MandateTermsModalComponent implements OnInit, OnDestroy {

  title: string;
  content: string;
  termsAccepted = false;

  constructor(
    public bsModalRef: BsModalRef<MandateTermsModalComponent>,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'zing-ui-modal-backdrop');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'zing-ui-modal-backdrop');
  }

}
