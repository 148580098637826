import { PageSizeItem } from '@progress/kendo-angular-grid';
import { PaidStatementsColumnName, TransactionColumnName } from '../enums/billing-column-type';

export const defaultPaidStatementsColumns = [
  {columnName: PaidStatementsColumnName.StatementNumber, columnData: 'statementNumber', width: 200, class: 'statement-number'},
  {columnName: PaidStatementsColumnName.StatementDate, columnData: 'statementDate', width: 100, format: '{0:MM/dd/yyyy hh:mm}'},
  {columnName: PaidStatementsColumnName.TransactionsNumber, columnData: 'transactionNumber', width: 150, class: 'alignCenter'},
  {columnName: PaidStatementsColumnName.StatementAmount, columnData: 'statementAmount', width: 150, format: '{0:c}', class: 'alignRight'}
];

export const defaultTransactionColumns = [
  {columnName: TransactionColumnName.ShipmentId, columnData: 'shipmentId', width: 130, footerClass:'d-none'},
  {columnName: TransactionColumnName.ClientId, columnData: 'clientId', width: 130, footerClass:'d-none'},
  {columnName: TransactionColumnName.Type, columnData: 'type', width: 170, footerClass:'d-none'},
  {columnName: TransactionColumnName.TransactionDate, columnData: 'transactionDate', format: '{0:MM/dd/yyyy hh:mm}', width: 180, footerClass:'d-none'},
  {columnName: TransactionColumnName.Recipient, columnData: 'recipient', width: 180, class: 'recipient', footerClass:'d-none'},
  {columnName: TransactionColumnName.Service, columnData: 'service', width: 170, footerClass:'d-none'},
  {columnName: TransactionColumnName.TrackingNumber, columnData: 'trackingNumber', width: 200, footerClass: 'd-none'},
  {columnName: TransactionColumnName.Amount, columnData: 'amount', width: 170, format: '{0:c}', class: 'alignRight', footerClass: 'text-start text-sm-right'}
];

export const sessionStorageKeys = {
  paidStatementsPageSize: 'paidStatementsPageSize',
  transactionsPageSize: 'transactionsPageSize',
  searchCriteria: 'searchCriteria',
  startDate: 'startDate',
  endDate: 'endDate'
};

export const pageSizes: PageSizeItem[] = [
  { text: '15', value: 15 },
  { text: '30', value: 30 },
  { text: '50', value: 50 },
  { text: '100', value: 100 }
];

export const documentNames = {
  paidStatements: 'Paid Statements',
  statementDetailsExcel: 'Paid Statement Detail',
  statementDetailsPdf: 'Statement Detail'
};
