import { FilterDefinition } from '../modules/shared/models/filter-definition.model';
import { CompositeFilterDescriptor, FilterDescriptor, SortDescriptor, toODataString } from '@progress/kendo-data-query';
import { DateTimeRegex, GuidQueryRegex } from './regex';
import { ShippingColumnName } from '../modules/membership/modules/shipping/enums/shipping-column-type';
import { SortingType } from '../modules/membership/modules/shared/enums/sortingType';
import { getColumnFilterType, getColumnNameByFileName, isPriceColumn } from '../modules/membership/modules/shipping/utils/new-shipment-helper';
import { FilterType } from '../modules/shared/models/odata-query-model';
import { FilterCriteria } from '../modules/membership/modules/shipping/enums/filter-criteria';
import { QueryCondition } from '../modules/membership/modules/shipping/enums/query-condition';

export class ODataModel {
  filter: string;
  orderBy: string;
  skip: number;
  top: number;
}

export class CombinedODataQueryModel extends ODataModel {
  filteringFields: FilteringField[];
  sortingField?: SortingField;
}

export interface FilteringField {
  name: ShippingColumnName;
  values: string[];
  additionalCriteria?: FilterCriteria[];
  queryCondition?: QueryCondition;
}
export interface SortingField {
  name: ShippingColumnName;
  type: SortingType;
}

/** Skip single quotes so that odata query works */
function skipODataStringInFilter(queryFilter: CompositeFilterDescriptor | FilterDescriptor) {
  if ('value' in queryFilter) {
    queryFilter = queryFilter as FilterDescriptor;
    if (!queryFilter.value || typeof (queryFilter.value) !== 'string') {
      return {
        ...queryFilter,
        field: queryFilter.field
      };
    }

    const result = {
      ...queryFilter,
      field: queryFilter.field,
      value: queryFilter.value.replace(/'/g, function (match) { return '\\\''; })
    } as FilterDescriptor;
    return result;
  } else {
    queryFilter = queryFilter as CompositeFilterDescriptor;
    return {
      ...queryFilter,
      filters: queryFilter.filters.map(el => skipODataStringInFilter(el))
    };
  }
}

export function createDataQueryModel(filter: FilterDefinition): ODataModel {
  if (filter?.filter?.filters) {
    // escape single quotes, create new filter so that kendo search is not showing escape chars
    const copy = {
      ...filter.filter,
      filters: filter.filter?.filters?.map(el => skipODataStringInFilter(el))
    };
    filter = {
      ...filter,
      filter: copy
    };
  }

  return createODataModel(filter);
}

function createODataModel(filter: FilterDefinition) {
  const oDataModel = {
    filter: '',
    orderBy: '',
    skip: 0,
    top: 0
  };

  const filterOptions = `${toODataString(filter)}`.split('&');
  for (let i = 0; i < filterOptions.length; i++) {
    const item = filterOptions[i].split('=');

    switch (item[0]) {
      case '$skip': oDataModel.skip = +item[1];
        break;
      case '$top': oDataModel.top = +item[1];
        break;
      case '$orderby': oDataModel.orderBy = item[1];
        break;
      case '$filter': {
        // we need to decode characters
        item[1] = unescape(item[1]);
        // odata adds two quotes when you search for one so we need to replace it before search
        const replaceChars = { '\\\'\'': '\\\'' };
        item[1] = item[1].replace(/\\''/g, function (match) { return replaceChars[match]; });
        // because the backend uses an older version of OData (older than v4) need to replace the date strings
        item[1] = item[1].replace(new RegExp(DateTimeRegex, 'g'), function (match) { return `datetime${match}`; });
        item[1] = item[1].replace(new RegExp(GuidQueryRegex, 'g'), function (match) { return `guid${match}`; });
        oDataModel.filter = item[1];
      } break;
    }
  }
  return oDataModel;
}


/* ******** COMBINED ODATA FILTER METHODS ********* */

/**
 * Returns model value from database for filters using regular query
 */
function mapFieldToModelColumnName(field: any): string {
  const fieldsMapping = {
    'carrierTrackingId': 'purchase/trackingNumber',
    'shipTo': 'destination/company',
    'webStore': 'eStoreDetail/customersStoreRef',
    'shipVia': 'eStoreDetail/shipVia',
    'scanBasedBilling': 'isScanBasedBilling',
    'shipFromCity': 'origin/city',
    'voided': 'voidDetail'
  };
  return fieldsMapping[field] || field;
}

function mapOperatorToFilterCriteria(operator: string) {
  const operatorsMapping = {
    'eq': FilterCriteria.Equal,
    'neq': FilterCriteria.NotEqual,
    'lt': FilterCriteria.LessThan,
    'lte': FilterCriteria.LessOrEqual,
    'gt': FilterCriteria.GreaterThan,
    'gte': FilterCriteria.GreaterOrEqual
  };
  return operatorsMapping[operator];
}

function mapLogicToQueryCondition(logic: string) {
  const logicsMapping = {
    'and': QueryCondition.And,
    'or': QueryCondition.Or
  };
  return logicsMapping[logic];
}

function skipODataStringInCombinedFilter(queryFilter: CompositeFilterDescriptor | FilterDescriptor) {
  if ('value' in queryFilter) {
    queryFilter = queryFilter as FilterDescriptor;

    //skip custom query
    if (getColumnFilterType(queryFilter.field as string) === FilterType.CustomQuery) {
      return null;
    }

    if (!queryFilter.value || typeof (queryFilter.value) !== 'string') {
      return {
        ...queryFilter,
        field: mapFieldToModelColumnName(queryFilter.field)
      };
    }

    const result = {
      ...queryFilter,
      field: mapFieldToModelColumnName(queryFilter.field),
      value: queryFilter.value.replace(/'/g, function (match) { return '\\\''; })
    } as FilterDescriptor;

    return result;
  } else {
    queryFilter = queryFilter as CompositeFilterDescriptor;
    const mappedFilters = queryFilter.filters.reduce((acc, el) => {
      const mappedFilter = skipODataStringInCombinedFilter(el);
      if (mappedFilter) {
        acc.push(mappedFilter);
      }
      return acc;
    }, []);

    return {
      ...queryFilter,
      filters: mappedFilters
    };
  }
}


export function createShipmentSummaryDataQueryModel(filter: FilterDefinition): CombinedODataQueryModel {
  const filters = [];
  createCustomFilteringModel(filter.filter, filters);

  if (filter?.filter?.filters) {
    // escape single quotes, create new filter so that kendo search is not showing escape chars
    const mappedFilters = filter.filter?.filters
      .reduce((acc, el) => {
        const mappedFilter = skipODataStringInCombinedFilter(el) as CompositeFilterDescriptor;
        if ((mappedFilter && !mappedFilter.filters) || (mappedFilter && mappedFilter.filters && mappedFilter.filters.length > 0)) {
          acc.push(mappedFilter);
        }
        return acc;
      }, []);

    const copy = {
      ...filter.filter,
      filters: mappedFilters
    };
    filter = {
      ...filter,
      filter: copy
    };
  }

  const oDataModel = createODataModel(filter);
  const sortingModel = createCustomSortingModel(filter.sort);

  const combinedODataModel = {
    filter: oDataModel.filter,
    orderBy: '',
    filteringFields: filters,
    sortingField: sortingModel,
    skip: oDataModel.skip,
    top: oDataModel.top
  };

  return combinedODataModel;
}

function createCustomFilteringModel(
  queryFilter: CompositeFilterDescriptor | FilterDescriptor,
  filteringFileds: FilteringField[],
  logic?: string
): FilteringField[] {
  if ('value' in queryFilter) {
    queryFilter = queryFilter as FilterDescriptor;

    // skip custom queries which should be included separately
    if (getColumnFilterType(queryFilter.field as string) !== FilterType.CustomQuery) {
      return;
    }

    const columnName = getColumnNameByFileName(queryFilter.field as string);
    const existingColumnIndex = filteringFileds.findIndex(f => f.name === columnName);

    const filterCriteria = mapOperatorToFilterCriteria(queryFilter.operator as string);
    const queryCondition = mapLogicToQueryCondition(logic);

    if (existingColumnIndex > -1) {
      filteringFileds[existingColumnIndex].values.push(queryFilter.value);
      if (isPriceColumn(columnName)) {
        filteringFileds[existingColumnIndex].additionalCriteria.push(filterCriteria);
        filteringFileds[existingColumnIndex].queryCondition = queryCondition;
      }
    } else {
      const filteringField = {
        name: columnName,
        values: [queryFilter.value]
      } as FilteringField;
      const filteringFieldPrice = {
        name: columnName,
        values: [queryFilter.value],
        additionalCriteria: [filterCriteria],
        queryCondition
      } as FilteringField;

      filteringFileds.push(isPriceColumn(columnName) ? filteringFieldPrice : filteringField);
    }
    return;
  } else {
    queryFilter = queryFilter as CompositeFilterDescriptor;
    if (!queryFilter.filters) {
      return;
    }
    const l = queryFilter.logic;
    queryFilter.filters.forEach(el => createCustomFilteringModel(el, filteringFileds, l));
  }
}

function createCustomSortingModel(sort: SortDescriptor[]): SortingField {
  if (!sort) {
    return;
  }
  const sortFiled = sort[0];

  if (!sortFiled) {
    return;
  }
  const columnName = getColumnNameByFileName(sortFiled.field);

  if (!columnName) {
    return;
  }

  return {
    name: columnName,
    type: sortFiled.dir === 'asc' ? SortingType.Ascending : SortingType.Descending
  };
}
