import * as fromRoot from '../../../../../../store/root.state';
import { Invoice } from '../../models/invoice.model';
import { PaymentDetails } from '../../models/payment-details.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { BillingActions, BillingActionTypes } from '../actions/billing.actions';

export interface ZingState extends fromRoot.ZingState {
  billingState: BillingState;
}

export interface BillingState {
  clientInvoices: Array<Invoice>;
  clientPayments: Array<PaymentDetails>;
  startDate: Date;
  endDate: Date;

}

export const initialState: BillingState = {
  clientInvoices: [],
  clientPayments: [],
  startDate: null,
  endDate: null
};

const getBillingFeatureState = createFeatureSelector<BillingState>('billing');

export const getClientInvoices = createSelector(
  getBillingFeatureState,
  state => state.clientInvoices
);

export const getClientPayments = createSelector(
  getBillingFeatureState,
  state => state.clientPayments
);

export const getSearchStartDate = createSelector(
  getBillingFeatureState,
  state => state.startDate
);

export const getSearchEndDate = createSelector(
  getBillingFeatureState,
  state => state.endDate
);

export function billingReducer(state = initialState, action: BillingActions): BillingState {
  switch (action.type) {
    case BillingActionTypes.LoadDataInvoicesSuccess:
      return {
        ...state,
        clientInvoices: action.payload,
      };
    case BillingActionTypes.LoadDataInvoicesPaymentsFail:
      return {
        ...state,
        clientInvoices: [],
        clientPayments: [],
      };
    case BillingActionTypes.SetSearchStartDate:
      return {
        ...state,
        startDate: action.startDate
      };
    case BillingActionTypes.GetSearchStartDate:
      return {
        ...state
      };
    case BillingActionTypes.SetSearchEndDate:
      return {
        ...state,
        endDate: action.endDate
      };
    case BillingActionTypes.GetSearchEndDate:
      return {
        ...state
      };
    default:
      return state;
  }
}
