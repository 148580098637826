import { CartInformation } from '../../../shopping-cart/models/cart-information';
import { DiamondCart } from '../../../shopping-cart/models/diamond-cart';
import { ModuleType } from '../../../shopping-cart/models/module-type';
import { NaturalShoppingCart } from '../../models/natural-cart';

export function cartHasGracePeriod(cart: NaturalShoppingCart): boolean {
  const cartInfo = cart.cartInformation.find(c => c.type === ModuleType.NaturalDiamonds);

  return cartInfo ? cartInfo.hasGracePeriod : false;
}

export function shippingAndHandlingAmount(cart: NaturalShoppingCart) {
  const cartInfo = cart.cartInformation.find(c => c.type === ModuleType.NaturalDiamonds);

  if (cartInfo) {
    return cartInfo.shippingAndHandlingAmount !== 0;
  }

  return false;
}

export function cartHasMultipleShippingMethods(cart: NaturalShoppingCart) {
  const cartInfo = cart.cartInformation.find(c => c.type === ModuleType.NaturalDiamonds);

  if (cartInfo) {
    return !!cartInfo.totalExpeditedShippingHandlingAmount
      && !!cartInfo.totalStandardShippingHandlingAmount;
  }

  return false;
}

export function hasShippingAndHandling(cart: NaturalShoppingCart) {
  const cartInfo = cart.cartInformation.find(c => c.type === ModuleType.NaturalDiamonds);

  if (cartInfo) {
    return !!cartInfo.totalExpeditedShippingHandlingAmount
      || !!cartInfo.totalStandardShippingHandlingAmount;
  }

  return false;
}

export function getCartInformation(cart: NaturalShoppingCart): CartInformation {
  return cart.cartInformation.find(c => c.type === ModuleType.NaturalDiamonds);
}

export function getLabGrownCartInformation(cart: DiamondCart): CartInformation {
  return cart.cartInformation.find(c => c.type === ModuleType.LabGrownDiamonds);
}