import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UnsupportedBrowserService } from '../modules/shared/services/unsupported-browsers.service';

@Injectable()
export class ApplicationBrowserGuard implements CanActivate {

  constructor(
    private router: Router,
    private unsupportedBrowsersService: UnsupportedBrowserService
  ) { }

  canActivate(): Observable<boolean> | boolean {
    if (this.unsupportedBrowsersService.checkForBrowserValidity()) {
        return true;
    } else {
        this.router.navigate(['/unsupportedBrowsers']);
        return false;
    }
  }
}
