import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { AuthService } from '../../core/services/auth.service';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { IdleWarningComponent } from '../components/idle-warning/idle-warning.component';

const INACTIVITY_PERIOD = 3300;
const IDLE_TIMEOUT = 300;

@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {

  bsModalRef: BsModalRef;
  private userLoggedIn = new Subject<boolean>();

  private setupCompleted = false;

  constructor(
    private authService: AuthService,
    private idle: Idle,
    private modalService: BsModalService) {
    this.userLoggedIn.next(false);
    this.onHideModal();
  }

  setIdleConfig() {
    if (this.setupCompleted) {
      return;
    }

    const tokenPayload = this.authService.tokenPayload;

    const inactivityPeriod = tokenPayload && Number.isInteger(+tokenPayload.inactivity_period) ?
      +tokenPayload.inactivity_period : INACTIVITY_PERIOD;
    const idleTimeout = tokenPayload && Number.isInteger(+tokenPayload.idle_timeout) ?
      +tokenPayload.idle_timeout : IDLE_TIMEOUT;

    // Sets an idle timeout.
    this.idle.setIdle(inactivityPeriod);
    // Sets a timeout period after which the user will be considered timed out.
    this.idle.setTimeout(idleTimeout);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the page
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    this.setupCompleted = true;

    this.idle.onIdleStart.subscribe(() => {
      this.showIdleWarning();
    });

    this.idle.onIdleEnd.subscribe(() => {
      this.resetSession();
    });

    this.idle.onTimeout.subscribe(() => {
      this.setUserLoggedIn(false);
      this.logoutUser();
    });

    this.getUserLoggedIn().subscribe(userLoggedIn => {
      if (userLoggedIn && !this.idle.isRunning()) {
        this.idle.watch();
      } else if (!userLoggedIn && this.idle.isRunning()) {
        this.idle.stop();
      }
    });
  }

  resetSession() {
    this.idle.watch();
  }

  setUserLoggedIn(userLoggedIn: boolean) {
    this.userLoggedIn.next(userLoggedIn);
  }

  getUserLoggedIn(): Observable<boolean> {
    return this.userLoggedIn.asObservable();
  }

  logoutUser() {
    this.authService.logOut();
  }

  showIdleWarning() {
    this.bsModalRef = this.modalService.show(
      IdleWarningComponent,
      {
        class: 'gray modal-dialog-25 modal-dialog-centered',
        backdrop: 'static'
      }
    );
  }

  onHideModal() {
    this.modalService.onHide
      .subscribe(() => {
        if (this.bsModalRef && this.bsModalRef.content instanceof IdleWarningComponent) {
          const { isLogout } = this.bsModalRef.content;
          if (isLogout) {
            this.setUserLoggedIn(false);
            this.logoutUser();
          } else {
            this.resetSession();
          }
        }

        this.bsModalRef = null;
      });
  }
}
