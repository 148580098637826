import { Action } from '@ngrx/store';
import { TeamMember } from '../models/team-member';
import { Lookup } from '../services/team.service';
import { PaginatedResponse } from '../../../../shared/models/paginated-response.model';
import { FilterDefinition } from '../../../../shared/models/filter-definition.model';
import { AccountUserSettings } from '../models/account-user-settings.model';

export interface ZingError {
  type: string;
  code: string;
  message: string;
}

export enum TeamActionTypes {
  LoadLocations             = '[Team] Load Locations',
  LoadLocationsSuccess      = '[Team] Load Locations Success',
  LoadLocationsFail         = '[Team] Load Locations Fail',
  LoadRoles                 = '[Team] Load Roles',
  LoadRolesSuccess          = '[Team] Load Roles Success',
  LoadRolesFail             = '[Team] Load Roles Fail',
  LoadTeamMembers           = '[Team] Load Team Members',
  LoadTeamMembersSuccess    = '[Team] Load Team Members Success',
  LoadTeamMembersFail       = '[Team] Load Team Members Fail',
  ApproveTeamMember         = '[Team] Approve Team Member',
  ApproveTeamMemberSuccess  = '[Team] Approve Team Member Success',
  ApproveTeamMemberFail     = '[Team] Approve Team Member Fail',
  RejectTeamMember          = '[Team] Reject Team Member',
  RejectTeamMemberSuccess   = '[Team] Reject Team Member Success',
  RejectTeamMemberFail      = '[Team] Reject Team Member Fail',
  UpdateTeamMember          = '[Team] Update Team Member',
  UpdateTeamMemberSuccess   = '[Team] Update Team Member Success',
  UpdateTeamMemberFail      = '[Team] Update Team Member Fail',
  UpdateShippingUserData  = '[Shipping] Update Shipping User Data',
  UpdateShippingUserDataSuccess  = '[Shipping] Update Shipping User Data Success',
  UpdateShippingUserDataFail  = '[Shipping] Update Shipping User Data Fail',
  UpdatePersonData  = '[Shipping] Update Person Data',
  UpdatePersonDataSuccess  = '[Shipping] Update Person Data Success',
  UpdatePersonDataFail  = '[Shipping] Update Person Data Fail',
  SaveShippingUserData    = '[Shipping] Save Shipping User Data',
  SaveShippingUserDataSuccess = '[Shipping] Save Shipping User Data Success',
  SaveShippingUserDataFail = '[Shipping] Save Shipping User Data Fail',
  PrepareForEdit = '[Shipping] Prepare state for Edit',
  ResetTeamMembers = '[Team] Reset Team Member',
  SaveAccountUserSettings = '[Team] Save Account User Settings',
  SaveAccountUserSettingsSuccess = '[Team] Save Account User Settings Success',
  SaveAccountUserSettingsFail = '[Team] Save Account User Settings Fail',
  UpdateAccountUserSettings = '[Team] Update Account User Settings',
  UpdateAccountUserSettingsSuccess = '[Team] Update Account User Settings Success',
  UpdateAccountUserSettingsFail = '[Team] Update Account User Settings Fail',
}

export class LoadLocations implements Action {
  readonly type = TeamActionTypes.LoadLocations;
}

export class LoadLocationsSuccess implements Action {
  readonly type = TeamActionTypes.LoadLocationsSuccess;
  constructor(public payload: Array<Lookup>) { }
}

export class LoadLocationsFail implements Action {
  readonly type = TeamActionTypes.LoadLocationsFail;
  constructor(public payload: ZingError) { }
}

export class LoadRoles implements Action {
  readonly type = TeamActionTypes.LoadRoles;
}

export class LoadRolesSuccess implements Action {
  readonly type = TeamActionTypes.LoadRolesSuccess;
  constructor(public payload: Array<Lookup>) { }
}

export class LoadRolesFail implements Action {
  readonly type = TeamActionTypes.LoadRolesFail;
  constructor(public payload: ZingError) { }
}

export class LoadTeamMembers implements Action {
  readonly type = TeamActionTypes.LoadTeamMembers;
  constructor(public payload: FilterDefinition) { }
}

export class LoadTeamMembersSuccess implements Action {
  readonly type = TeamActionTypes.LoadTeamMembersSuccess;
  constructor(public payload: PaginatedResponse<TeamMember>) { }
}

export class LoadTeamMembersFail implements Action {
  readonly type = TeamActionTypes.LoadTeamMembersFail;
  constructor(public payload: ZingError) { }
}

export class ApproveTeamMember implements Action {
  readonly type = TeamActionTypes.ApproveTeamMember;
  constructor(public payload: TeamMember) { }
}

export class ResetTeamMembers implements Action {
  readonly type = TeamActionTypes.ResetTeamMembers;
}

export class ApproveTeamMemberSuccess implements Action {
  readonly type = TeamActionTypes.ApproveTeamMemberSuccess;
  constructor(public payload: TeamMember) { }
}

export class ApproveTeamMemberFail implements Action {
  readonly type = TeamActionTypes.ApproveTeamMemberFail;
  constructor(public payload: ZingError) { }
}

export class RejectTeamMember implements Action {
  readonly type = TeamActionTypes.RejectTeamMember;
  constructor(public payload: TeamMember) { }
}

export class RejectTeamMemberSuccess implements Action {
  readonly type = TeamActionTypes.RejectTeamMemberSuccess;
  constructor(public payload: TeamMember) { }
}

export class RejectTeamMemberFail implements Action {
  readonly type = TeamActionTypes.RejectTeamMemberFail;
  constructor(public payload: ZingError) { }
}

export class UpdateTeamMember implements Action {
  readonly type = TeamActionTypes.UpdateTeamMember;
  constructor(public payload: TeamMember) { }
}

export class UpdateTeamMemberSuccess implements Action {
  readonly type = TeamActionTypes.UpdateTeamMemberSuccess;
}

export class UpdateTeamMemberFail implements Action {
  readonly type = TeamActionTypes.UpdateTeamMemberFail;
  constructor(public payload: ZingError) { }
}

export class UpdateShippingUserData implements Action {
  readonly type = TeamActionTypes.UpdateShippingUserData;
  constructor(public payload: any) { }
}

export class UpdateShippingUserDataSuccess implements Action {
  readonly type = TeamActionTypes.UpdateShippingUserDataSuccess;
  constructor(public payload: any) { }
}

export class UpdateShippingUserDataFail implements Action {
  readonly type = TeamActionTypes.UpdateShippingUserDataFail;
  constructor(public payload: any) { }
}

export class SaveShippingUserData implements Action {
  readonly type = TeamActionTypes.SaveShippingUserData;
  constructor(public payload: any) { }
}

export class SaveShippingUserDataSuccess implements Action {
  readonly type = TeamActionTypes.SaveShippingUserDataSuccess;
  constructor(public payload: any) { }
}

export class SaveShippingUserDataFail implements Action {
  readonly type = TeamActionTypes.SaveShippingUserDataFail;
  constructor(public payload: any) { }
}
export class PrepareForEdit implements Action {
  readonly type = TeamActionTypes.PrepareForEdit;
}

export class SaveAccountUserSettings implements Action {
  readonly type = TeamActionTypes.SaveAccountUserSettings;
  constructor(public payload: AccountUserSettings) {}
}

export class SaveAccountUserSettingsSuccess implements Action {
  readonly type = TeamActionTypes.SaveAccountUserSettingsSuccess;
}

export class SaveAccountUserSettingsFail implements Action {
  readonly type = TeamActionTypes.SaveAccountUserSettingsFail;
  constructor(public payload: any) {}
}

export class UpdateAccountUserSettings implements Action {
  readonly type = TeamActionTypes.UpdateAccountUserSettings;
  constructor(public payload: AccountUserSettings) {}
}

export class UpdateAccountUserSettingsSuccess implements Action {
  readonly type = TeamActionTypes.UpdateAccountUserSettingsSuccess;
}

export class UpdateAccountUserSettingsFail implements Action {
  readonly type = TeamActionTypes.UpdateAccountUserSettingsFail;
  constructor(public payload: any) {}
}

export type TeamActions = LoadLocations
  | LoadLocationsSuccess
  | LoadLocationsFail
  | LoadRoles
  | LoadRolesSuccess
  | LoadRolesFail
  | LoadTeamMembers
  | LoadTeamMembersSuccess
  | LoadTeamMembersFail
  | ResetTeamMembers
  | ApproveTeamMember
  | ApproveTeamMemberSuccess
  | ApproveTeamMemberFail
  | RejectTeamMember
  | RejectTeamMemberSuccess
  | RejectTeamMemberFail
  | UpdateTeamMember
  | UpdateTeamMemberSuccess
  | UpdateTeamMemberFail
  | UpdateShippingUserData
  | UpdateShippingUserDataSuccess
  | UpdateShippingUserDataFail
  | SaveShippingUserData
  | SaveShippingUserDataSuccess
  | SaveShippingUserDataFail
  | PrepareForEdit
  | SaveAccountUserSettingsSuccess
  | SaveAccountUserSettingsFail
  | SaveAccountUserSettings
  | UpdateAccountUserSettingsSuccess
  | UpdateAccountUserSettingsFail
  | UpdateAccountUserSettings;
