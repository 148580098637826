export enum ServiceType {
  /// <summary>
  /// None
  /// </summary>
  None = 0,

  /// <summary>
  /// Next Day Early AM
  /// </summary>
  NextDayEarlyAM = 1,

  /// <summary>
  /// Next Day AM
  /// </summary>
  NextDayAM = 2,

  /// <summary>
  /// Next Day PM
  /// </summary>
  NextDayPM = 3,

  /// <summary>
  /// Two Day AM
  /// </summary>
  TwoDayAM = 4,

  /// <summary>
  /// Two Day PM
  /// </summary>
  TwoDayPM = 5,

  /// <summary>
  /// Third Day
  /// </summary>
  ThirdDay = 6,

  /// <summary>
  /// Ground Or First Class
  /// </summary>
  GroundOrFirstClass = 7,

  /// <summary>
  /// Parcel Select
  /// </summary>
  ParcelSelect = 8,

  /// <summary>
  /// International One
  /// </summary>
  International1 = 9,

  /// <summary>
  /// International Two
  /// </summary>
  International2 = 10,

  /// <summary>
  /// International Three
  /// </summary>
  International3 = 11,

  /// <summary>
  /// International Four
  /// </summary>
  International4 = 12
}

export enum ServiceOptionType {
  None,
  NextDay,
  TwoThreeDays,
  Ground
}
