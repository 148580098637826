import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { GoogleTagManagerEvent } from '../models/gtm.model';

@Injectable()
export class GTMService {
  private isLoaded = false;
  private gtmId: string;

  constructor() {
    this.gtmId = environment.gtmId;
  }

  public addGTM() {
    if (this.isLoaded) {
      return;
    }

    // add script in begining of head
    this.pushOnDataLayer({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js'
    });

    const gtmScript = document.createElement('script');
    gtmScript.async = true;
    gtmScript.src = `//www.googletagmanager.com/gtm.js?id=${this.gtmId}`;
    document.head.insertBefore(gtmScript, document.head.firstChild);

    // add iframe in body
    const iframe = document.createElement('iframe');
    iframe.setAttribute(
      'src',
      `//www.googletagmanager.com/ns.html?id=${this.gtmId}`
    );
    iframe.style.width = '0';
    iframe.style.height = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    const noscript = document.createElement('noscript');
    noscript.appendChild(iframe);

    document.body.insertBefore(noscript, document.body.firstChild);
    this.isLoaded = true;
  }

  public getDataLayerRef() {
    window['dataLayer'] = window['dataLayer'] || [];
    return window['dataLayer'];
  }

  private pushOnDataLayer(obj: object) {
    const dataLayer = this.getDataLayerRef();
    dataLayer.push(obj);
  }

  public pushTag(item: GoogleTagManagerEvent) {
    if (!this.isLoaded) {
      this.addGTM();
    }
    this.pushOnDataLayer(item);
  }

  public pushTags(items: GoogleTagManagerEvent[]) {
    if (!this.isLoaded) {
      this.addGTM();
    }
    items.forEach(item => this.pushOnDataLayer(item));
  }
}
