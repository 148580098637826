import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { CompanyService } from './company.service';

@Injectable()
export class CompanyDocumentsService {

  /**
   * Base url of the API (domain)
   */
  public apiDomain: string;

  /**
   * Name of the endpoint which will be used
   */
  public endpoint: string;

  constructor(
    private http: HttpClient,
    private companyService: CompanyService
  ) {
    this.apiDomain = environment.membershipApiURL;
    this.endpoint = 'documents';
  }

  uploadLogo(formData: FormData): Observable<Document> {
    const url = `${this.apiDomain}/api/companies/${this.companyService.company.id}/logos`;
    const uploadOptions = {
      headers: new HttpHeaders({ 'uploadFile': 'true' })
    };

    return this.http.post<Document>(url, formData, uploadOptions);
  }
}
