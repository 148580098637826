import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'z-participant-agreement',
  styleUrls: ['./participant-agreement.component.scss'],
  templateUrl: './participant-agreement.component.html'
})
export class ParticipantAgreementComponent implements OnInit {

  sanitizedUrl: any;
  readonly documentUrl: string = '../../../../assets/Zing Participant Agreement 01.18.2023.pdf';

  constructor(private domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.sanitizedUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.documentUrl);
  }

}
