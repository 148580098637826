import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { PaginatedResponse } from '../models/paginated-response.model';

@Injectable()
export class BaseHttpService<T> {

  /**
   * Base url of the API (domain)
   */
  public apiDomain: string;

  /**
   * Name of the endpoint which will be used
   */
  public endpoint: string;

  constructor(
    private httpClient: HttpClient
  ) { }

  /**
   * Return full url for specific endpoint
   */
  protected getFullEndpointUrl(): string {
    return `${this.apiDomain}/api/${this.endpoint}`;
  }

  get(): Observable<T> {
    return this.httpClient.get<T>(this.getFullEndpointUrl());
  }

  /**
   * Make http request to get the items
   * @param filter Object containing filter information
   */
  query(): Observable<PaginatedResponse<T>> {
    return this.httpClient
      .get<PaginatedResponse<T>>(`${this.getFullEndpointUrl()}`);
  }

  /**
   * Make http request for specific item
   * @param id Unique identifier
   */
  getById(id: string): Observable<T> {
    return this.httpClient
      .get<T>(`${this.getFullEndpointUrl()}/${id}`);
  }

  /**
   * Make http request to create new resource on the server
   * @param body New item
   */
  save(body: T) {
    return this.httpClient
      .post(this.getFullEndpointUrl(), body);
  }

  /**
   * Make http request to update specific item
   * @param id Unique identifier
   * @param body Object which contains new data
   */
  update(id: string, body: any = {}) {
    const url = `${this.getFullEndpointUrl()}/${id}`;
    return this.httpClient
      .put(url, body);
  }

  /**
   * Make http request to delete an item
   * @param id Unique identifier
   */
  delete(id: string) {
    const url = `${this.getFullEndpointUrl()}/${id}`;
    return this.httpClient
      .delete(url);
  }

}
