import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { State } from '@progress/kendo-data-query';
import { SearchParameters } from '../shared/models';
import * as fromStore from '../store';
import { NaturalCartItem } from '../models/natural-cart';
import { filterLabTypes } from '../utils';
import { map, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceFacade {

  searchPresets$ = this.store.pipe(select(fromStore.getSearchPresets));
  searchFilterOptions$ = this.store.pipe(select(fromStore.getSearchFilterOptions));
  isSearchFilterOptionsLoading$ = this.store.pipe(select(fromStore.getIsLoading));
  isSearchFilterOptionsLoaded$ = this.store.pipe(select(fromStore.getIsLoaded));
  error$ = this.store.pipe(select(fromStore.getError));

  //#region Basic search filter option observables

  shapes$ = this.store.pipe(select(fromStore.getShapes));
  specialShapes$ = this.store.pipe(select(fromStore.getSpecialShapes));
  priceRange$ = this.store.pipe(select(fromStore.getPriceRange));
  caratValues$ = this.store.pipe(select(fromStore.getCaratValues));
  colors$ = this.store.pipe(select(fromStore.getColors));
  clarities$ = this.store.pipe(select(fromStore.getClarities));
  cutGrades$ = this.store.pipe(select(fromStore.getCutGrades));
  lightPerformances$ = this.store.pipe(select(fromStore.getLightPerformances));
  proportionFactors$ = this.store.pipe(select(fromStore.getProportionFactors));
  polishes$ = this.store.pipe(select(fromStore.getPolishes));
  symmmetries$ = this.store.pipe(select(fromStore.getSymmmetries));
  fluorescenceColors$ = this.store.pipe(select(fromStore.getFluorescenceColors));
  fluorescenceIntensities$ = this.store.pipe(select(fromStore.getFluorescenceIntensities));
  gradingLabs$ = this.store.pipe(select(fromStore.getGradingLabs));
  reportTypes$ = this.store.pipe(select(fromStore.getReportTypes));
  defaultSearchParameter$ = this.store.pipe(select(fromStore.getDefaultSearchParameters));
  naturalFancyColorIntensities$ = this.store.pipe(select(fromStore.getNaturalFancyColorIntensities));
  naturalFancyColorOvertones$ = this.store.pipe(select(fromStore.getNaturalFancyColorOvertones));
  naturalFancyColorColors$ = this.store.pipe(select(fromStore.getNaturalFancyColorColors));

  labGrownFancyColors$ = this.store.pipe(select(fromStore.getLabGrownFancyColors));

  labGrownFancyColorIntensities$ = this.store.pipe(select(fromStore.getNaturalFancyColorIntensities));

  labGrownFancyColorOvertones$ = this.store.pipe(select(fromStore.getNaturalFancyColorOvertones));
  //#endregion\

  //#region Advanced search filter option observables

  countries$ = this.store.pipe(select(fromStore.getCountries));
  culetSizes$ = this.store.pipe(select(fromStore.getCuletSizes));
  culetConditions$ = this.store.pipe(select(fromStore.getCuletConditions));
  girdles$ = this.store.pipe(select(fromStore.getGirdles));
  shades$ = this.store.pipe(select(fromStore.getShades));
  milkies$ = this.store.pipe(select(fromStore.getMilkies));
  blackInclusions$ = this.store.pipe(select(fromStore.getBlackInclusions));
  eyeCleans$ = this.store.pipe(select(fromStore.getEyeCleans));
  provenanceReports$ = this.store.pipe(select(fromStore.getProvenanceReports));

  //#endregion

  recentSearchParameters$ = this.store.pipe(select(fromStore.getSearchParameters));
  diamondSearchResults$ = this.store.pipe(select(fromStore.getDiamondSearchResults));
  diamondSearchResultsLoading$ = this.store.pipe(select(fromStore.getDiamondSearchResultsLoading));
  diamondSearchResultsLoaded$ = this.store.pipe(select(fromStore.getDiamondSearchResultsLoaded));
  compareDiamondIds$ = this.store.pipe(select(fromStore.getCompareDiamondIds));

  hasAnyDiamondSearchResults$ = this.store.pipe(select(fromStore.getHasAnyDiamondSearchResults));
  hasNoDiamondSearchResult$ = this.store.pipe(select(fromStore.getHasNoDiamondSearchResult));

  lgdCaratValues$ = this.store.pipe(select(fromStore.getLgdCaratValues));
  media$ = this.store.pipe(select(fromStore.getMedia));
  lgdBlues$ = this.store.pipe(select(fromStore.getLgdBlues));
  lgdBrowns$ = this.store.pipe(select(fromStore.getLgdBrowns));
  lgdGreens$ = this.store.pipe(select(fromStore.getLgdGreens));
  lgdGrays$ = this.store.pipe(select(fromStore.getLgdGrays));
  lgdLusters$ = this.store.pipe(select(fromStore.getLgdLusters));
  lgdOtherShades$ = this.store.pipe(select(fromStore.getLgdOtherShades));
  lgdTreatments$ = this.store.pipe(select(fromStore.getLgdTreatments));

  constructor(private store: Store<fromStore.ZingState>) { }

  getSearchPresets() {
    this.store.dispatch(new fromStore.FetchSearchPresets());
  }

  saveSearchPreset(searchPreset: any) {
    this.store.dispatch(new fromStore.AddSearchPreset(searchPreset));
  }

  deleteSearchPreset(id: string) {
    this.store.dispatch(new fromStore.DeleteSearchPreset(id));
  }

  getSearchFilterOptions() {
    this.store.dispatch(new fromStore.FetchSearchFilterOptions());
  }

  getDiamondSearchResults(searchParameters: SearchParameters) {
    this.store.dispatch(new fromStore.GetDiamondSearchResult(searchParameters));
  }

  changeResultOptions(state: State) {
    this.store.dispatch(new fromStore.ChangeResultOptions(state));
  }

  getDefaultSearchResults() {
    this.store.dispatch(new fromStore.GetDefaultSearchResults());
  }

  clearSearchResults() {
    this.store.dispatch(new fromStore.ClearSearchResults());
  }

  clearSearchOptions() {
    this.store.dispatch(new fromStore.ClearSearchOptions());
  }

  searchSimilar(cartItem: NaturalCartItem) {
    this.store.dispatch(fromStore.searchSimilar({ cartItem }));
  }

  addDiamondIdToCompare(id: number) {
    this.store.dispatch(new fromStore.AddDiamondIdToCompare(id));
  }

  removeDiamondIdToCompare(id: number) {
    this.store.dispatch(new fromStore.RemoveDiamondIdToCompare(id));
  }

  setDefaultSearchParameters(value: SearchParameters) {
    this.store.dispatch(new fromStore.SetDefaultSearchParameters(value));
  }

  isBasicShape(shape: string) {
    return this.shapes$.pipe(
      filterLabTypes(),
      map(shapes => shapes.map(s => s.shapeName.toLowerCase()).includes(shape.toLocaleLowerCase())),
      take(1));
  }
}
