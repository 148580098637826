import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'z-shopping-cart-modal',
  templateUrl: './shopping-cart-modal.component.html',
  styleUrls: ['./shopping-cart-modal.component.scss']
})
export class ShoppingCartModalComponent implements OnInit, OnDestroy {
  title: string;
  content: string;

  showContinueShoppingButton = true;

  constructor(
    public bsModalRef: BsModalRef,
    private renderer: Renderer2,
    private router: Router
  ) { }

  ngOnInit() {
    this.renderer.addClass(document.body, 'cart-modal-open');
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'cart-modal-open');
  }

  onCheckOut() {
    this.bsModalRef.hide();
    this.router.navigateByUrl('/marketplace/shoppingcart');
  }

  onContinueShopping() {
    this.bsModalRef.hide();
  }
}
