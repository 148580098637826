<div class="modal-header" [ngClass]="config.headerClass ?? 'mb-2'">
  <h4 class="modal-title roboto-condensed-bold font-24" [innerHTML]="config.headerText | translate : config.translationData"></h4>
  <i *ngIf="!config.hideCloseButton" class="mdi mdi-close font-20 cursor-pointer float-end" (click)="onCloseModal()"></i>
</div>

<div [class]="config.bodyContainerClass">
  <i [ngClass]="config.bodyIcon"></i>
  <div [ngClass]="config.bodyTextClass ?? 'px-3 py-2 modal-text'" [innerHTML]="config.bodyText | translate : config.translationData"></div>
</div>

<div class="float-end pr-2 mt-2" *ngIf="!config.hideButtons">
  <button class="btn w-auto mb-2 modal-btn" [ngClass]="!config.inverted ? config.continueButtonClass : config.cancelButtonClass"
    type="button" (click)="!config.inverted ? onAgree() : onCloseModal()">
    {{ (!config.inverted ? config.continueButtonText : config.cancelButtonText) | translate : config.translationData }}
  </button>
  <button class="btn w-auto ml-2 mb-2 modal-btn" [ngClass]="!config.inverted ? config.cancelButtonClass : config.continueButtonClass"
    type="button" (click)="!config.inverted ? onCloseModal() : onAgree()">
    {{ (!config.inverted ? config.cancelButtonText : config.continueButtonText) | translate : config.translationData }}
  </button>
</div>

<z-spinner class="wholesale-spinner" [inProgress]="config.showSpinner$ | async"></z-spinner>
