export enum PaidStatementsColumnName {
  StatementNumber = 1,
  StatementDate = 2,
  TransactionsNumber = 3,
  StatementAmount = 4
}

export enum TransactionColumnName {
  ShipmentId = 1,
  ClientId = 2,
  Type = 3,
  TransactionDate = 4,
  Recipient = 5,
  Service = 6,
  TrackingNumber = 7,
  Amount = 8
}
