import { Action } from '@ngrx/store';
import { PersonalDetails, CompanyDetails, SecurityOptions } from '../reducers/register.reducer';
import { Invite } from '../../../models/invite';

export enum RegisterActionTypes {
  ChangeCurrentStep = '[Register] Change Current Step',
  VerifyInviteCode = '[Register] Verify Invite Code',
  VerifyInviteCodeSuccess = '[Register] Verify Invite Code Success',
  VerifyInviteCodeFail = '[Register] Verify Invite Code Fail',
  LoadSecurityQuestions = '[Register] Load Security Questions',
  LoadSecurityQuestionsSuccess = '[Register] Load Security Questions Success',
  LoadSecurityQuestionsFail = '[Register] Load Security Questions Fail',
  SavePersonalDetails = '[Register] Save Personal Details',
  SaveCompanyDetails = '[Register] Save Company Details',
  SaveSecurityOptions = '[Register] Save Security Options',
  LoadTerms = '[Register] Load Terms',
  LoadTermsSuccess = '[Register] Load Terms Success',
  LoadTermsFail = '[Register] Load Terms Fail',
  CheckCompanyExists = '[Register] Check Company Exists',
  CheckCompanyExistsSuccess = '[Register] Check Company Exists Success',
  CheckCompanyExistsFail = '[Register] Check Company Exists Fail',
  SearchCompanies = '[Register] Search Companies',
  SearchCompaniesSuccess = '[Register] Search Companies Success',
  SearchCompaniesFail = '[Register] Search Companies Fail',
  CreateNewUser = '[Register] Create New User',
  CreateNewUserSuccess = '[Register] Create New User Success',
  CreateNewUserFail = '[Register] Create New User Fail',
  LoadExistingUserDetails = '[Register] Load Existing User Details',
  LoadExistingUserDetailsSuccess = '[Register] Load Existing User Details Success',
  LoadExistingUserDetailsFail = '[Register] Load Existing User Details Fail',
  LoadPartnerUserDetails = '[Register] Load Partner User Details',
  LoadPartnerUserDetailsSuccess = '[Register] Load Partner User Details Success',
  LoadPartnerUserDetailsFail = '[Register] Load Partner User Details Fail',
  SaveNewRegisterCompany = '[Register] Save New Register Company',
  DisplayRegistrationBanner = '[Register] Display Registration Banner',
  CreateNewUserViaInvite = "CreateNewUserViaInvite",
  CreateNewUserViaInviteSuccess = "CreateNewUserViaInviteSuccess",
  CreateNewUserViaInviteFail = "CreateNewUserViaInviteFail"
}

export class SaveNewRegisterCompany implements Action {
  readonly type = RegisterActionTypes.SaveNewRegisterCompany;
  constructor(public payload: any) {}
}
export class ChangeCurrentStep implements Action {
  readonly type = RegisterActionTypes.ChangeCurrentStep;
  constructor(public payload: string) {}
}

export class VerifyInviteCode implements Action {
  readonly type = RegisterActionTypes.VerifyInviteCode;
  constructor(public payload: string) { }
}

export class VerifyInviteCodeSuccess implements Action {
  readonly type = RegisterActionTypes.VerifyInviteCodeSuccess;
  constructor(public payload: Invite) { }
}

export class VerifyInviteCodeFail implements Action {
  readonly type = RegisterActionTypes.VerifyInviteCodeFail;
  constructor(public payload: any) { }
}

export class LoadSecurityQuestions implements Action {
  readonly type = RegisterActionTypes.LoadSecurityQuestions;
}

export class LoadSecurityQuestionsSuccess implements Action {
  readonly type = RegisterActionTypes.LoadSecurityQuestionsSuccess;
  constructor(public payload: any) { }
}

export class LoadSecurityQuestionsFail implements Action {
  readonly type = RegisterActionTypes.LoadSecurityQuestionsFail;
  constructor(public payload: any) { }
}

export class SavePersonalDetails implements Action {
  readonly type = RegisterActionTypes.SavePersonalDetails;
  constructor(public payload: PersonalDetails) { }
}

export class SaveCompanyDetails implements Action {
  readonly type = RegisterActionTypes.SaveCompanyDetails;
  constructor(public payload: CompanyDetails) { }
}

export class SaveSecurityOptions implements Action {
  readonly type = RegisterActionTypes.SaveSecurityOptions;
  constructor(public payload: SecurityOptions) { }
}

export class LoadTerms implements Action {
  readonly type = RegisterActionTypes.LoadTerms;
}

export class LoadTermsSuccess implements Action {
  readonly type = RegisterActionTypes.LoadTermsSuccess;
  constructor(public payload: any) { }
}

export class LoadTermsFail implements Action {
  readonly type = RegisterActionTypes.LoadTermsFail;
  constructor(public payload: any) { }
}

export class CheckCompanyExists implements Action {
  readonly type = RegisterActionTypes.CheckCompanyExists;
  constructor(public payload: string) { }
}

export class CheckCompanyExistsSuccess implements Action {
  readonly type = RegisterActionTypes.CheckCompanyExistsSuccess;
  constructor(public payload: boolean) { }
}

export class CheckCompanyExistsFail implements Action {
  readonly type = RegisterActionTypes.CheckCompanyExistsFail;
  constructor(public payload: any) { }
}

export class SearchCompanies implements Action {
  readonly type = RegisterActionTypes.SearchCompanies;
  constructor(public payload: any) { }
}

export class SearchCompaniesSuccess implements Action {
  readonly type = RegisterActionTypes.SearchCompaniesSuccess;
  constructor(public payload: any[]) { }
}

export class SearchCompaniesFail implements Action {
  readonly type = RegisterActionTypes.SearchCompaniesFail;
  constructor(public payload: any) { }
}

export class CreateNewUser implements Action {
  readonly type = RegisterActionTypes.CreateNewUser;
  constructor(public payload: any) { }
}

export class CreateNewUserSuccess implements Action {
  readonly type = RegisterActionTypes.CreateNewUserSuccess;
  constructor(public payload: any) { }
}

export class CreateNewUserFail implements Action {
  readonly type = RegisterActionTypes.CreateNewUserFail;
  constructor(public payload: any) { }
}

export class LoadExistingUserDetails implements Action {
  readonly type = RegisterActionTypes.LoadExistingUserDetails;
  constructor(public payload: string) { }
}

export class LoadExistingUserDetailsSuccess implements Action {
  readonly type = RegisterActionTypes.LoadExistingUserDetailsSuccess;
  constructor(public payload: any) { }
}
export class LoadExistingUserDetailsFail implements Action {
  readonly type = RegisterActionTypes.LoadExistingUserDetailsFail;
  constructor(public payload: any) { }
}

export class LoadPartnerUserDetails implements Action {
  readonly type = RegisterActionTypes.LoadPartnerUserDetails;
  constructor(public payload: any) { }
}

export class LoadPartnerUserDetailsSuccess implements Action {
  readonly type = RegisterActionTypes.LoadPartnerUserDetailsSuccess;
  constructor(public payload: any) { }
}
export class LoadPartnerUserDetailsFail implements Action {
  readonly type = RegisterActionTypes.LoadPartnerUserDetailsFail;
  constructor(public payload: any) { }
}

export class DisplayRegistrationBanner implements Action {
  readonly type = RegisterActionTypes.DisplayRegistrationBanner;
  constructor(public payload: boolean) { }
}

export class CreateNewUserViaInvite implements Action {
  readonly type = RegisterActionTypes.CreateNewUserViaInvite;
  constructor(public payload: any) { }
}

export class CreateNewUserViaInviteSuccess implements Action {
  readonly type = RegisterActionTypes.CreateNewUserViaInviteSuccess;
  constructor(public payload: any) { }
}

export class CreateNewUserViaInviteFail implements Action {
  readonly type = RegisterActionTypes.CreateNewUserViaInviteFail;
  constructor(public payload: any) { }
}

export type RegisterActions = ChangeCurrentStep
| VerifyInviteCode
| VerifyInviteCodeSuccess
| VerifyInviteCodeFail
| LoadSecurityQuestions
| LoadSecurityQuestionsSuccess
| LoadSecurityQuestionsFail
| SavePersonalDetails
| SaveCompanyDetails
| SaveSecurityOptions
| LoadTerms
| LoadTermsSuccess
| LoadTermsFail
| CheckCompanyExists
| CheckCompanyExistsSuccess
| CheckCompanyExistsFail
| SearchCompanies
| SearchCompaniesSuccess
| SearchCompaniesFail
| CreateNewUser
| CreateNewUserSuccess
| CreateNewUserFail
| LoadExistingUserDetails
| LoadExistingUserDetailsSuccess
| LoadExistingUserDetailsFail
| LoadPartnerUserDetails
| LoadPartnerUserDetailsSuccess
| LoadPartnerUserDetailsFail
| SaveNewRegisterCompany
| DisplayRegistrationBanner
| CreateNewUserViaInvite
| CreateNewUserViaInviteSuccess
| CreateNewUserViaInviteFail;
