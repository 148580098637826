import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../../environments/environment';
import { UserDataService } from './user-data.service';
import { AccountUserSettings } from '../modules/account/models/account-user-settings.model';

@Injectable()
export class AccountUserDataService extends UserDataService<AccountUserSettings> {

  protected readonly apiUrl = environment.accountApiUrl;

  constructor(protected http: HttpClient) {
    super(http);
  }

  canAccessAccounts() {
    return this.userData && this.userData.canAccessAccounts;
  }

}
