import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseHttpService } from '../../shared/services/base-http.service';
import { environment } from '../../../../environments/environment';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { AppConfigurationService } from './app-configuration.service';
import { FeatureName } from '../../shared/enums/feature-name';

export interface ForbiddenWord {
  id?: string;
  word: string;
  excludedClients?: string | Array<string>;
}

@Injectable()
export class ForbiddenWordsService extends BaseHttpService<ForbiddenWord> {

  private _forbiddenWords: Array<ForbiddenWord>;
  private _applyOnlyLetters: boolean;
  originForbiddenWords = new Array<{ field: string; locationTypeInput: string; words: Set<string> }>();
  originForbiddenWords$ = new BehaviorSubject<any>(this.originForbiddenWords);
  destinationForbiddenWords = new Array<{ field: string; locationTypeInput: string; words: Set<string> }>();
  destinationForbiddenWords$ = new BehaviorSubject<any>(this.originForbiddenWords);
  shipmentDetailsForbiddenWords = new Array<{ field: string; locationTypeInput: string; words: Set<string> }>();
  shipmentDetailsForbiddenWords$ = new BehaviorSubject<any>(this.shipmentDetailsForbiddenWords);

  /**
   * Returns the array of forbidden words as an array of strings
   */
  public get forbiddenWords(): Array<string> {
    if (!this._forbiddenWords) {
      return [];
    } else {
      return this._forbiddenWords
        .map(item => item.word);
    }
  }

  /**
   * Returns the feature flag value as boolean
   */
  public get applyOnlyLetters(): boolean {
    return this._applyOnlyLetters;
  }

  constructor(
    private http: HttpClient,
    private appConfigurationService: AppConfigurationService
  ) {
    super(http);
    this.apiDomain = environment.shippingApiURL;
    this.endpoint = 'forbiddenwords';
  }

  /**
   * Try getting all forbidden words
   */
  public getAll(): Observable<Array<ForbiddenWord>> {
    if (this._forbiddenWords) {
      return of(this._forbiddenWords);
    } else {
      return this.http
        .get<Array<ForbiddenWord>>(`${this.getFullEndpointUrl()}`)
        .pipe(
          tap(words => this._forbiddenWords = words)
        );
    }
  }

  public getOnlyLettersFlag() {
    return this.appConfigurationService.getFeatureFlag(FeatureName.ZingShippingForbiddenWordsOnlyLetters)
      .pipe(
        take(1),
        tap(applyOnlyLetters => this._applyOnlyLetters = applyOnlyLetters)
      );
  }

  public handleForbiddenWords(words: string[], locationType: string, field: string) {
    if (words.length === 0) {
      this[`${locationType}ForbiddenWords`] = this[`${locationType}ForbiddenWords`].filter(word => word.field !== field);
      this[`${locationType}ForbiddenWords$`].next(this[`${locationType}ForbiddenWords`]);
      return;
    }
    if (this[`${locationType}ForbiddenWords`].length === 0) {
      this[`${locationType}ForbiddenWords`].push({ field, locationTypeInput: locationType, words });
      this[`${locationType}ForbiddenWords$`].next(this[`${locationType}ForbiddenWords`]);
    } else {
      const fieldIndex = this[`${locationType}ForbiddenWords`].findIndex(word => word.field === field);
      if (fieldIndex > -1) {
        this[`${locationType}ForbiddenWords`][fieldIndex].words = words;
      } else {
        this[`${locationType}ForbiddenWords`].push({ field, locationTypeInput: locationType, words });
      }
      this[`${locationType}ForbiddenWords$`].next(this[`${locationType}ForbiddenWords`]);
    }
  }

  public resetForbiddenWords(locationType: string) {
    this[`${locationType}ForbiddenWords`] = [];
    this[`${locationType}ForbiddenWords$`].next([]);
  }

}
