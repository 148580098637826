import { Component, Input } from '@angular/core';
import { fadeOut } from './animations/app.animations';

@Component({
  selector: 'z-starting',
  template: `
    <div *ngIf="starting && !showJmconnect" [@fadeOut]
      id="startingDiv"
      class="starting d-flex flex-column justify-content-center align-items-center">
        <img src="/assets/icons/icon-logo-white.svg" height="120" alt="zing logo" />
    </div>
    <!-- this code is added to hide branding on jmconnect page -->
    <div *ngIf="starting && showJmconnect"
      id="startingDivJmconnect"
      class="startingJmconnect d-flex flex-column justify-content-center align-items-center">
    </div>
  `,
  styles: [`
    @keyframes fadeInAndOut {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.05;
      }
      100% {
        opacity: 1;
      }
    }

    .starting img {
      animation: fadeInAndOut 1.5s infinite;
    }
  `],
  animations: [fadeOut()]
})
export class StartingComponent {
  @Input() starting = true;
  showJmconnect = true;

  constructor() {
    // this code is added to hide branding on jmconnect page
    const currentUrl = window.location.href;
    if (!currentUrl.includes('/jmconnect')) {
      this.showJmconnect = false;
    }
  }
}
