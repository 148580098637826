import { PaymentMethodType } from '../../../order/models';
import { ShippingMethodType } from '../../../shopping-cart/models/shipping-method-type';
import { NaturalShoppingCart } from '../../models/natural-cart';
import { Lookup } from '../../shared/models/search-filter-options';
import { getCartInformation } from '../../shared/utils/cart-helpers';
import { ShoppingCart, CartCheckout, ShoppingCartDetail } from '../models';

export function hasMultipleAvailableShippingMethods(cart: ShoppingCart) {
  return !!cart.totalStandardShippingHandlingAmount
    && !!cart.totalExpeditedShippingHandlingAmount;
}

export function getAvailableSupplierItemIdentifiers(cart: ShoppingCart) {
  return cart.items
    .filter(i => i.availableIndicator)
    .map(i => i.supplierItemIdentifier);
}

export function getCartCheckout(shippingMethod: ShippingMethodType,
  locationID: string,
  paymentMethod: PaymentMethodType): CartCheckout {
  return {
    locationID,
    shippingAndHandlingMethodType: shippingMethod,
    paymentMethodType: paymentMethod
  };
}

export function mapShoppingCart(cart: ShoppingCart, reportTypes: Lookup[]): ShoppingCart {
  cart.items.forEach(item => mapCartItem(item, reportTypes));
  return cart;
}

function mapCartItem(cartItem: ShoppingCartDetail, reportTypes: Lookup[]) {
  if (cartItem.reportType) {
    cartItem.reportType = reportTypes.filter
    (x => x.code === cartItem.reportType || x.description === cartItem.reportType).map(x => x.description).pop();
  }
}

export function getCartDiscountAmount(cart: NaturalShoppingCart, paymentMethod?: PaymentMethodType) {
  const cartInformation = getCartInformation(cart);

  if (paymentMethod === null
      || paymentMethod === undefined
      || paymentMethod === PaymentMethodType.CreditCard
      || cartInformation.shippingMethodUsed === ShippingMethodType.None) {
    return 0;
  }

  return paymentMethod === PaymentMethodType.WireTransfer
    ? cartInformation.totalMarkupWireDiscountAmount
    : cartInformation.totalMarkupAchDiscountAmount;
}
