import { AccountService } from './account.service';
import { RiskService } from './risk.service';
import { TermsAndConditionsService } from './terms-and-conditions.service';
import { VerificationService } from './verification.service';
import { AnalyticsService } from './analytics.service';
import { AddressService } from './address.service';
import { ReportingService } from '../../core/services/reporting.service';
import { PartnerService } from './partner.service';
import { ServiceModulesService } from './service-modules.service';

export * from './account.service';
export * from './company.service';
export * from './company-documents.service';
export * from './profile.service';
export * from './risk.service';
export * from './terms-and-conditions.service';
export * from './verification.service';
export * from './address.service';
export * from './partner.service';
export * from './loading-spinner.service';
export * from './service-modules.service';

export const services: any[] = [
  AccountService,
  RiskService,
  TermsAndConditionsService,
  VerificationService,
  AnalyticsService,
  AddressService,
  ReportingService,
  PartnerService,
  ServiceModulesService
];

