import { Injectable } from '@angular/core';

@Injectable()
export class ConfigurationService {

  get highContrastClass() {
    return 'contrast-theme';
  }

  getDefaultCountryCode() {
    return '+1';
  }

  getDefaultCountry() {
    return 'US';
  }

}
