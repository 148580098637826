import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { LoginResponse, OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, iif, of } from 'rxjs';
import { catchError, delayWhen, map, switchMap, take, tap } from 'rxjs/operators';
import { MarketplaceFacade } from '../modules/marketplace/services';
import { DEFAULT_SEARCH_PARAMETERS, DEFAULT_SEARCH_PARAMETERS_AGS } from '../modules/marketplace/shared/config';
import { MarketplacePartnerServicePermission } from '../modules/membership/modules/account/models/marketplace-partner-service-permission';
import { Person } from '../modules/membership/modules/account/models/person.model';
import { MarketplacePartnerAffiliationType } from '../modules/shared/enums/marketplace-partner-affiliation-types';
import { CompanyService, PartnerService, ProfileService } from '../modules/shared/services';

@Injectable({
  providedIn: 'root'
})
export class ProfilesPreloadGuard implements CanActivate {

  constructor(
    private oidcSecurityService: OidcSecurityService,
    private profileService: ProfileService,
    private companyService: CompanyService,
    private partnerService: PartnerService,
    private marketplaceFacade: MarketplaceFacade,
  ) { }

  canActivate(): Observable<boolean> {
    return this.oidcSecurityService.checkAuth().pipe(
      take(1),
      map((response: LoginResponse) => response.isAuthenticated),
      delayWhen(isAuthenticated => iif(() => isAuthenticated, this.getPersonInfo(), of(false))),
    );
  }

  getPersonInfo(): Observable<boolean> {
    return this.profileService.getProfileInfo().pipe(
      take(1),
      tap(person => {
        const agsPermission = this.getAGSPartner(person);
        let searchParameters = DEFAULT_SEARCH_PARAMETERS;

        if (!!agsPermission) {
          this.validateAGSMembership();

          if (this.partnerService.checkIfPartnerParmissionIsAGSAndActive(agsPermission)) {
            searchParameters = DEFAULT_SEARCH_PARAMETERS_AGS;
          }
        }

        this.marketplaceFacade.setDefaultSearchParameters(searchParameters);
      }),
      switchMap(person => iif(() => !!person.companyId, this.getCompanyInfo(), of(true))),
      catchError(() => of(true))
    );
  }

  getCompanyInfo(): Observable<boolean> {
    return this.companyService.getCompanyProfile().pipe(
      take(1),
      map(() => true),
      catchError(() => of(true))
    );
  }

  private getAGSPartner(person: Person): MarketplacePartnerServicePermission {
    return this.partnerService.getPartnerPermission(
      MarketplacePartnerAffiliationType.AGS,
      person,
      true);
  }

  private validateAGSMembership() {
    this.partnerService.validatePartner(MarketplacePartnerAffiliationType[MarketplacePartnerAffiliationType.AGS])
      .pipe(take(1))
      .subscribe();
  }
}
