import { Action } from '@ngrx/store';

import { Cargo } from '../../models/shipment-cargo';
import { ShipmentDetails,
  ShipmentCarriers, ShipmentReference,
  PurchaseDetails, ShippingTime, BoomerangDetails } from '../reducers/shipping.reducer';
import {
  ShipmentLocation, OriginalShipmentDetails,
  ShipmentActionType, ShippingPreset, QuickQuote
} from '../../models/base-shipment-models';
import { NameValuePair } from '../../models/name-value-pair.model';
import { PackageType } from '../../models/shipment-package-type.model';
import { BillingReview } from '../../models/billing-review-model';
import { Rules } from '../../models/rules.model';
import { InternationalShipmentData } from '../../models/international-shipments-data.model';
import { IntegratedAccountDetails } from '../../models/integrated-account-details-model';

export enum ShippingActionTypes {
  SetInitialState = '[Shipping] Set Initial State',
  LoadCargo = '[Shipping] Load Cargo',
  LoadCargoSuccess = '[Shipping] Load Cargo Success',
  LoadCargoFail = '[Shipping] Load Cargo Fail',
  LoadPackageTypes = '[Shipping] Load Package Types',
  LoadPackageTypesSuccess = '[Shipping] Load Package Type Success',
  LoadPackageTypesFail = '[Shipping] Load Package Type Fail',
  LoadCarriers = '[Shipping] Load Carriers',
  LoadCarriersSuccess = '[Shipping] Load Carriers Success',
  LoadCarriersFail = '[Shipping] Load Carriers Fail',
  LoadLookup = '[Shipping] Load Lookup',
  LoadLookupSuccess = '[Shipping] Load Lookup Success',
  LoadLookupFail = '[Shipping] Load Lookup Fail',
  LoadInternationalShipmentsData = '[Shipping] Load International Shipments Data',
  LoadInternationalShipmentsDataSuccess = '[Shipping] Load International Shipments Data Success',
  LoadInternationalShipmentsDataFail = '[Shipping] Load International Shipments Data Fail',
  SaveShipmentDetails = '[Shipping] Save Shipment Details',
  SaveShipmentCarriers = '[Shipping] Save Shipment Carriers',
  SaveOriginLocation = '[Shipping] Save Origin Location',
  SaveDestinationLocation = '[Shipping] Save Destination Location',
  SaveShipmentReference = '[Shipping] Save Shipment Reference',
  ResetShipment = '[Shipping] Reset Shipment',
  SaveShipment = '[Shipping] Save Shipment',
  SaveShipmentSuccess = '[Shipping] Save Shipment Success',
  SaveShipmentFail = '[Shipping] Save Shipment Fail',
  SaveQuoteId = '[Shipping] Save QuoteId',
  SaveSelectedQuote = '[Shipping] Save Selected Quote',
  SaveInternationalDetail = '[Shipping] Save International Detail',
  PurchaseShipment = '[Shipping] Purchase Shipment',
  SavePurchaseDetails = '[Shipping] Purchase Shipment Success',
  ShipmentRequestsApproval = '[Shipping] Shipment Requests Approval',
  PurchaseShipmentFail = '[Shipping] Purchase Shipment Fail',
  ProcessQuoteOptions = '[Shipping] Process Quote Options',
  ProcessQuoteOptionsSuccess = '[Shipping] Process Quote Options Success',
  ProcessQuoteOptionsFail = '[Shipping] Process Quote Options Fail',
  SaveShipmentId          = '[Shipping] Save ShipmentId',
  SaveShipmentName        = '[Shipping] Save ShipmentName',
  SaveShippingDate        = '[Shipping] Save ShippingDate',
  SaveOriginalShipmentDetails = '[Shipping] Save Original Shipment Details',
  SaveShipmentActionType = '[Shipping] Save Shipment Action Type',
  CreateClaim = '[Shipping] Create Claim',
  CreateClaimFail = '[Shipping] Create Claim',
  SaveClaim = '[Shipping] Save Claim',
  ResetClaim = '[Shipping] Reset Claim',
  SaveClaimDocuments = '[Shipping] Save Claim Documents',
  SaveBillingReview = '[Shipping] Save Billing Review',
  SaveBillingReviewSuccess = '[Shipping] Save Billing Review Success',
  SaveBillingReviewFail = '[Shipping] Save Billing Review Fail',
  ClearBillingRequestNotifications = '[Shipping] Clear Billing Request Notifications',
  AddApprovalContact = '[Shipping] Add Approval Contact',
  PopulateFromPreset = '[Shipping] Populate From Preset',
  PopulateEditPresetFromPreset = '[Shipping] Populate Edit Preset From Preset',
  PopulateFromPresetSuccess = '[Shipping] Populate From Preset Success',
  PopulateFromQuickQuote = '[Shipping] Populate From Quick Quote',
  PurchaseBoomerangShipment = '[Shipping] Purchase Boomerang Shipment',
  SavedBoomerangShipment = '[Shipping] Saved Boomerang Shipment',
  LoadPresets =         '[Shipping] Load Presets',
  LoadPresetsSuccess =  '[Shipping] Load Presets Success',
  LoadPresetsFail =     '[Shipping] Load Presets Fail',
  CreatePreset =        '[Shipping] Create Preset',
  CreatePresetSuccess = '[Shipping] Create Preset Success',
  CreatePresetFail =    '[Shipping] Create Preset Fail',
  DefaultPreset = '[Shipping] Default Preset',
  DefaultPresetSuccess = '[Shipping] Default Preset Success',
  DefaultPresetFail = '[Shipping] Default Preset Fail',
  UpdatePreset =        '[Shipping] Update Preset',
  UpdatePresetSuccess = '[Shipping] Update Preset Success',
  UpdatePresetFail =    '[Shipping] Update Preset Fail',
  DeletePreset =        '[Shipping] Delete Preset',
  DeletePresetSuccess = '[Shipping] Delete Preset Success',
  DeletePresetFail =    '[Shipping] Delete Preset Fail',
  LoadRules =           '[Shipping] Load Rules',
  LoadRulesSuccess =    '[Shipping] Load Rules Success',
  LoadRulesFail =       '[Shipping] Load Rules Fail',
  LoadIntegratedAccountDetails = '[Shipping] Load Integrated Account Details',
  LoadIntegratedAccountDetailsSuccess = '[Shipping] Load Integrated Account Details Success',
  LoadIntegratedAccountDetailsFail = '[Shipping] Load Integrated Account Details Fail',
  ClearError =          '[Shipping] Clear Error',
  SaveIsInternational = '[Shipping] Save Is International',
  SaveIsHoldForPickup = '[Shipping] Save Is Hold For Pickup',
  SaveHoldForPickupAddress = '[Shipping] Save Hold For Pickup Address',
  SaveNoInsurance =     '[Shipping] Save No Insurance',
  SaveInsuranceOnlyTrackingNumber = '[Shipping] Save Insurance Only Tracking Number',
  SaveInsuranceOnly =   '[Shipping] Save Insurance Only',
  SaveHasApprovalCodeError = '[Shipping] Save Has Approval Code Error',
  SaveQuoteError = '[Shipping] Save Quote Error',
  SaveZeroDollarInsuranceWarningPrompted = '[Shipping] Save Zero Dollar Insurance Warning Prompted',
  ClearShipmentDetails =          '[Shipping] Clear Shipment Details',
  LoadEStoreData = '[Shipping] Load EStore Data'
}

export class SetInitialState implements Action {
  readonly type = ShippingActionTypes.SetInitialState;
  constructor(public payload: any) { }
}

export class LoadCargo implements Action {
  readonly type = ShippingActionTypes.LoadCargo;
}

export class LoadCargoSuccess implements Action {
  readonly type = ShippingActionTypes.LoadCargoSuccess;
  constructor(public payload: Cargo[]) { }
}

export class LoadCargoFail implements Action {
  readonly type = ShippingActionTypes.LoadCargoFail;
  constructor(public payload: any) { }
}

export class LoadLookup implements Action {
  readonly type = ShippingActionTypes.LoadLookup;
  constructor(public payload: string) { }
}

export class LoadLookupSuccess implements Action {
  readonly type = ShippingActionTypes.LoadLookupSuccess;
  constructor(public payload: {
    type: string,
    items: NameValuePair[]
  }) { }
}

export class LoadLookupFail implements Action {
  readonly type = ShippingActionTypes.LoadLookupFail;
  constructor(public payload: any) { }
}

export class LoadInternationalShipmentsData implements Action {
  readonly type = ShippingActionTypes.LoadInternationalShipmentsData;
}

export class LoadInternationalShipmentsDataSuccess implements Action {
  readonly type = ShippingActionTypes.LoadInternationalShipmentsDataSuccess;
  constructor(public payload: InternationalShipmentData) { }
}

export class LoadInternationalShipmentsDataFail implements Action {
  readonly type = ShippingActionTypes.LoadInternationalShipmentsDataFail;
  constructor(public payload: any) { }
}

export class LoadPackageTypes implements Action {
  readonly type = ShippingActionTypes.LoadPackageTypes;
}

export class LoadPackageTypesSuccess implements Action {
  readonly type = ShippingActionTypes.LoadPackageTypesSuccess;
  constructor(public payload: PackageType[]) { }
}

export class LoadPackageTypesFail implements Action {
  readonly type = ShippingActionTypes.LoadPackageTypesFail;
  constructor(public payload: any) { }
}

export class LoadCarriers implements Action {
  readonly type = ShippingActionTypes.LoadCarriers;
}

export class LoadCarriersSuccess implements Action {
  readonly type = ShippingActionTypes.LoadCarriersSuccess;
  constructor(public payload: any[]) { }
}

export class LoadCarriersFail implements Action {
  readonly type = ShippingActionTypes.LoadCarriersFail;
  constructor(public payload: any) { }
}

export class SaveShipmentDetails implements Action {
  readonly type = ShippingActionTypes.SaveShipmentDetails;
  constructor(public payload: ShipmentDetails) { }
}

export class SaveShipmentCarriers implements Action {
  readonly type = ShippingActionTypes.SaveShipmentCarriers;
  constructor(public payload: ShipmentCarriers) { }
}

export class SaveOriginLocation implements Action {
  readonly type = ShippingActionTypes.SaveOriginLocation;
  constructor(public payload: ShipmentLocation) { }
}

export class SaveDestinationLocation implements Action {
  readonly type = ShippingActionTypes.SaveDestinationLocation;
  constructor(public payload: ShipmentLocation) { }
}

export class SaveShipmentReference implements Action {
  readonly type = ShippingActionTypes.SaveShipmentReference;
  constructor(public payload: ShipmentReference) { }
}

export class ResetShipment implements Action {
  readonly type = ShippingActionTypes.ResetShipment;
  constructor(public resetInsuranceOnlyFlag: boolean = true) { }
}

export class SaveShipment implements Action {
  readonly type = ShippingActionTypes.SaveShipment;
  constructor(public payload: any) { }
}

export class SaveShipmentSuccess implements Action {
  readonly type = ShippingActionTypes.SaveShipmentSuccess;
}

export class SaveShipmentFail implements Action {
  readonly type = ShippingActionTypes.SaveShipmentFail;
}

export class SaveQuoteId implements Action {
  readonly type = ShippingActionTypes.SaveQuoteId;
  constructor(public payload: any) { }
}

export class SaveInternationalDetail implements Action {
  readonly type = ShippingActionTypes.SaveInternationalDetail;
  constructor(public payload: any) { }
}

export class SaveShipmentId implements Action {
  readonly type = ShippingActionTypes.SaveShipmentId;
  constructor(public payload: any) { }
}

export class SaveShipmentName implements Action {
  readonly type = ShippingActionTypes.SaveShipmentName;
  constructor(public payload: any) { }
}

export class SaveSelectedQuote implements Action {
  readonly type = ShippingActionTypes.SaveSelectedQuote;
  constructor(public payload: any) { }
}

export class PurchaseShipment implements Action {
  readonly type = ShippingActionTypes.PurchaseShipment;
  constructor(public payload?: any) { }
}

export class PurchaseShipmentSuccess implements Action {
  readonly type = ShippingActionTypes.SavePurchaseDetails;
  constructor(public payload: PurchaseDetails) { }
}

export class ShipmentRequestsApproval implements Action {
  readonly type = ShippingActionTypes.ShipmentRequestsApproval;
}

export class PurchaseShipmentFail implements Action {
  readonly type = ShippingActionTypes.PurchaseShipmentFail;
  constructor(public payload: any) { }
}

export class ProcessQuoteOptions implements Action {
  readonly type = ShippingActionTypes.ProcessQuoteOptions;
}

export class ProcessQuoteOptionsSuccess implements Action {
  readonly type = ShippingActionTypes.ProcessQuoteOptionsSuccess;
  constructor(public payload: any) { }
}

export class ProcessQuoteOptionsFail implements Action {
  readonly type = ShippingActionTypes.ProcessQuoteOptionsFail;
  constructor(public payload: any) { }
}

export class SaveShippingDate implements Action {
  readonly type = ShippingActionTypes.SaveShippingDate;
  constructor(public payload: ShippingTime) { }
}

export class SaveOriginalShipmentDetails implements Action {
  readonly type = ShippingActionTypes.SaveOriginalShipmentDetails;
  constructor(public payload: OriginalShipmentDetails) { }
}

export class SaveShipmentActionType implements Action {
  readonly type = ShippingActionTypes.SaveShipmentActionType;
  constructor(public payload: ShipmentActionType) { }
}


export class CreateClaim implements Action {
  readonly type = ShippingActionTypes.CreateClaim;
  constructor(public payload: any) { }
}
export class SaveClaim implements Action {
  readonly type = ShippingActionTypes.SaveClaim;
  constructor(public payload: any) { }
}

export class SaveClaimDocuments implements Action {
  readonly type = ShippingActionTypes.SaveClaimDocuments;
  constructor(public payload: any) { }
}

export class ResetClaim implements Action {
  readonly type = ShippingActionTypes.ResetClaim;
}

export class SaveBillingReview implements Action {
  readonly type = ShippingActionTypes.SaveBillingReview;
  constructor(public payload: BillingReview) { }
}

export class SaveBillingReviewSuccess implements Action {
  readonly type = ShippingActionTypes.SaveBillingReviewSuccess;
}

export class SaveBillingReviewFail implements Action {
  readonly type = ShippingActionTypes.SaveBillingReviewFail;
}

export class ClearBillingReviewNotifications implements Action {
  readonly type = ShippingActionTypes.ClearBillingRequestNotifications;
}

export class AddApprovalContact implements Action {
  readonly type = ShippingActionTypes.AddApprovalContact;
  constructor(public payload: any) { }
}

export class PopulateFromPreset implements Action {
  readonly type = ShippingActionTypes.PopulateFromPreset;
  constructor(public payload: ShippingPreset) { }
}

export class PopulateEditPresetFromPreset implements Action {
  readonly type = ShippingActionTypes.PopulateEditPresetFromPreset;
  constructor(public payload: ShippingPreset) { }
}

export class PopulateFromPresetSuccess implements Action {
  readonly type = ShippingActionTypes.PopulateFromPresetSuccess;
}

export class PopulateFromQuickQuote implements Action {
  readonly type = ShippingActionTypes.PopulateFromQuickQuote;
  constructor(public payload: QuickQuote) { }
}

export class PurchaseBoomerangShipment implements Action {
  readonly type = ShippingActionTypes.PurchaseBoomerangShipment;
}

export class SavedBoomerangShipment implements Action {
  readonly type = ShippingActionTypes.SavedBoomerangShipment;
  constructor(public payload: BoomerangDetails) { }
}

export class LoadPresets implements Action {
    readonly type = ShippingActionTypes.LoadPresets;
}

export class LoadPresetsSuccess implements Action {
    readonly type = ShippingActionTypes.LoadPresetsSuccess;
    constructor(public payload: ShippingPreset[]) { }
}

export class LoadPresetsFail implements Action {
    readonly type = ShippingActionTypes.LoadPresetsFail;
    constructor(public payload: any) { }
}

export class CreatePreset implements Action {
    readonly type = ShippingActionTypes.CreatePreset;
    constructor(public payload: ShippingPreset) { }
}

export class CreatePresetSuccess implements Action {
    readonly type = ShippingActionTypes.CreatePresetSuccess;
}

export class CreatePresetFail implements Action {
    readonly type = ShippingActionTypes.CreatePresetFail;
    constructor(public payload: any) { }
}

export class DefaultPreset implements Action {
    readonly type = ShippingActionTypes.DefaultPreset;
    constructor(public payload: ShippingPreset) { }
}

export class DefaultPresetSuccess implements Action {
    readonly type = ShippingActionTypes.DefaultPresetSuccess;
    constructor(public payload: ShippingPreset) { }
}

export class DefaultPresetFail implements Action {
  readonly type = ShippingActionTypes.DefaultPresetFail;
  constructor(public payload: any) { }
}

export class UpdatePreset implements Action {
    readonly type = ShippingActionTypes.UpdatePreset;
    constructor(public payload: ShippingPreset) { }
}

export class UpdatePresetSuccess implements Action {
    readonly type = ShippingActionTypes.UpdatePresetSuccess;
    constructor(public payload: ShippingPreset) { }
}

export class UpdatePresetFail implements Action {
    readonly type = ShippingActionTypes.UpdatePresetFail;
    constructor(public payload: any) { }
}

export class DeletePreset implements Action {
    readonly type = ShippingActionTypes.DeletePreset;
    constructor(public payload: { shippingPresetId: string }) { }
}

export class DeletePresetSuccess implements Action {
    readonly type = ShippingActionTypes.DeletePresetSuccess;
    constructor(public payload: { shippingPresetId: string }) { }
}

export class DeletePresetFail implements Action {
  readonly type = ShippingActionTypes.DeletePresetFail;
  constructor(public payload: any) { }
}

export class LoadIntegratedAccountDetails implements Action {
  readonly type = ShippingActionTypes.LoadIntegratedAccountDetails;
}

export class LoadIntegratedAccountDetailsSuccess implements Action {
  readonly type = ShippingActionTypes.LoadIntegratedAccountDetailsSuccess;
  constructor(public payload: IntegratedAccountDetails) { }
}

export class LoadIntegratedAccountDetailsFail implements Action {
  readonly type = ShippingActionTypes.LoadIntegratedAccountDetailsFail;
  constructor(public payload: any) { }
}

export class LoadRules implements Action {
  readonly type = ShippingActionTypes.LoadRules;
}

export class LoadRulesSuccess implements Action {
  readonly type = ShippingActionTypes.LoadRulesSuccess;
  constructor(public payload: Rules) { }
}

export class LoadRulesFail implements Action {
  readonly type = ShippingActionTypes.LoadRulesFail;
  constructor(public payload: any) { }
}

export class ClearError implements Action {
  readonly type = ShippingActionTypes.ClearError;
}

export class SaveIsInternational implements Action {
  readonly type = ShippingActionTypes.SaveIsInternational;
  constructor(public payload: any) { }
}

export class SaveIsHoldForPickup
 implements Action {
  readonly type = ShippingActionTypes.SaveIsHoldForPickup;
  constructor(public payload: any) { }
}

export class SaveHoldForPickupAddress implements Action {
  readonly type = ShippingActionTypes.SaveHoldForPickupAddress;
  constructor(public payload: any) { }
}

export class SaveHasApprovalCodeError implements Action {
  readonly type = ShippingActionTypes.SaveHasApprovalCodeError;
  constructor(public payload: any) { }
}

export class SaveInsuranceOnly implements Action {
  readonly type = ShippingActionTypes.SaveInsuranceOnly;
  constructor(public payload: any) { }
}

export class SaveNoInsurance implements Action {
  readonly type = ShippingActionTypes.SaveNoInsurance;
  constructor(public payload: any) { }
}

export class SaveInsuranceOnlyTrackingNumber implements Action {
  readonly type = ShippingActionTypes.SaveInsuranceOnlyTrackingNumber;
  constructor(public payload: any) { }
}

export class SaveQuoteError implements Action {
  readonly type = ShippingActionTypes.SaveQuoteError;
  constructor(public payload: any) { }
}

export class SaveZeroDollarInsuranceWarningPrompted implements Action {
  readonly type = ShippingActionTypes.SaveZeroDollarInsuranceWarningPrompted;
  constructor(public payload: any) { }
}

export class ClearShipmentDetails implements Action {
  readonly type = ShippingActionTypes.ClearShipmentDetails;
}
export class LoadEStoreData implements Action {
  readonly type = ShippingActionTypes.LoadEStoreData;
  constructor(public payload: any) { }
}

export type ShippingActions = SetInitialState
  | LoadCargo
  | LoadCargoSuccess
  | LoadCargoFail
  | LoadLookup
  | LoadLookupSuccess
  | LoadLookupFail
  | LoadInternationalShipmentsData
  | LoadInternationalShipmentsDataSuccess
  | LoadInternationalShipmentsDataFail
  | LoadPackageTypes
  | LoadPackageTypesSuccess
  | LoadPackageTypesFail
  | LoadCarriers
  | LoadCarriersSuccess
  | LoadCarriersFail
  | SaveShipmentDetails
  | SaveShipmentCarriers
  | SaveOriginLocation
  | SaveDestinationLocation
  | SaveShipmentReference
  | ResetShipment
  | SaveShipment
  | SaveShipmentSuccess
  | SaveShipmentFail
  | SaveQuoteId
  | SaveShipmentId
  | SaveSelectedQuote
  | SaveInternationalDetail
  | SaveShipmentName
  | PurchaseShipment
  | PurchaseShipmentSuccess
  | PurchaseShipmentFail
  | ProcessQuoteOptions
  | ProcessQuoteOptionsSuccess
  | ProcessQuoteOptionsFail
  | SaveShippingDate
  | SaveOriginalShipmentDetails
  | SaveShipmentActionType
  | CreateClaim
  | SaveClaim
  | SaveClaimDocuments
  | ResetClaim
  | SaveBillingReview
  | SaveBillingReviewSuccess
  | SaveBillingReviewFail
  | ClearBillingReviewNotifications
  | ShipmentRequestsApproval
  | AddApprovalContact
  | PopulateFromPreset
  | PopulateEditPresetFromPreset
  | PopulateFromPresetSuccess
  | PopulateFromQuickQuote
  | PurchaseBoomerangShipment
  | SavedBoomerangShipment
  | LoadPresets
  | LoadPresetsSuccess
  | LoadPresetsFail
  | CreatePreset
  | CreatePresetSuccess
  | CreatePresetFail
  | DefaultPresetSuccess
  | DefaultPresetFail
  | UpdatePreset
  | UpdatePresetSuccess
  | UpdatePresetFail
  | DeletePreset
  | DeletePresetSuccess
  | DeletePresetFail
  | LoadRules
  | LoadRulesSuccess
  | LoadRulesFail
  | LoadIntegratedAccountDetails
  | LoadIntegratedAccountDetailsSuccess
  | LoadIntegratedAccountDetailsFail
  | ClearError
  | SaveIsInternational
  | SaveIsHoldForPickup
  | SaveHoldForPickupAddress
  | SaveNoInsurance
  | SaveInsuranceOnlyTrackingNumber
  | SaveInsuranceOnly
  | SaveHasApprovalCodeError
  | SaveQuoteError
  | SaveZeroDollarInsuranceWarningPrompted
  | ClearShipmentDetails
  | LoadEStoreData;
