import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../store/root.state';

import { MarketplaceActions, MarketplaceActionTypes } from '../actions/marketplace.actions';
import { DEFAULT_SEARCH_PARAMETERS } from '../../shared/config';
import { DiamondSearchResults } from '../../models';
import {
  SearchFilterOptions,
  ColorType,
  SearchParameters,
} from '../../shared/models';

import isEqual from 'lodash.isequal';
import { SearchPreset } from '../../models/search-preset';

export interface ZingState extends fromRoot.ZingState {
  insurance: MarketplaceState;
}

export interface MarketplaceState {
  diamondSearchResults: DiamondSearchResults;
  diamondSearchResultsLoading: boolean;
  diamondSearchResultsLoaded: boolean;

  searchFilterOptions: SearchFilterOptions;
  isSearchFilterOptionsLoading: boolean;
  isSearchFilterOptionsLoaded: boolean;

  error?: any;

  defaultSearchParameters: SearchParameters;
  searchParameters: SearchParameters;
  compareDiamondIds: number[];
  searchPresets: SearchPreset[];
}

export const initialState: MarketplaceState = {
  diamondSearchResults: null,
  diamondSearchResultsLoaded: false,
  diamondSearchResultsLoading: false,

  searchFilterOptions: null,
  isSearchFilterOptionsLoading: false,
  isSearchFilterOptionsLoaded: false,

  error: null,

  defaultSearchParameters: DEFAULT_SEARCH_PARAMETERS,
  searchParameters: null,
  compareDiamondIds: [],
  searchPresets: null
};

const getMarketplaceState = createFeatureSelector<MarketplaceState>('marketplace');

export const getSearchPresets = createSelector(
  getMarketplaceState,
  state => state.searchPresets
);

export const getIsSearchPerformed = createSelector(
  getMarketplaceState,
  state => state.searchParameters !== null && !isEqual(state.searchParameters, state.defaultSearchParameters)
);

export const getDefaultSearchParameters = createSelector(
  getMarketplaceState,
  state => state.defaultSearchParameters
);

export const getSearchFilterOptions = createSelector(
  getMarketplaceState,
  state => state.searchFilterOptions
);

export const getError = createSelector(
  getMarketplaceState,
  state => state.error
);

export const getIsLoading = createSelector(
  getMarketplaceState,
  state => state.isSearchFilterOptionsLoading
);

export const getIsLoaded = createSelector(
  getMarketplaceState,
  state => state.isSearchFilterOptionsLoaded
);

//#region Basic search filter option selectors

export const getShapes = createSelector(
  getSearchFilterOptions,
  state => state && state.shapes && state.shapes.filter(c => !c.isSpecialShape)
);

export const getSpecialShapes = createSelector(
  getSearchFilterOptions,
  state => state && state.shapes && state.shapes.filter(c => c.isSpecialShape)
);

export const getPriceRange = createSelector(
  getSearchFilterOptions,
  params => params && params.priceRange
);

export const getCaratValues = createSelector(
  getSearchFilterOptions,
  params => params && params.caratValues
);

export const getColors = createSelector(
  getSearchFilterOptions,
  state => state && state.colors && state.colors.filter(c => c.colorType.code === ColorType.DiamondColor)
);

export const getClarities = createSelector(
  getSearchFilterOptions,
  param => param && param.clarities
);

export const getCutGrades = createSelector(
  getSearchFilterOptions,
  param => param && param.cutGrades
);

export const getLightPerformances = createSelector(
  getSearchFilterOptions,
  param => param && param.lightPerformances
);

export const getProportionFactors = createSelector(
  getSearchFilterOptions,
  param => param && param.proportionFactors
);

export const getPolishes = createSelector(
  getSearchFilterOptions,
  param => param && param.polishes
);

export const getSymmmetries = createSelector(
  getSearchFilterOptions,
  param => param && param.symmetries
);

export const getFluorescenceColors = createSelector(
  getSearchFilterOptions,
  param => param && param.fluorescenceColors
);

export const getFluorescenceIntensities = createSelector(
  getSearchFilterOptions,
  param => param && param.fluorescenceIntensities
);

export const getGradingLabs = createSelector(
  getSearchFilterOptions,
  param => param && param.gradingLabs
);

export const getReportTypes = createSelector(
  getSearchFilterOptions,
  param => param && param.reportTypes
);

export const getNaturalFancyColorOvertones = createSelector(
  getSearchFilterOptions,
  state => state.naturalFancyColorOvertones
);

export const getNaturalFancyColorColors = createSelector(
  getSearchFilterOptions,
  state => state && state.colors
    && state.colors.filter(c => c.colorType.code === ColorType.NaturalFancyColor)
);

export const getNaturalFancyColorIntensities = createSelector(
  getSearchFilterOptions,
  state => state.naturalFancyColorIntensities
);

//#endregion

//#region Advance search filter option selectors

export const getCountries = createSelector(
  getSearchFilterOptions,
  params => params && params.countries
);

export const getGirdles = createSelector(
  getSearchFilterOptions,
  params => params && params.girdles
);

export const getCuletSizes = createSelector(
  getSearchFilterOptions,
  params => params && params.culetSizes
);

export const getCuletConditions = createSelector(
  getSearchFilterOptions,
  params => params && params.culetConditions
);

export const getShades = createSelector(
  getSearchFilterOptions,
  params => params && params.shades
);

export const getMilkies = createSelector(
  getSearchFilterOptions,
  params => params && params.milkies
);

export const getBlackInclusions = createSelector(
  getSearchFilterOptions,
  params => params && params.blackInclusions
);

export const getEyeCleans = createSelector(
  getSearchFilterOptions,
  params => params && params.eyeCleans
);

export const getProvenanceReports = createSelector(
  getSearchFilterOptions,
  params => params && params.provenanceReports
);

//#endregion

export const getDiamondSearchResults = createSelector(
  getMarketplaceState,
  state => state.diamondSearchResults
);

export const getDiamondSearchResultsLoading = createSelector(
  getMarketplaceState,
  state => state.diamondSearchResultsLoading
);

export const getDiamondSearchResultsLoaded = createSelector(
  getMarketplaceState,
  state => state.diamondSearchResultsLoaded
);

export const getHasAnyDiamondSearchResults = createSelector(
  getMarketplaceState,
  ({ diamondSearchResults }) => diamondSearchResults && !!diamondSearchResults.totalDiamondsAvailable
);

export const getHasNoDiamondSearchResult = createSelector(
  getMarketplaceState,
  ({ diamondSearchResults }) => diamondSearchResults && !!!diamondSearchResults.totalDiamondsAvailable
);

export const getSearchParameters = createSelector(
  getMarketplaceState,
  state => state.searchParameters || state.defaultSearchParameters
);

export const getCompareDiamondIds = createSelector(
  getMarketplaceState,
  state => state.compareDiamondIds
);

export const getLgdCaratValues = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdCaratValues
);

export const getMedia = createSelector(
  getSearchFilterOptions,
  params => params && params.media
);

export const getLabGrownFancyColors = createSelector(
  getSearchFilterOptions,
  state => state && state.colors
    && state.colors.filter(c => c.colorType.code === ColorType.LabGrownFancyColor)
);

export const getLgdBrowns = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdBrowns
);

export const getLgdBlues = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdBlues
);

export const getLgdGreens = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdGreens
);

export const getLgdGrays = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdGrays
);

export const getLgdLusters = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdLusters
);

export const getLgdOtherShades = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdOtherShades
);

export const getLgdTreatments = createSelector(
  getSearchFilterOptions,
  params => params && params.lgdTreatmentTypes
);

export function marketplaceReducer(state = initialState, action: MarketplaceActions): MarketplaceState {
  switch (action.type) {
    case MarketplaceActionTypes.SetDefaultSearchParameters:
      return {
        ...state,
        defaultSearchParameters: action.payload
      };
    case MarketplaceActionTypes.GetSearchFilterOptions:
      return {
        ...state,
        isSearchFilterOptionsLoading: true,
        isSearchFilterOptionsLoaded: false
      };
    case MarketplaceActionTypes.GetSearchFilterOptionsSuccess:
      return {
        ...state,
        searchFilterOptions: action.payload,
        isSearchFilterOptionsLoading: false,
        isSearchFilterOptionsLoaded: true,
        error: null
      };
    case MarketplaceActionTypes.GetSearchFilterOptionsFail:
      return {
        ...state,
        error: action.payload,
        isSearchFilterOptionsLoading: false,
        isSearchFilterOptionsLoaded: false
      };
    case MarketplaceActionTypes.GetDiamondSearchResult:
      return {
        ...state,
        searchParameters: action.payload,
        diamondSearchResultsLoading: true,
        diamondSearchResultsLoaded: false
      };
    case MarketplaceActionTypes.GetDiamondSearchResultSuccess:
      return {
        ...state,
        diamondSearchResults: action.payload,
        diamondSearchResultsLoading: false,
        diamondSearchResultsLoaded: true
      };
    case MarketplaceActionTypes.GetDiamondSearchResultFail:
      return {
        ...state,
        diamondSearchResults: action.payload,
        diamondSearchResultsLoading: false,
        diamondSearchResultsLoaded: false
      };
    case MarketplaceActionTypes.ChangeResultOptions:
      return {
        ...state,
        diamondSearchResultsLoading: true
      };
    case MarketplaceActionTypes.GetDefaultSearchResults:
      return {
        ...state,
        diamondSearchResultsLoading: true,
        diamondSearchResultsLoaded: false
      };
    case MarketplaceActionTypes.ClearSearchResults:
      return {
        ...state,
        diamondSearchResults: null,
        diamondSearchResultsLoaded: false,
        diamondSearchResultsLoading: false
      };
    case MarketplaceActionTypes.ClearSearchOptions:
      return {
        ...state,
        searchParameters: null
      };
    case MarketplaceActionTypes.AddDiamondIdToCompare:
    return {
      ...state,
      compareDiamondIds: [...state.compareDiamondIds, action.payload]
    };
    case MarketplaceActionTypes.RemoveDiamondIdToCompare:
    return {
      ...state,
      compareDiamondIds: state.compareDiamondIds.filter(id => id !== action.payload)
    };
    case MarketplaceActionTypes.FetchSearchPresetsSuccess:
      return {
        ...state,
        searchPresets: action.payload
      };
      case MarketplaceActionTypes.AddSearchPresetSuccess:
      return {
        ...state,
        searchPresets: [...state.searchPresets, action.payload]
      };
      case MarketplaceActionTypes.DeleteSearchPresetSuccess:
        return {
          ...state,
          searchPresets: state.searchPresets.filter(x => x.id !== action.id)
        };
    default:
      return state;
  }
}

