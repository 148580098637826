import { NgModule } from '@angular/core';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import * as fromComponents from './components';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SpinnerModule } from '../shared/components/spinner/spinner.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  imports: [
    NgbModule,
    ReactiveFormsModule,
    CommonModule,
    TranslateModule.forChild(),
    SpinnerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    ...fromComponents.components
  ],
  exports: [
    ...fromComponents.components
  ]
})
export class PopupModalsModule { }
