import { trigger, state, animate, transition, style } from '@angular/animations';

export function fadeOut() {
  return trigger('fadeOut', [
    state(':enter', style({
      opacity: 1
    })),
    transition(':leave', animate(300, style({
      opacity: 0
    }))),
  ]);
}

export function flyInOut() {
  return trigger('flyInOut', [
    state('*', style({ opacity: 1, transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateX(-100%)', opacity: 0 }),
      animate('500ms ease-in')
    ]),
    transition(':leave', [
      animate('500ms ease-out', style({ transform: 'translateX(100%)', opacity: 0 }))
    ])
  ]);
}

export function slideUp() {
  return trigger('slideUp', [
    state('*', style({ opacity: 1, transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateY(-30%)', opacity: 0.1 }),
      animate('600ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
    ])
  ]);
}

export function slideDown() {
  return trigger('slideDown', [
    state('*', style({ opacity: 1, transform: 'translateX(0)' })),
    transition(':enter', [
      style({ transform: 'translateY(30%)', opacity: 0.1 }),
      animate('600ms ease-in', style({ opacity: 1, transform: 'translateX(0)' }))
    ])
  ]);
}
