import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { FeatureName } from '../../shared/enums/feature-name';
import { ConfigurationName } from '../../shared/enums/configuration-name';

@Injectable({ providedIn: 'root' })
export class AppConfigurationService {

  private featureFlags: { [key in FeatureName]?: boolean } = {};
  private configurationNames: { [key in ConfigurationName]?: string } = {};
  private configurationArrayValue: { [key in ConfigurationName]?: string[] } = {};

  constructor(private http: HttpClient) { }

  getFeatureFlag(featureName: FeatureName) {
    if (this.featureFlags[featureName] === undefined) {
      return this.http.get<boolean>(`${environment.configurationApiUrl}/api/features/${featureName}`)
        .pipe(
          tap(value => this.featureFlags[featureName] = value),
          catchError(() => of(false)) // fallback to false in case of an error
        );
    } else {
      return of(this.featureFlags[featureName]);
    }
  }

  getConfigurationValue(configurationName: ConfigurationName) {
    if (this.configurationNames[configurationName] === undefined) {
      return this.http.get<string>(`${environment.configurationApiUrl}/api/configurations/${configurationName}`)
        .pipe(
          tap(value => this.configurationNames[configurationName] = value)
        );
    } else {
      return of(this.configurationNames[configurationName]);
    }
  }

  getConfigurationPlainTextValue(configurationName: ConfigurationName) {
    if (this.configurationNames[configurationName] === undefined) {
      return this.http.get(`${environment.configurationApiUrl}/api/configurations/${configurationName}`, { responseType: 'text' })
        .pipe(
          tap(value => this.configurationNames[configurationName] = value)
        );
    } else {
      return of(this.configurationNames[configurationName]);
    }
  }

  getArrayConfigurationValue(configurationName: ConfigurationName) {
    if(this.configurationArrayValue[configurationName] === undefined) {
      return this.http.get<string[]>(`${environment.configurationApiUrl}/api/configurations/${configurationName}`)
        .pipe(
          tap(value => this.configurationArrayValue[configurationName] = value));
    } else {
      return of(this.configurationArrayValue[configurationName]);
    }
  }

}
