import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { encodingConfig } from '../config/field-encode.config';
import { encodeBase64 } from '@progress/kendo-file-saver';
import * as cloneDeep from 'lodash.cloneDeep';

@Injectable()
export class FieldEncodeInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler) {

    const requestUrl = req.url;
    const requestEncodeConfig = encodingConfig.find(c => requestUrl.includes(c.url));

    if ((req.method === 'POST' || req.method === 'PUT') && !req.headers.has('uploadFile') && requestEncodeConfig) {
      const bodyCopy = cloneDeep(req.body);
      this.encodeFields(bodyCopy, requestEncodeConfig.fields);

      const cloneReq = req.clone({
        body: bodyCopy
      });

      return next.handle(cloneReq);
    }

    return next.handle(req);
  }

  private encodeFields(object: any, fieldsToEncode: string[]): void {
    if (!object) {
      return;
    }
    Object.keys(object).forEach(fieldName => {
      if (typeof object[fieldName] === 'object' && object[fieldName] !== null) {
        this.encodeFields(object[fieldName], fieldsToEncode);
      }
      if (fieldsToEncode.includes(fieldName) && !!object[fieldName]) {
        object[fieldName] = encodeBase64(object[fieldName]);
      }
    });
  }

}

