import { NgbDateParserFormatter, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    constructor(private _decimalPipe: DecimalPipe) {
        super();
    }

    readonly DELIMITER = '/';

    parse(value: string): NgbDate {
        let result: NgbDate = null;
        if (value) {
            const date = value.split(this.DELIMITER);
            const orderedDate = [date[2], date[0], date[1]];
            if (this.validateDate(orderedDate)) {
                result = new NgbDate(parseInt(orderedDate[0], 10), parseInt(orderedDate[1], 10), parseInt(orderedDate[2], 10));
            }
        }
        return result;
    }

    format(date: NgbDate): string {
        let result: string = null;
        if (date) {
            result = this.transformNumber(date.month) +
                this.DELIMITER +
                this.transformNumber(date.day) +
                this.DELIMITER +
                this.transformNumber(date.year);
        }
        return result;
    }

    private transformNumber(num: number) {
        return num < 9 ? this._decimalPipe.transform(num, '2.0') : num;
    }

    private validateDate(date: string[]) {
        const dateObj = new Date(date.join(this.DELIMITER));
        if (date.length !== 3 || dateObj.toString() === 'Invalid Date' || dateObj.getDate() !== +date[2]) {
            return false;
        }
        return true;
    }
}
