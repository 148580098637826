import { Component, EventEmitter, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { NotificationModalConfig, defaultNotificationModalConfig } from '../models/modal-config';

@Component({
  selector: 'z-notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent {

  @Output() continue = new EventEmitter<void>();
  @Output() cancel = new EventEmitter<void>();

  config: NotificationModalConfig = defaultNotificationModalConfig;

  constructor(
    public bsModalRef: BsModalRef
  ) { }

  onCloseModal() {
    this.cancel.emit();
    this.bsModalRef.hide();
  }

  onAgree() {
    this.continue.emit();
  }
}
