import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { CompanyService } from '../../../../shared/services';
import { ExternalNavigationService } from '../../../services/external-navigation.service';

@Injectable()
export class ParnerGatewayEnabledGuard implements CanActivate {
  constructor(
    private companyService: CompanyService,
    private externalNavigationService: ExternalNavigationService
  ) { }

  canActivate(): boolean {
    if (this.companyService.hasOnlyPartnerGateway) {
      this.externalNavigationService.goToPartnerGateway(false);
      return false;
    }

    return true;
  }
}
