import { Injectable } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { EmailAndPhoneValidationStatus } from '../models/email-and-phone-validation-status';
import { encodeBase64 } from '@progress/kendo-file-saver';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  private twoFactorApiUrl = environment.identityApiURL + '/TwoFactorAuth';
  private verifierApiUrl = environment.identityApiURL + '/Verifier';

  constructor(
    private http: HttpClient
  ) { }

  getResetToken(email: string) {
    const url = this.verifierApiUrl + `/RequestResetPasswordToken`;
    return this.http
      .post<{ id: string, token: string, email: string }>(url, { email });
  }

  getUserEmailAndPhone(userId: string) {
    const url = this.twoFactorApiUrl + `/GetEmailAndPhone?identityUserId=${userId}`;
    return this.http
      .get<{ email: string, phoneNumber: string }>(url);
  }

  getTwoFactorAuthProvider(userId: string) {
    const url = this.twoFactorApiUrl + `/GetTwoFactorAuthProvider?identityUserId=${userId}`;
    return this.http
    .get(url);
  }


  sendVerificationCode(method: string, userId: string, isPasswordChangePage = false): Observable<any> {
    const endpoint = method === 'email' ? '/RequestEmailToken' : '/RequestPhoneToken';
    const url = this.twoFactorApiUrl + endpoint;
    return this.http
      .post(url, { id: userId, isPasswordChange: isPasswordChangePage });
  }

  verifyCode(userId: string, token: string, method: string) {
    const endpoint = method === 'email' ? '/VerifyEmailToken' : '/VerifyPhoneToken';
    const url = this.twoFactorApiUrl + endpoint;
    return this.http
      .post(url, { id: userId, token });
  }

  getRandomSecurityQuestion(userId: string) {
    const url = this.twoFactorApiUrl + `/GetRandomQuestion?identityUserId=${userId}`;
    return this.http
      .get(url);
  }

  verifyAnswer(userId: string, questionId: string, answer: string): Observable<any> {
    const url = this.twoFactorApiUrl + '/VerifyAnswer';

    answer = encodeBase64(answer);

    return this.http
      .post(url, {
        identityUserId: userId,
        questionId,
        answer
      });
  }
  changePhoneNumber(userId: string, token: string) {
    const url = this.verifierApiUrl + '/VerifyPhoneNumberChangeAsync';
    const model = {
      id: userId,
      token: token
    };
    return this.http.post(url, model);
  }

  /**
   * Check verification statuses of users email and phone number.
   */
  checkVerificationStatuses(email: string): Observable<EmailAndPhoneValidationStatus> {
    return this.http.post<EmailAndPhoneValidationStatus>(`${this.verifierApiUrl}/CheckVerificationStatus`,
      { email });
  }

  isEmailInUse(email: string) {
    const url = this.verifierApiUrl + `/IsEmailInUse?email=${encodeBase64(email)}`;
    return this.http
      .get<{ email: string, isEmailInUse: string }>(url);
  }

  skipPhoneVerification(userId: string) {
    return this.http.get(this.verifierApiUrl + `/SkipPhoneVerification?identityUserId=${userId}`);
  }
}
