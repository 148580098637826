import { NotificationModalConfig, defaultNotificationModalConfig } from './modal-config';

export class AgreementModalConfig extends NotificationModalConfig {
  checkboxText?: string;
  separationLine?: boolean;
  checkbox?: boolean;
  adjustText?: boolean;
}

export const defaultAgreementModalConfig = {
  ...defaultNotificationModalConfig,
  separationLine: false,
  checkbox: false,
  adjustText: false,
};

