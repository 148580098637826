import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../../../environments/environment';
import { Observable } from 'rxjs';
import { CompanyLocation } from '../models/location.model';
import { StoreType } from '../models/store-type.model';
import { BusinessProduct } from '../models/business-product.model';
import { BusinessUnitHierarchySaveModel } from '../models/business-unit-model';
import { search } from '../../../../../utils/search';
import { CompanyDetails } from '../../../../memo/models/company-details-model';

@Injectable({
  providedIn: 'root'
})
export class LocationService {
  public companyId: string;
  public companyName: string;
  public location: CompanyLocation;
  public allLocations: CompanyLocation[];
  public isLocationActive = true;
  public storeTypes: StoreType[];
  public productsOrServices: BusinessProduct[];
  public companyApiDomain = environment.membershipApiURL + '/api/Companies';
  public apiDomain: string;
  public endpoint: string;
  public isEdit: boolean;
  public hasAlert: boolean;
  public alertContent: string;
  public alertType: string;
  public hasStatusAlert: boolean;
  public statusAlertContent: string;
  public statusAlertType: string;
  businessUnitsHierarchy = new BusinessUnitHierarchySaveModel();

  constructor(
    private http: HttpClient
  ) {
    this.apiDomain = environment.membershipApiURL;
    this.endpoint = 'Locations';
    this.isEdit = false;
  }

  /**
  * Update existing location
  * @param companyId CompanyId for which locations is updated
  * @param locationId LocationId of entity being updated
  * @param location Location entity to replace existing one

  */
  updateLocation(companyId: string, locationId: string, location: CompanyLocation): Observable<any> {
    return this.http
      .put<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}/${locationId}`, location);
  }
  /**
  * Save new location
  * @param companyId CompanyId for which locations is updated
  * @param location Location entity to replace existing one

  */
  saveLocation(companyId: string, location: CompanyLocation): Observable<any> {
    return this.http
      .post<any>(`${this.companyApiDomain}/${companyId}/${this.endpoint}`, location);
  }

  /**
   * Make http request for specific item
   * @param companyId CompanyId for which locations are returned
   */
  getLocationsForCompanyId(companyId: string): Observable<CompanyLocation[]> {
    return this.http
      .get<CompanyLocation[]>(`${this.companyApiDomain}/${companyId}/${this.endpoint}`);
  }

  getLocationsForShipment(companyId: string) {
    this.companyId = companyId;
    return this.getLocationsByCompanyId(companyId, false)
      .toPromise()
      .then(res => { this.allLocations = res; });
  }
  /**
 * Make http request for specific item
 * @param companyId CompanyId for which locations are returned
 */
  getLocation(companyId: string, locationId: string): Observable<CompanyLocation> {
    return this.http
      .get<CompanyLocation>(`${this.companyApiDomain}/${companyId}/${this.endpoint}/${locationId}`);
  }
  /**
  * Make http request for retrieving all available store types
  */
  getStoreTypes(): Observable<StoreType[]> {
    const storeTypesEndpoint = 'storetypes';
    return this.http
      .get<StoreType[]>(`${environment.membershipApiURL}/api/${storeTypesEndpoint}`);
  }
  /**
  * Make http request for retrieving all available store types
  */
  getProductsAndServices(): Observable<BusinessProduct[]> {
    const storeTypesEndpoint = 'businessproducts';
    return this.http
      .get<BusinessProduct[]>(`${environment.membershipApiURL}/api/${storeTypesEndpoint}`);
  }

  searchLocations(searchString: string) {
    return search(this.allLocations, searchString);
  }

  /**
   * Make http request for specific item
   * @param checkUserLocations If true, only user locations will be returned.
   */
  getLocationsByCompanyId(companyId: string, checkUserLocations: boolean): Observable<CompanyLocation[]> {
    return this.http
      .get<CompanyLocation[]>(`${this.companyApiDomain}/${companyId}/${this.endpoint}?checkUserLocations=${checkUserLocations}`);
  }

  getLocationsByEmail(email: string): Observable<CompanyDetails> {
    const searchEndpoint = 'search/byemail';
    return this.http
      .post<any>(`${this.companyApiDomain}/${searchEndpoint}`, {
        'email': email
      });
  }
}
