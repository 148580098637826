export class ODataQueryModel {

  constructor() { }

  filter?: string;
  orderBy?: string;
  top?: string;
  skip?: string;
}

export enum FilterType {
  OdataQuery,
  CustomQuery
}
