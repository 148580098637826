import { UserConsentType } from '../../../../shared/enums/user-consent-type';

export class UserConsent {
    id: string;
    consentId: string;
    userConsentType: UserConsentType;
    consentGivenOn: Date;
    additionalConsents: AdditionalConsent[];
}

export class AdditionalConsent {
    name: string;
    acceptedOn: Date;
    isAccepted: boolean;
}
