export const sharedConstants = {
  teamInvitePdfLink: 'https://info.jewelersmutual.com/hubfs/elevate/downloads/zing-invites-guide.pdf'
};

export const moduleNames = {
  core: 'Core',
  marketplace: 'Marketplace',
  shipping: 'Shipping',
  membership: 'Membership'
};

export const emptyAGSPhoneNumber = '0-';
