import {
  animate,
  animation,
  style
} from '@angular/animations';

export const expandRowAnimation = animation(
  [
    style({ height: '{{ start }}', overflow: 'hidden' }),
    animate('{{ timings }}', style({ height: '{{ end }}' }))
  ],
  {
    params: { timings: '200ms ease-in', start: '0px', end: '*' }
  }
);

