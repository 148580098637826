import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import isEqual from 'lodash.isequal';
import { tap, map } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ShippingUserData, ShippingColumn, AccountData } from '../modules/shipping/models/shipment';
import { UserDataService } from './user-data.service';
import { Observable } from 'rxjs';
import { FedexIntegrationData } from '../modules/shipping/models/fedex-integration';
import { ProfileService } from '../../shared/services/profile.service';
import { AddressDisplayMode } from '../modules/shipping/components/shipment-address-selection/shipment-address-selection.component';
import { savedShipmentsDefaultColumns } from '../modules/shipping/utils/shipment-constants';

@Injectable()
export class ShippingUserDataService extends UserDataService<ShippingUserData> {

  protected readonly apiUrl = environment.shippingApiURL;

  constructor(protected http: HttpClient, protected profileService: ProfileService) {
    super(http);
  }

  /**
   * Save current user shipping columns for Processed Shipments tab and Saved Shipments tab.
   */
  async saveShippingColumns(columns: ShippingColumn[], savedShipmentsColumns: ShippingColumn[]): Promise<void> {
    if (isEqual(columns, this.userData.columns) && isEqual(savedShipmentsColumns, this.userData.savedShipmentsColumns)) {
      return Promise.resolve();
    }

    return this.http.put<void>(`${this.apiUrl}/api/usersettings/shippingcolumns`, { columns, savedShipmentsColumns })
      .pipe(
        tap(() => this.userDataSubject$.next({ ...this.userData, columns, savedShipmentsColumns }))
      ).toPromise();
  }

  /**
  * Save current user shipping columns for Processed Shipments.
  */
  async saveShippingColumnsForProcessedTab(columns: ShippingColumn[]): Promise<void> {
    if (isEqual(columns, this.userData.columns)) {
      return Promise.resolve();
    }

    return this.http.put<void>(`${this.apiUrl}/api/usersettings/shippingcolumns`, { columns })
      .pipe(
        tap(() => this.userDataSubject$.next({ ...this.userData, columns }))
      ).toPromise();
  }

 /**
 * Save current user shipping columns for Saved Shipments.
 */
  async saveShippingColumnsForSavedTab(savedShipmentsColumns: ShippingColumn[]): Promise<void> {
    if (isEqual(savedShipmentsColumns, this.userData.savedShipmentsColumns)) {
      return Promise.resolve();
    }

    return this.http.put<void>(`${this.apiUrl}/api/usersettings/shippingcolumns`, { savedShipmentsColumns })
      .pipe(
        tap(() => this.userDataSubject$.next({ ...this.userData, savedShipmentsColumns }))
      ).toPromise();
  }

  skipLinkingAccount() {
    const url = `${this.apiUrl}/api/usersettings/skiplinking`;
    return this.http.put(url, null);
  }

  async linkAccount(accountData: AccountData): Promise<void> {
    return this.http.put<void>(`${this.apiUrl}/api/usersettings/linkaccount`, accountData)
      .pipe(
        tap(() => this.userDataSubject$.next({ ...this.userData, accountData }))
      ).toPromise();
  }

  getFedexPdf(): Observable<string> {
    const url = `${this.apiUrl}/api/usersettings/meterinstructionspdf`;
    return this.http.get(url, { responseType: 'text' });
  }

  integrateFedexAccount(data: FedexIntegrationData): Observable<any> {
    const url = `${this.apiUrl}/api/usersettings/integratefedexaccount`;
    return this.http.post<any>(url, data);
  }

  saveBrinksSkipFlag() {
    const url = `${this.apiUrl}/api/usersettings/${this.userData.id}/patch`;
    return this.http.put(url, { skipBrinksIntroScreen: true });
  }

  saveDestinationDisplayMode(mode: AddressDisplayMode) {
    const url = `${this.apiUrl}/api/usersettings/${this.userData.id}/patch`;
    return this.http.put(url, { destinationDisplayMode: mode });
  }

  checkUserApproval() {
    const url = `${this.apiUrl}/api/usersettings/checkuserapproval`;
    return this.http.post(url, null);
  }

}
