import { Action } from '@ngrx/store';
import { OrderInformation, OrderLocation, PaymentInformation, PaymentMethodType } from '../../../../order/models';
import { ShippingMethodType } from '../../../../shopping-cart/models/shipping-method-type';
import { ShoppingCart } from '../../models';

export enum ShoppingCartActionTypes {
  GetShoppingCart = '[ShoppingCart] Get Shopping Cart',
  GetShoppingCartSuccess = '[ShoppingCart] Get Shopping Cart Success',
  GetShoppingCartFail = '[ShoppingCart] Get Shopping Cart Fail',
  AddToShoppingCart = '[ShoppingCart] Add To Shopping Cart',
  AddToShoppingCartSuccess = '[ShoppingCart] Add To Shopping Cart Success',
  AddToShoppingCartFail = '[ShoppingCart] Add To Shopping Cart Fail',
  ClearShoppingCart = '[ShoppingCart] Clear Shopping Cart',
  RemoveFromCart = '[ShoppingCart] Remove From Cart',
  RemoveFromCartSuccess = '[ShoppingCart] Remove From Cart Success',
  RemoveFromCartFail = '[ShoppingCart] Remove From Cart Fail',
  ClearRemoveFromCartError = '[ShoppingCart] Clear Remove From Cart Error',
  Checkout = '[ShoppingCart] Checkout Shopping Cart',
  CheckoutSuccess = '[ShoppingCart] Checkout Shopping Cart Success',
  CheckoutFail = '[ShoppingCart] Checkout Shopping Cart Fail',
  ClearCheckoutError = '[ShoppingCart] Clear Checkout Error',
  ClearOrder = '[ShoppingCart] Clear Order',
  SaveOrderInformation = '[ShoppingCart] Save Order Information',
  SaveOrderLocation = '[ShoppingCart] Save Order Location',
  SaveShippingMethod = '[ShoppingCart] Save Shipping Method',
  FetchPaymentInformation = '[ShoppingCart] Fetch Payment Information',
  FetchPaymentInformationSuccess = '[ShoppingCart] Fetch Payment Information Success',
  FetchPaymentInformationFail = '[ShoppingCart] Fetch Payment Information Fail',
  ClearPaymentInformation = '[ShoppingCart] Clear Payment Information'
}

export class GetShoppingCart implements Action {
  readonly type = ShoppingCartActionTypes.GetShoppingCart;
  constructor(public payload?: { locationId?: string; shippingMethod?: ShippingMethodType }) { }
}

export class GetShoppingCartSuccess implements Action {
  readonly type = ShoppingCartActionTypes.GetShoppingCartSuccess;
  constructor(public payload: ShoppingCart) { }
}

export class GetShoppingCartFail implements Action {
  readonly type = ShoppingCartActionTypes.GetShoppingCartFail;
  constructor(public payload: any) { }
}

export class AddToShoppingCart implements Action {
  readonly type = ShoppingCartActionTypes.AddToShoppingCart;
  constructor(public payload: any) { }
}

export class AddToShoppingCartSuccess implements Action {
  readonly type = ShoppingCartActionTypes.AddToShoppingCartSuccess;
}

export class AddToShoppingCartFail implements Action {
  readonly type = ShoppingCartActionTypes.AddToShoppingCartFail;
  constructor(public payload: any) { }
}

export class ClearShoppingCart implements Action {
  readonly type = ShoppingCartActionTypes.ClearShoppingCart;
}

export class RemoveFromCart implements Action {
  readonly type = ShoppingCartActionTypes.RemoveFromCart;
  constructor(public payload: { id: string; locationId?: string; shippingMethod?: ShippingMethodType }) { }
}

export class RemoveFromCartSuccess implements Action {
  readonly type = ShoppingCartActionTypes.RemoveFromCartSuccess;
}

export class RemoveFromCartFail implements Action {
  readonly type = ShoppingCartActionTypes.RemoveFromCartFail;
  constructor(public payload: any) { }
}

export class ClearRemoveFromCartError implements Action {
  readonly type = ShoppingCartActionTypes.ClearRemoveFromCartError;
}

export class Checkout implements Action {
  readonly type = ShoppingCartActionTypes.Checkout;
  constructor(public payload: { paymentMethod: PaymentMethodType }) { }
}

export class CheckoutSuccess implements Action {
  readonly type = ShoppingCartActionTypes.CheckoutSuccess;
}

export class CheckoutFail implements Action {
  readonly type = ShoppingCartActionTypes.CheckoutFail;
  constructor(public payload: any) { }
}

export class ClearCheckoutError implements Action {
  readonly type = ShoppingCartActionTypes.ClearCheckoutError;
}

export class ClearOrder implements Action {
  readonly type = ShoppingCartActionTypes.ClearOrder;
}

export class SaveOrderInformation implements Action {
  readonly type = ShoppingCartActionTypes.SaveOrderInformation;
  constructor(public payload: OrderInformation) { }
}

export class SavrOrderLocation implements Action {
  readonly type = ShoppingCartActionTypes.SaveOrderLocation;
  constructor(public payload: OrderLocation) { }
}

export class SaveShippingMethod implements Action {
  readonly type = ShoppingCartActionTypes.SaveShippingMethod;
  // TODO: CHECK SHIPPING METHOD TYPE
  constructor(public payload: ShippingMethodType) { }
}

export class FetchPaymentInformation implements Action {
  readonly type = ShoppingCartActionTypes.FetchPaymentInformation;
  constructor(public payload: { zingOrderId: string }) { }
}

export class FetchPaymentInformationSuccess implements Action {
  readonly type = ShoppingCartActionTypes.FetchPaymentInformationSuccess;
  constructor(public payload: PaymentInformation) { }
}

export class FetchPaymentInformationFail implements Action {
  readonly type = ShoppingCartActionTypes.FetchPaymentInformationFail;
  constructor(public payload: any) { }
}

export class ClearPaymentInformation implements Action {
  readonly type = ShoppingCartActionTypes.ClearPaymentInformation;
}

export type ShoppingCartActions =
  GetShoppingCart
  | GetShoppingCartSuccess
  | GetShoppingCartFail
  | AddToShoppingCart
  | AddToShoppingCartSuccess
  | AddToShoppingCartFail
  | ClearShoppingCart
  | RemoveFromCart
  | RemoveFromCartSuccess
  | RemoveFromCartFail
  | ClearRemoveFromCartError
  | Checkout
  | CheckoutSuccess
  | CheckoutFail
  | ClearCheckoutError
  | ClearOrder
  | SaveOrderInformation
  | SavrOrderLocation
  | SaveShippingMethod
  | FetchPaymentInformation
  | FetchPaymentInformationSuccess
  | FetchPaymentInformationFail
  | ClearPaymentInformation;
