import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { BasePartnerUserDetailsParams } from '../../guest/modules/models/partner';
import { MarketplaceAGSServicePermission } from '../../membership/modules/account/models/marketplace-ags-service-permission.model';
import { MarketplacePartnerServicePermission } from '../../membership/modules/account/models/marketplace-partner-service-permission';
import { Person } from '../../membership/modules/account/models/person.model';
import { BusinessServiceType } from '../../shared/enums/business-service-type';
import { MarketplacePartnerAffiliationType } from '../../shared/enums/marketplace-partner-affiliation-types';
import { MarketplacePartnerAffiliation } from '../../shared/models/marketplace-partner-affiliation';
import { MarketplaceServicePermissionStatus } from '../enums/marketplace-service-permission-status';
import { ValidatePartner } from '../models/validate-partner';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  private membershipApiUrl = environment.membershipApiURL + '/api/Partners';

  constructor(private httpClient: HttpClient) { }

  getPartnerUserDetails(params: BasePartnerUserDetailsParams): Observable<any> {
    return this.httpClient
      .get(`${this.membershipApiUrl}/${params.partner}/${params.memberId}`);
  }

  validatePartner(partnerName: string) {
    return this.httpClient
      .get<ValidatePartner>(`${this.membershipApiUrl}/${partnerName}/validate`);
  }

  createPartnerModel(affiliationType: number, partnerData?): MarketplacePartnerAffiliation {
    switch (affiliationType) {
      case MarketplacePartnerAffiliationType.AGS:
        return {
          affiliationType: affiliationType,
          memberId: partnerData.memberId.trim(),
          status: MarketplaceServicePermissionStatus.Active
        };
      case MarketplacePartnerAffiliationType.GemAndJewel:
        return {
          affiliationType: affiliationType,
          status: MarketplaceServicePermissionStatus.Active
        };
      case MarketplacePartnerAffiliationType.None:
        return null;
      default:
        return null;
    }
  }

  getSelectedPartner(person: Person, onlyActive: boolean = false) {
    const partnerPermission = this.getPartnerPermission(MarketplacePartnerAffiliationType.AGS, person, onlyActive);

    if (partnerPermission) {
      return partnerPermission.affiliationType;
    }
    return MarketplacePartnerAffiliationType.None;
  }

  getPartnerPermission(affiliationType: MarketplacePartnerAffiliationType, person: Person, onlyActive: boolean = false): MarketplacePartnerServicePermission {
    if (person && person.servicePermissions && affiliationType) {
      const servicePermission = person.servicePermissions.find(x => x.serviceType === BusinessServiceType.MarketPlace);
      if (servicePermission) {
        const partnerPermission = servicePermission
          .partnerPermissions?.find(x =>
            x.affiliationType === affiliationType
            && (onlyActive ? this.checkIfPartnerParmissionIsAGSAndActive(x) : true)
          );

        return partnerPermission;
      }
    }
    return null;
  }

  checkIfPartnerParmissionIsAGSAndActive(partnerPermission: MarketplacePartnerServicePermission): boolean {
    return partnerPermission.affiliationType === MarketplacePartnerAffiliationType.AGS
      && (partnerPermission as MarketplaceAGSServicePermission).status === MarketplaceServicePermissionStatus.Active;
  }
}
