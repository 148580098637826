import { Component } from '@angular/core';

@Component({
  selector: 'z-message-spinner',
  templateUrl: './message-spinner.component.html',
  styleUrls: ['./message-spinner.component.scss']
})
export class MessageSpinnerComponent {
  message: string;
}
