import { Inject, Injectable } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { PartnerService } from './partner.service';
import { Person } from '../../membership/modules/account/models/person.model';
import { MarketplacePartnerAffiliationType } from '../enums/marketplace-partner-affiliation-types';
import { BehaviorSubject } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private themeName$ = new BehaviorSubject<string>(null);
  themeNameObs$ = this.themeName$.asObservable();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private partnerService: PartnerService,
    private route: Router
  ) { }

  loadTheme(themeName: string) {
    this.themeName$.next(themeName);
    if (!themeName) {
      return;
    }

    themeName = themeName.endsWith('.css') ? themeName : themeName + '.css';

    const headElement = this.document.getElementsByTagName('head')[0];
    const existingStyleElement = this.document.getElementById('theme-style-link') as HTMLLinkElement;

    if (existingStyleElement) {
      existingStyleElement.href = themeName;
    } else {
      const newStyleElement = this.document.createElement('link');
      newStyleElement.id = 'theme-style-link';
      newStyleElement.rel = 'stylesheet';
      newStyleElement.href = themeName;

      headElement.appendChild(newStyleElement);
    }
  }

  unloadTheme() {
    const headElement = this.document.getElementsByTagName('head')[0];
    const existingStyleElement = this.document.getElementById('theme-style-link') as HTMLLinkElement;

    headElement.removeChild(existingStyleElement);
  }

  private addModuleClass(url: string) {
    const modulesList = ['/marketplace', '/zing-dashboard'];
    this.document.body.classList.remove(...modulesList.map(module => `${module.slice(1)}-style`));
    modulesList.forEach(module => {
        if (url.includes(module)) {
            this.document.body.classList.add(`${module.slice(1)}-style`);
        }
    });
}

  trackActiveModule() {
    this.route.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
            this.addModuleClass(event.url);
        }
    });
}

  getThemeName(person: Person) {
    const selectedPartner = this.partnerService.getSelectedPartner(person, true);
    if (selectedPartner === MarketplacePartnerAffiliationType.None) {
      return null;
    }

    const partnerName = MarketplacePartnerAffiliationType[selectedPartner];
    const partnerThemeName = 'partner-' + partnerName.toLowerCase();

    return partnerThemeName;
  }
}
