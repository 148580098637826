import { CompanyLocation } from './location.model';

export class BusinessUnit {
    isSelected?: boolean;
    businessUnitID?: string;
    name: string;
    tempId?: string;
    businessUnits = new Array<BusinessUnit>();
}

export class BusinessUnitHierarchy {
    constructor (
        public hierarchyLevel: number,
        public businessUnit: BusinessUnit,
        public tempId?: string
    ) {}
}

export class BusinessUnitHierarchySaveModel {
    isValid: boolean;
    businessUnitsStructure = new Array<BusinessUnit>();
    selectedBusinessUnit?: BusinessUnit;
    businessUnitsOnForm = new Array<BusinessUnit>();
}

export class LocationsUnderBusinessUnit {
    businessUnitHierarchy = new Array<BusinessUnitHierarchy>();
    locations = new Array<CompanyLocation>();
}
