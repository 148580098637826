import { Injectable } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BehaviorSubject, Observable, defer, iif, of, throwError } from 'rxjs';
import { catchError, finalize, map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PaymentMethodType } from '../../order/models';
import { ModuleType } from '../../shopping-cart/models/module-type';
import { ShippingMethodType } from '../../shopping-cart/models/shipping-method-type';
import { ShoppingCartValidateRequest } from '../../shopping-cart/models/validate-request';
import { emptyCart, NaturalShoppingCart } from '../models/natural-cart';
import { RetailCartCheckout } from '../models/retail-cart-checkout';
import { ShoppingCartModalComponent } from '../shopping-cart/components';
import { OrderFacadeService } from '../shopping-cart/services/order-facade.service';
import { DiamondCartService } from './diamond-cart.service';

@Injectable({ providedIn: 'root' })
export class DiamondCartFacadeService {
  private maxItems = environment.shoppingCart.maxShoppingCartItems;
  private cart$: BehaviorSubject<NaturalShoppingCart> = new BehaviorSubject(null);

  isLoading$ = new BehaviorSubject(false);

  // Used to fire error
  private errorSub = new BehaviorSubject<NaturalCartError>(null);
  private successSub = new BehaviorSubject(false);
  private processingCartSub = new BehaviorSubject(false);

  error$ = this.errorSub.asObservable();
  success$ = this.successSub.asObservable();
  cartObs$ = this.cart$.asObservable();
  processingCart$ = this.processingCartSub.asObservable();
  private readonly validate$ = defer(() => of(this.cart$.value.count < this.maxItems));

  readonly cartLoaded$ = this.cartObs$.pipe(
    switchMap(c => iif(() => c === null,
      this.fetchCart$.pipe(
        catchError(() => {
          this.errorSub.next({
            reason: NaturalCartErrorReason.SystemError,
            message: 'Service error'
          });

          return of(emptyCart);
        }),
        tap(cart => this.cart$.next(cart)),
        map(cart => !!cart)),
      of(c).pipe(map(cart => !!cart)))));

  readonly fetchCart$ = this.cartService.getCart()
    .pipe(catchError(err => {
      if (err.status === 404) {
        return of(emptyCart);
      } else {
        return throwError(err);
      }
    }), finalize(() => {
      this.isLoading$.next(false);
      this.processingCartSub.next(false);
    }));

  private readonly addItemObs = (supplierItemId: string) => defer(() => {
    this.isLoading$.next(true);

    return this.validate$.pipe(
      switchMap(isValid =>
        iif(() => isValid,
          this.cartService.addItem(supplierItemId),
          throwError({
            reason: NaturalCartErrorReason.MaximumItemCountExceeded,
            message: 'Maximum items in cart exceeded 15.'
          })
        )
      ),
      catchError(err => {
        if (err && err.reason) {
          this.errorSub.next(err);
        } else {
          this.errorSub.next({
            reason: NaturalCartErrorReason.AddToCartServiceError,
            message: 'Service Error'
          });
        }

        return throwError(err);
      }),
      take(1),
      finalize(() => this.isLoading$.next(false))
    );
  });

  private readonly removeItemObs = (itemId: string) => defer(() => {
    const item = this.cart$.value.items.find(i => i.supplierItemIdentifier === itemId);

    if (!item) {
      this.isLoading$.next(false);

      return throwError({
        reason: NaturalCartErrorReason.UnableToRemoveItem,
        message: 'Item does not exists in cart.'
      });
    }

    return this.cartService.removeItem(this.cart$.value.id, item.id).pipe(
      this.handleRequestErrors({
        reason: NaturalCartErrorReason.UnableToRemoveItem,
        message: 'Unable to remove item from cart'
      })
    );
  });

  private readonly isInCartObs = (itemId: string) => defer(() =>
    of(this.cart$.value.items.some(i => i.supplierItemIdentifier === itemId))
  );

  shoppingCartProcessing = (validationStatus: string) => defer(() => {
    if (validationStatus === 'Updated') {
      return this.fetchCart$.pipe(tap(cart => {
        this.cart$.next(cart);
      }));
    } else {
      this.isLoading$.next(false);
      this.processingCartSub.next(false);
      this.cart$.next(this.cart$.value);
      return of(null);
    }
  });

  constructor(
    private cartService: DiamondCartService,
    private orderFacade: OrderFacadeService,
    private bsModalRef: BsModalService
  ) { }

  fetchShoppingCart() {
    this.fetchCart$.subscribe(cart => this.cart$.next(cart));
  }

  addToCart(supplierItemId: string, calledFromCompare: boolean = false) {
    this.addItemObs(supplierItemId).subscribe(() => {
      this.fetchShoppingCart();

      // dont show success modal if called from compare modal
      this.successSub.next(!calledFromCompare);
    });
  }

  removeItem(itemId: string) {
    this.isLoading$.next(true);
    this.removeItemObs(itemId).pipe(
      finalize(() => this.isLoading$.next(false))
    ).subscribe(() => this.fetchShoppingCart());
  }

  removeAndValidate(itemId: string, validationRequest: ShoppingCartValidateRequest) {
    this.isLoading$.next(true);
    this.processingCartSub.next(true);

    return this.removeItemObs(itemId).pipe(
      switchMap(() => this.cartService.validate(validationRequest)),
      this.handleRequestErrors({ reason: NaturalCartErrorReason.SystemError, message: 'Validate error' })
    );
  }

  isItemInCart(supplierItemId: string) {
    return this.isInCartObs(supplierItemId);
  }

  clearShoppingCart() {
    this.cart$.next(emptyCart);
  }

  checkout(paymentMethod: PaymentMethodType, shipingHandlingMethod: ShippingMethodType) {
    return this.orderFacade.orderLocation$.pipe(
      switchMap(location => {
        const checkoutModel: RetailCartCheckout = {
          locationId: location.locationID,
          paymentMethodType: paymentMethod,
          shippingAndHandlingMethodType: shipingHandlingMethod,
          moduleType: ModuleType.NaturalDiamonds
        };

        return this.cartService.checkout(checkoutModel);
      }),
      this.handleRequestErrors({ reason: NaturalCartErrorReason.SystemError, message: 'Checkut error' }),
      take(1),
    );
  }

  validate = (validateModel: ShoppingCartValidateRequest) => defer(() => {
    this.isLoading$.next(true);
    this.processingCartSub.next(true);
    return this.cartService.validate(validateModel)
      .pipe(this.handleRequestErrors({ reason: NaturalCartErrorReason.SystemError, message: 'Validate error' }));
  });

  showModal(initialState) {
    this.bsModalRef.show(
      ShoppingCartModalComponent,
      {
        class: 'marketplace-modal modal-fit-content pt-4',
        backdrop: 'static',
        keyboard: false,
        initialState
      }
    ).onHide.pipe(take(1)).subscribe(() => this.successSub.next(false));
  }

  private handleRequestErrors(errorType: NaturalCartError) {
    return (source: Observable<any>) => source.pipe(
      catchError(() => {
        this.errorSub.next(errorType);
        return throwError(errorType);
      }),
      tap(() => this.errorSub.next(null))
    );
  }

}

export class NaturalCartError {
  reason: string;
  message: string;
}

export enum NaturalCartErrorReason {
  UnableToRemoveItem = 'UnableToRemoveItem',
  MaximumItemCountExceeded = 'MaximumItemCountExceeded',
  AddToCartServiceError = 'AddToCartError',
  SystemError = 'SystemError'
}
