import {Action} from '@ngrx/store';
import {Invoice} from '../../models/invoice.model';
import {PaymentDetails} from '../../models/payment-details.model';

export enum BillingActionTypes {
  LoadDataInvoicesPayments = '[Billing] Load Invoices and Payments',
  LoadDataInvoicesSuccess = '[Billing] Load Invoices Success',
  LoadDataInvoicesPaymentsFail = '[Billing] Load Invoices and Payments Fail',
  SetSearchStartDate = '[Billing] Set Search Start Date',
  GetSearchStartDate = '[Billing] Get Search Start Date',
  SetSearchEndDate = '[Billing] Set Search End Date',
  GetSearchEndDate = '[Billing] Get Search End Date'
}

export class LoadDataInvoicesPayments implements Action {
  readonly type = BillingActionTypes.LoadDataInvoicesPayments;

  constructor(public startDate: Date, public endDate: Date) {
  }
}

export class LoadDataInvoicesSuccess implements Action {
  readonly type = BillingActionTypes.LoadDataInvoicesSuccess;

  constructor(public payload: Array<Invoice>) {
  }
}

export class LoadDataInvoicesPaymentsFail implements Action {
  readonly type = BillingActionTypes.LoadDataInvoicesPaymentsFail;

  constructor(public payload: any) {
  }
}

export class SetSearchStartDate implements Action {
  readonly type = BillingActionTypes.SetSearchStartDate;
  constructor(public startDate: Date) {
  }
}

export class GetSearchStartDate implements Action {
  readonly type = BillingActionTypes.GetSearchStartDate;
}

export class SetSearchEndDate implements Action {
  readonly type = BillingActionTypes.SetSearchEndDate;
  constructor(public endDate: Date) {
  }
}

export class GetSearchEndDate implements Action {
  readonly type = BillingActionTypes.GetSearchEndDate;
}

export type BillingActions =
  LoadDataInvoicesPayments
  | LoadDataInvoicesSuccess
  | LoadDataInvoicesPaymentsFail
  | SetSearchStartDate
  | GetSearchStartDate
  | SetSearchEndDate
  | GetSearchEndDate;


