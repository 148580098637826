import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { maskEmail } from '../../../utils/formatters';
import { MarketplacePartnerAffiliationType } from '../../shared/enums/marketplace-partner-affiliation-types';
import { ProfileService } from '../../shared/services';
import { AnalyticsService } from '../../shared/services/analytics.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalNavigationService {

  plDashboard = `${environment.personalLinesUrl}/dashboard`;
  plCreateClaim = `${environment.personalLinesUrl}/create-claim`;

  carePlanDashboard = environment.carePlanAppUrl;
  carePlanClaims = `${environment.carePlanAppUrl}/claims`;
  carePlanSales = `${environment.carePlanAppUrl}/sales`;
  carePlanHomepage = `${environment.carePlanHomePage}`;

  gemAndJewelAccount = environment.gemAndJewelAccountUrl;

  partnerGateway = environment.partnerGatewayLink;

  get email() {
    return maskEmail(this.profileService.person.email);
  }

  constructor(
    private analyticsService: AnalyticsService,
    private profileService: ProfileService
  ) { }

  //#region Personal Insurance

  goToPersonalInsuranceDashboard(url?: string) {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-PersonalLines',
      properties: { Email: this.email }
    });

    window.location.href = url || this.plDashboard;
  }

  goToPersonalInsuranceClaim() {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-PersonalLines-CreateClaim',
      properties: { Email: this.email }
    });
    window.location.href = this.plCreateClaim;
  }

  searchPersonalInsuranceClaim(searchTerm) {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-PersonalLines-SearchClaim',
      properties: { Email: this.email }
    });

    const searchUrl = `${this.plDashboard}?searchTerm=${searchTerm}`;
    window.location.href = searchUrl;
  }

  //#endregion

  //#region Care plan

  goToCarePlanDashboard(url?: string) {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-CarePlan',
      properties: { Email: this.email }
    });

    window.location.href = url || this.carePlanDashboard;
  }

  goToCarePlanClaims() {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-CarePlan-Claims',
      properties: { Email: this.email }
    });

    window.location.href = this.carePlanClaims;
  }

  goToCarePlanSales() {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-CarePlan-Sales',
      properties: { Email: this.email }
    });

    window.location.href = this.carePlanSales;
  }

  goToCarePlanHomepage() {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-CarePlan-Homepage',
      properties: { Email: this.email }
    });

    window.open(this.carePlanHomepage, '_blank');
  }

  //#endregion

  //#region Partner Gateway (JMPG)

  goToPartnerGateway(newTab: boolean, url?: string) {
    this.analyticsService.analytics.eventTrack.next({
      action: 'ProductActive-PartnerGateway',
      properties: { Email: this.email }
    });

    if (!newTab) {
      window.location.href = url || this.partnerGateway;
    } else {
      window.open(url || this.partnerGateway, '_blank');
    }
  }

  //#endregion

  //#region Gem + Jewel

  goToGemAndJewelAccount(url?: string) {
    const partner = MarketplacePartnerAffiliationType[MarketplacePartnerAffiliationType.GemAndJewel];
    this.analyticsService.analytics.eventTrack.next({
      action: `PartnerActive-${partner}`,
      properties: { Email: this.email }
    });

    window.open(url || this.gemAndJewelAccount, '_blank', 'noopener');
  }

  //#endregion Gem + Jewel

}
