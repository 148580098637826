export enum PriceField {
  Diamond_Authentication_Fee,
  Shipping_Handling,
  Transaction_Fee,
  Payment_Processing,
  Total_Transaction_Cost,
  Total_Tax_Amount,
  Authentication_And_Shipping,
  Servicing_Fee,
  Discount_Amount
}

export interface PriceChangeDetails {
  priceField: PriceField;
  dateOfUpdate: Date;
  changeReason: string;
}
