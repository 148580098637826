import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, select, Store } from '@ngrx/store';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, concatMap, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { MarketplacePartnerAffiliationType } from '../../../shared/enums/marketplace-partner-affiliation-types';
import { PartnerService, ProfileService } from '../../../shared/services';
import { MarketplaceService } from '../../services/marketplace.service';
import { DEFAULT_CARAT_VALUES, DEFAULT_LGD_CARAT_VALUES, DEFAULT_MEDIA, DEFAULT_PRICING_LIMIT } from '../../shared/config';
import { getDiamondSearchParameters, getResultOptions, getSimilarSearchCriteria } from '../../shared/utils';
import * as marketplaceActions from '../actions/marketplace.actions';
import {
  getDefaultSearchParameters, getSearchFilterOptions,
  getSearchParameters, MarketplaceState
} from '../reducers/marketplace.reducer';

@Injectable()
export class MarketplaceEffects {

  isAGSUser = false;

  constructor(
    private actions$: Actions,
    private marketplaceService: MarketplaceService,
    private profileService: ProfileService,
    private partnerService: PartnerService,
    private router: Router,
    private store: Store<MarketplaceState>
  ) { }

  
  getSearchFilterOptions$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.GetSearchFilterOptions),
      switchMap(() =>
        this.marketplaceService.getSearchFilterOptions()
          .pipe(
            tap(searchFilterOptions => {
              searchFilterOptions.priceRange = DEFAULT_PRICING_LIMIT;
              searchFilterOptions.caratValues = DEFAULT_CARAT_VALUES;
              searchFilterOptions.media = DEFAULT_MEDIA;
              searchFilterOptions.lgdCaratValues = DEFAULT_LGD_CARAT_VALUES;
            }),
            map(searchFilterOptions => new marketplaceActions.FetchSearchFilterOptionsSuccess(searchFilterOptions)),
            catchError(err => of(new marketplaceActions.FetchSearchFilterOptionsFail(err)))
          )
      )
    ));

  
  getDiamondSearchResulsts$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.GetDiamondSearchResult),
      concatMap(({ payload }: marketplaceActions.GetDiamondSearchResult) => of(payload).pipe(
        withLatestFrom(
          this.store.pipe(select(getSearchFilterOptions))
        )
      )),
      map(([payload, searchFilters]) =>
        getDiamondSearchParameters(payload, searchFilters, payload.customSearch ? this.checkIfAGSUser() : false)
      ),
      switchMap(diamondSearchParameters =>
        this.marketplaceService.getDiamondSearchResults(diamondSearchParameters)
          .pipe(
            tap(diamondSearchResult => {
              if (diamondSearchResult && diamondSearchResult.totalDiamondsAvailable) {
                this.router.navigateByUrl('marketplace/natural/dashboard');
              }
            }),
            map(diamondSearchResult => new marketplaceActions.GetDiamondSearchResultSuccess(diamondSearchResult)),
            catchError(err => of(new marketplaceActions.GetDiamondSearchResultFail(err)))
          )
      )));

  
  changeResultOptions$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.ChangeResultOptions),
      map(({ payload }: marketplaceActions.ChangeResultOptions) => getResultOptions(payload)),
      concatMap(resultOptions => of(resultOptions).pipe(
        withLatestFrom(
          this.store.pipe(select(getSearchParameters)),
          this.store.pipe(select(getSearchFilterOptions))
        )
      )),
      map(([resultOptions, searchParameters, searchFilterOptions]) => {
        const diamondSearchParameters =
          getDiamondSearchParameters(searchParameters, searchFilterOptions, searchParameters.customSearch ? this.checkIfAGSUser() : false);
        diamondSearchParameters.resultOptions = resultOptions;
        return diamondSearchParameters;
      }),
      switchMap(diamondSearchRequest =>
        this.marketplaceService.getDiamondSearchResults(diamondSearchRequest)
          .pipe(
            map(idexsearchcriterias => new marketplaceActions.GetDiamondSearchResultSuccess(idexsearchcriterias)),
            catchError(err => of(new marketplaceActions.GetDiamondSearchResultFail(err)))
          )
      )));

  
  getDefaultSearchResults$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.GetDefaultSearchResults),
      switchMap(() => combineLatest([
        this.store.pipe(select(getDefaultSearchParameters)),
        this.store.pipe(select(getSearchFilterOptions))
      ])),
      map(([searchParameters, searchFilters]) =>
        getDiamondSearchParameters(searchParameters, searchFilters, searchParameters.customSearch ? this.checkIfAGSUser() : false)),
      switchMap(diamondSearchRequest =>
        this.marketplaceService.getDiamondSearchResults(diamondSearchRequest)
          .pipe(
            map(idexsearchcriterias => new marketplaceActions.GetDiamondSearchResultSuccess(idexsearchcriterias)),
            catchError(err => of(new marketplaceActions.GetDiamondSearchResultFail(err)))
          )
      )));

  searchSimilar = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.searchSimilar),
      concatMap(action => of(action.cartItem).pipe(
        withLatestFrom(
          this.store.pipe(select(getDefaultSearchParameters)),
          this.store.pipe(select(getSearchFilterOptions))
        )
      )),
      map(([cartItem, defaultSearchParameters, searchFilterOptions]) =>
        getSimilarSearchCriteria(
          cartItem, defaultSearchParameters, searchFilterOptions, defaultSearchParameters.customSearch ? this.checkIfAGSUser() : false
        )
      ),
      tap(() => this.router.navigateByUrl('marketplace/natural/dashboard')),
      map(similarCriteria => new marketplaceActions.GetDiamondSearchResult(similarCriteria))
    )
  );

  
  getSearchPresets$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.FetchSearchPresets),
      switchMap(() =>
        this.marketplaceService.fetchSearchPresets()
          .pipe(
            map(searchPresets => new marketplaceActions.FetchSearchPresetsSuccess(searchPresets)),
            catchError(err => of(new marketplaceActions.FetchSearchPresetsFail(err)))
          )
      )
    ));

  
  saveSearchPreset$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.AddSearchPreset),
      concatMap((action: marketplaceActions.AddSearchPreset) =>
        this.marketplaceService.addSearchPreset(action.payload)
          .pipe(
            map(() => new marketplaceActions.AddSearchPresetSuccess(action.payload)),
            catchError(err => of(new marketplaceActions.AddSearchPresetFail(err))),
            switchMap(() =>
              this.marketplaceService.fetchSearchPresets()
                .pipe(
                  map(searchPresets => new marketplaceActions.FetchSearchPresetsSuccess(searchPresets)),
                  catchError(err => of(new marketplaceActions.FetchSearchPresetsFail(err)))
                )
            )
          )
      )
    ));

  
  deleteSearchPreset$: Observable<Action> = createEffect(() => this.actions$
    .pipe(
      ofType(marketplaceActions.MarketplaceActionTypes.DeleteSearchPreset),
      concatMap((action: marketplaceActions.DeleteSearchPreset) =>
        this.marketplaceService.deleteSearchPreset(action.id)
          .pipe(
            map(() => new marketplaceActions.DeleteSearchPresetSuccess(action.id)),
            catchError(err => of(new marketplaceActions.DeleteSearchPresetFail(err)))
          )
      )
    ));

  private checkIfAGSUser() {
    return !!this.partnerService.getPartnerPermission(MarketplacePartnerAffiliationType.AGS, this.profileService.person, true);
  }
}
