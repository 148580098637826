import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'z-idle-warning',
  templateUrl: './idle-warning.component.html',
  styleUrls: ['./idle-warning.component.scss']
})
export class IdleWarningComponent implements OnInit {
  public isLogout = false;
  public error: any;

  constructor(
     public bsModalRef: BsModalRef
  ) { }

  ngOnInit(): void {
  }

  continueSession(): void {
    this.bsModalRef.hide();
  }

  logout(): void {
    this.isLogout = true;
    this.bsModalRef.hide();
  }
}
