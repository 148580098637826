import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { AppraisalInTrialSubscription } from '../../appraisal/models/appraisal-in-trial-subscription';
import { BusinessServiceType } from '../enums/business-service-type';

/**
 * Service containing InTrial subscription related operations
 */
@Injectable({
  providedIn: 'root'
})
export class InTrialAppraisalService {

  private appraisalApiUrl = `${environment.appraisalApiURL}/api`;
  public appraisalInTrialSubscription: AppraisalInTrialSubscription;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Gets user specific restriction and InTrial subscription details
   * related to appraisal inTrial subscription
   */
  getInTrialRestriction(forceLoad = false): Observable<AppraisalInTrialSubscription> {
    if (this.appraisalInTrialSubscription === undefined || forceLoad) {
      return this.http.get<AppraisalInTrialSubscription>(`${this.appraisalApiUrl}/subscriptions`)
        .pipe(map(res => {
          this.appraisalInTrialSubscription = res;
          return res;
        }),
        catchError(() => {
          this.appraisalInTrialSubscription = null;

          return of(null);
        }));
    } else {
      return of(this.appraisalInTrialSubscription);
    }
  }

  /**
   * Actives appraisal inTrial subscription
   */
  activateInTrialSubscription(): Observable<AppraisalInTrialSubscription> {
    const model: AppraisalInTrialSubscription = {
      activeFrom: new Date(),
      serviceType: BusinessServiceType.Appraisal
    };

    return this.http.post<AppraisalInTrialSubscription>(`${this.appraisalApiUrl}/subscriptions/requesttotry`, model);
  }

  /**
   * Requests for a full Appraisal subscription
   */
  sendQuote(): Observable<any> {
    return this.http.post<any>(`${this.appraisalApiUrl}/appraisals/quote`, {});
  }
}
