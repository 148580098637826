<div class="modal-header" [ngClass]="config.headerClass ?? 'mb-2'">
    <h4 class="modal-title font-20" [innerHTML]="config.headerText | translate"></h4>
    <i *ngIf="!config.hideCloseButton" class="mdi mdi-close font-20 cursor-pointer float-start" (click)="onCloseModal()"></i>
  </div>
  
  <div *ngIf="config.separationLine" class="separation-line mb-2 mt-1 full-width"></div>
  
  <div [class]="config.bodyContainerClass">
    <i [ngClass]="config.bodyIcon"></i>
    <div class="px-3 py-2 modal-text" [ngClass]="config.adjustText ? 'text-size-and-color' : ''" [innerHTML]="config.bodyText | translate"></div>
  </div>
  
  <div *ngIf="config.checkbox" class="pl-3">
    <section [formGroup]="formGroup">
      <fieldset class="form-group">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" formControlName="checkbox" id="checkbox-id"
            class="custom-control-input" />
          <label class="zing-ui custom-control-label checkbox-label font-weight-normal pt-1" for="checkbox-id">
            <span [ngClass]="config.adjustText ? 'text-size-and-color' : ''" [innerHTML]="'Labels.TransactionRequirements.CheckboxText' | translate"></span>
          </label>
        </div>
      </fieldset>
    </section>
  </div>
  
  <div class="float-end pr-2 mt-2" *ngIf="!config.hideButtons">
    <button class="btn w-auto mb-2 modal-btn" [ngClass]="!config.inverted ? config.continueButtonClass : config.cancelButtonClass"
      type="button" (click)="!config.inverted ? onAgree() : onCloseModal()">
      {{ (!config.inverted ? config.continueButtonText : config.cancelButtonText) | translate }}
    </button>
    <button class="btn w-auto ml-2 mb-2 modal-btn" [disabled]="formGroup?.invalid" [ngClass]="!config.inverted ? config.cancelButtonClass : config.continueButtonClass"
      type="button" (click)="!config.inverted ? onCloseModal() : onAgree()">
      {{ (!config.inverted ? config.cancelButtonText : config.continueButtonText) | translate }}
    </button>
  </div>