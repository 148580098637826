import { createFeatureSelector, createSelector } from '@ngrx/store';
import * as fromRoot from '../../../../../store/root.state';
import { PaymentActions, PaymentActionTypes } from './payment.actions';
import { PaymentMethod } from '../models/payment-method.model';
import { PlatformModule } from '../models/platform-module.model';

export interface ZingState extends fromRoot.ZingState {
    paymentState: PaymentState;
}

export interface PaymentState {
    error?: any;
    paymentMethodsLoading?: boolean;
    platformModulesLoading?: boolean;
    paymentMethodsLoaded: boolean;
    platformModulesLoaded: boolean;
    paymentMethods: Array<PaymentMethod>;
    platformModules: Array<PlatformModule>;
    updateStatus: { message: string, isOk: boolean, isNOk: boolean };
}

export const initialState: PaymentState = {
    updateStatus: null,
    paymentMethods: null,
    platformModules: null,
    paymentMethodsLoaded: false,
    platformModulesLoaded: false,
    paymentMethodsLoading: false,
    platformModulesLoading: false
};

export const getPaymentFeatureState = createFeatureSelector<PaymentState>('payment');

export const getPaymentMethodsLoading = createSelector(
    getPaymentFeatureState,
    state => state.paymentMethodsLoading
);

export const getPlatformModulesLoading = createSelector(
    getPaymentFeatureState,
    state => state.platformModulesLoading
);

export const getAllPaymentMethods = createSelector(
    getPaymentFeatureState,
    state => state.paymentMethods
);

export const getAllPlatformModules = createSelector(
    getPaymentFeatureState,
    state => state.platformModules
);

export const getPlatformModulesLoaded = createSelector(
    getPaymentFeatureState,
    state => state.platformModulesLoaded
);

export const getUpdateStatus = createSelector(
    getPaymentFeatureState,
    state => state.updateStatus
);

export function paymentReducer(state = initialState, action: PaymentActions) {
    switch (action.type) {
        case PaymentActionTypes.LoadPaymentMethods:
            return {
                ...state,
                paymentMethodsLoading: true,
                paymentMethodsLoaded: false,
            };
        case PaymentActionTypes.LoadPaymentMethodsSuccess:
            return {
                ...state,
                paymentMethodsLoading: false,
                paymentMethods: [...action.payload.paymentMethods],
                paymentMethodsLoaded: true,
                error: null
            };
        case PaymentActionTypes.LoadPaymentMethodsFail:
            return {
                ...state,
                paymentMethodsLoading: false,
                paymentMethodsLoaded: false,
                error: action.payload
            };
        case PaymentActionTypes.LoadPlatformModules:
            return {
                ...state,
                platformModulesLoading: true,
                platformModulesLoaded: false
            };
        case PaymentActionTypes.LoadPlatformModulesSuccess:
            return {
                ...state,
                platformModulesLoading: false,
                platformModules: [...action.payload.platformModules],
                platformModulesLoaded: true,
                error: null
            };
        case PaymentActionTypes.LoadPlatformModulesFail:
            return {
                ...state,
                platformModulesLoading: false,
                platformModulesLoaded: false,
                error: action.payload
            };
        case PaymentActionTypes.EditPaymentMethods:
            return {
                ...state,
                isLoading: false,
                updateStatus: action.payload ? {...state.updateStatus, ...action.payload} : null
            };
        case PaymentActionTypes.UpdatePaymentMethod:
            return {
                ...state,
                isLoading: true
            };
        case PaymentActionTypes.UpdatePaymentMethodSuccess:
            return {
                ...state,
                isLoading: false,
                updateStatus: action.payload
            };
        case PaymentActionTypes.UpdatePaymentMethodFail:
            return {
                ...state,
                isLoading: false,
                updateStatus: action.payload
            };
        case PaymentActionTypes.CreatePaymentMethod:
            return {
                ...state,
                isLoading: true
            };
        case PaymentActionTypes.CreatePaymentMethodSuccess:
            return {
                ...state,
                isLoading: false
            };
        case PaymentActionTypes.CreatePaymentMethodFail:
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        default:
            return state;
    }
}
