<div class="forbidden d-flex flex-column justify-content-center align-items-center">
  <div class="container">
    <div class="row">
      <div class="col-12 mb-2 text-center heading-30-bold-primary">403 Forbidden</div>
    </div>
    <div class="row">
      <p class="col-12 text-center">You don't have permission to view this page.</p>
    </div>
    <div class="row mt-5">
      <div class="col-12 col-lg-4 offset-lg-4">
        <button (click)="goBack()" class="btn-md btn-secondary-white">
          {{ 'GoBack' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
